class PropertiesHelper {

    /**
     * PROPERTIES SECTIONS
     */

    /** 
     * These properties are used to define the positioning of an element.
     */
    static positionProperties = [
        'top',
        'left',
        'width',
        'height',
        'zIndex',
    ];

    /** 
     * These properties are used to define the styles withing a container
     */
    static containerProperties = [
        'backgroundColor',
        'opacity',

        'borderWidth',
        'borderRadius',
        'borderStyle',
        'borderColor',

        'borderTopWidth',
        'borderTopStyle',
        'borderTopColor',

        'borderBottomWidth',
        'borderBottomStyle',
        'borderBottomColor',

        'borderLeftWidth',
        'borderLeftStyle',
        'borderLeftColor',

        'borderRightWidth',
        'borderRightStyle',
        'borderRightColor',

        'padding',
        'paddingLeft',
        'paddingTop',
        'paddingRight',
        'paddingBottom',
    ];

    static textProperties = [
        'color',
        //'fontFamily',
        'fontSize',
        'fontStyle',
        'fontWeight',
        'textAlign',
        'textDecoration',
        'letterSpacing',
        'lineHeight',
        'textTransform',
        'textWrap',
        'wordSpacing'
    ];

    static imageProperties = [
        'filter',
        'objectFit'
    ];

    /**
     * INPUT CONFIGURATION
     */

    /**
     * Displays ColorPopup
     */
    static colorProperties = [
        'backgroundColor',
        'borderColor',
        'borderBottomColor',
        'borderLeftColor',
        'borderRightColor',
        'borderTopColor',
        'color',
    ];

    /**
     * Displays IntegerUnitInput
     */
    static integerUnitProperties = [
        'borderWidth',
        'borderRadius',
        'borderLeftWidth',
        'borderTopWidth',
        'borderRightWidth',
        'borderBottomWidth',
        'height',
        'left',
        'letterSpacing',
        'padding',
        'paddingLeft',
        'paddingTop',
        'paddingRight',
        'paddingBottom',
        'fontSize',
        'top',
        'width'
    ];

    /**
     * Displays IntegerInput
     */
    static integerProperties = [
        'zIndex'
    ];

    /**
     * Displays DecimalUnitInput
     */
    static decimalUnitProperties = [
        'lineHeight',
        'wordSpacing',
    ];

    /**
     * BORDER OPTIONS
     */
    static borderOptions = [
        { value: 'none', label: 'None' },
        { value: 'dashed', label: 'Dashed' },
        { value: 'dotted', label: 'Dotted' },
        { value: 'double', label: 'Double' },
        { value: 'groove', label: 'Groove' },
        { value: 'ridge', label: 'Ridge' },
        { value: 'solid', label: 'Solid' },
    ];

    /**
     * STYLE PICKERS (DROP-DOWNS)
     */
    static stylePickerMapping = {
        fontFamily: [
            { value: 'Arial', label: 'Arial' },
            { value: 'Courier New', label: 'Courier New' },
            { value: 'Georgia', label: 'Georgia' },
            { value: 'Times New Roman', label: 'Times New Roman' },
            { value: 'Trebuchet MS', label: 'Trebuchet MS' },
            { value: 'Verdana', label: 'Verdana' },
            { value: 'Roboto', label: 'Roboto' },
            { value: 'Open Sans', label: 'Open Sans' },
            { value: 'Lato', label: 'Lato' },
            { value: 'Montserrat', label: 'Montserrat' },
            { value: 'Dancing Script, cursive', label: 'Dancing Script' },
            { value: 'Great Vibes, cursive', label: 'Great Vibes' },
            { value: 'Pacifico, cursive', label: 'Pacifico' },
            { value: 'Lobster, cursive', label: 'Lobster' },
            { value: 'Caveat, cursive', label: 'Caveat' },
            { value: 'Satisfy, cursive', label: 'Satisfy' },
            { value: 'Pinyon Script, cursive', label: 'Pinyon Script' },
            { value: 'Courgette, cursive', label: 'Courgette' },
            { value: 'Indie Flower, cursive', label: 'Indie Flower' },
            { value: 'Sacramento, cursive', label: 'Sacramento' },
        ],
        fontStyle: [
            { value: 'italic', label: 'Italic' },
            { value: 'normal', label: 'Normal' },
            { value: 'oblique', label: 'Oblique' }
        ],
        fontWeight: [
            { value: 'bold', label: 'Bold' },
            { value: 'normal', label: 'Normal' },
            { value: 'lighter', label: 'Lighter' }
        ],
        textAlign: [
            { value: 'left', label: 'Left' },
            { value: 'center', label: 'Center' },
            { value: 'right', label: 'Right' }
        ],
        textDecoration: [
            { value: 'line-through', label: 'Line Through' },
            { value: 'none', label: 'None' },
            { value: 'overline', label: 'Overline' },
            { value: 'underline', label: 'Underline' },
        ],
        textTransform: [
            { value: 'capitalize', label: 'Capitalize' },
            { value: 'none', label: 'None' },
            { value: 'lowercase', label: 'Lowercase' },
            { value: 'uppercase', label: 'Uppercase' },
        ],
        textWrap: [
            { value: 'nowrap', label: 'No Wrap' },
            { value: 'pretty', label: 'Pretty' },
            { value: 'stable', label: 'Stable' },
            { value: 'wrap', label: 'Wrap' },
        ],
        objectFit: [
            { value: 'none', label: 'None' },
            { value: 'contain', label: 'Contain' },
            { value: 'cover', label: 'Cover' },
            { value: 'fill', label: 'Fill' },
        ],
        borderStyle: this.borderOptions,
        borderLeftStyle: this.borderOptions,
        borderTopStyle: this.borderOptions,
        borderRightStyle: this.borderOptions,
        borderBottomStyle: this.borderOptions,
    };

    /**
     * Method to gather all of the options for a particular property.
     * 
     * @param {string} property - Original property name.
     * 
     * @returns {string} - Options from stylePickerMapping.
     */
    static getOptionsForProperty(property) {
        return this.stylePickerMapping[property] || [];
    };

    /**
     * Method to format a property name into a human-readable label.
     * 
     * @param {string} property - Original property name.
     * 
     * @returns {string} - Formatted label.
     */
    formatLabel(property) {
        return property
            .split('')
            .map((char, index) => {
                if (index === 0) {
                    return char.toUpperCase();
                }
                return char === char.toUpperCase() ? ` ${char}` : char;
            })
            .join('');
    };

    /**
     * Method to define how values should be validated during save.
     * 
     * @param {set} styles - Set of styles to validate.
     * 
     * @returns {set} - Validated styles.
     */
    sanitizeCSS(styles) {
        const allowedProperties = {
            backgroundColor: /^.*$/, // Any value
            borderRadius: /^\d+(px|em|rem|%)$/,
            borderTopLeftRadius: /^\d+(px|em|rem|%)$/,
            borderTopRightRadius: /^\d+(px|em|rem|%)$/,
            borderBottomLeftRadius: /^\d+(px|em|rem|%)$/,
            borderBottomRightRadius: /^\d+(px|em|rem|%)$/,
            borderWidth: /^\d+(px|em|rem|%)$/,
            borderLeftWidth: /^\d+(px|em|rem|%)$/,
            borderTopWidth: /^\d+(px|em|rem|%)$/,
            borderRightWidth: /^\d+(px|em|rem|%)$/,
            borderBottomWidth: /^\d+(px|em|rem|%)$/,
            borderColor: /^.*$/, // Any value
            borderLeftColor: /^.*$/, // Any value
            borderTopColor: /^.*$/, // Any value
            borderRightColor: /^.*$/, // Any value
            borderBottomColor: /^.*$/, // Any value
            borderStyle: /^(solid|dashed|dotted|double|groove|ridge|inset|outset)$/,
            color: /^.*$/, // Any value
            filter: /^.*$/, // Any value
            fontFamily: /^([a-zA-Z0-9\s]+|"[^"]+"|'[^']+')(\s*,\s*([a-zA-Z0-9\s]+|"[^"]+"|'[^']+'))*$/, // Allows alphanumeric, space, comma, and quoted font names
            fontSize: /^\d+(px|em|pt|rem|%)$/,
            fontWeight: /^(normal|bold|bolder|lighter|\d{1,4})$/,
            fontStyle: /^(normal|italic|oblique)$/,
            left: /^-?\d+(px|em|rem|%)$/,
            letterSpacing: /^-?\d+(px|em|rem|%)$/,
            lineHeight: /^-?\d*\.?\d*(px|em|rem|%)?$/,
            height: /^\d+(px|em|rem|%)$/,
            objectFit: /^(fill|contain|cover|none|scale-down)$/,
            objectPosition: /^(\d+(px|em|rem|%)\s+\d+(px|em|rem|%))$/, // Example: "50% 50%"
            opacity: /^(0(\.\d+)?|1(\.0+)?)$/,
            padding: /^\d+(px|em|rem|%)$/,
            paddingLeft: /^\d+(px|em|rem|%)$/,
            paddingRight: /^\d+(px|em|rem|%)$/,
            paddingTop: /^\d+(px|em|rem|%)$/,
            paddingBottom: /^\d+(px|em|rem|%)$/,
            textAlign: /^(left|right|center|justify)$/,
            textDecoration: /^(none|underline|overline|line-through)$/,
            textTransform: /^(none|capitalize|uppercase|lowercase)$/,
            textWrap: /^(nowrap|pretty|stable|wrap)$/,
            top: /^-?\d+(px|em|rem|%)$/,
            width: /^\d+(px|em|rem|%)$/,
            wordSpacing: /^-?\d*\.?\d*(px|em|rem|%)?$/,
            zIndex: /^-?\d+$/,
        };
        const sanitizedStyles = {};
        for (const [property, value] of Object.entries(styles)) {
            if (allowedProperties[property] && allowedProperties[property].test(value)) {
                sanitizedStyles[property] = value;
            } else {
                console.warn(`Invalid CSS property or value: ${property}: ${value}`);
            }
        }
        return sanitizedStyles;
    }

}

export default PropertiesHelper;
