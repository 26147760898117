import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Constants
import { FormMode } from '../../../utilities/Constants';

// Styles
import './ChatPost.css';

// Components
import Document from '../../components/document/Document';
import Event from '../../components/event/Event';
import Gallery from '../../components/gallery/Gallery';
import Object from '../../../objects/Object';
import VideoGallery from '../../components/videogallery/VideoGallery';
import UserDot from '../../../components/userdot/UserDot';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ChatPost = ({ post, handleUserClick, timestamp }) => {
    const { theme } = useTheme();
    const {
        models,
        resetVisibility,
        setFormMode,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    const handleObjectClick = async (object) => {
        resetVisibility();
        const model = models.find(model => model.key === object.modelKey);
        setSelectedModel(model);
        setSelectedObject(object);
        setFormMode(FormMode.VIEW);
    };

    return (
        <>
            <div
                key={post.key}
                className="chat-post-wrapper">
                <div className="chat-post-left"
                    onClick={() => handleUserClick(post.userKey)}>
                    <UserDot
                        letter={post.username && post.username.toUpperCase()[0]}
                        size="36"
                    />
                </div>
                <div className="chat-post-right">
                    <div className="chat-post-header">
                        <div className="chat-post-user"
                            onClick={() => handleUserClick(post.userKey)}
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {post.username}
                        </div>
                        <div className="chat-post-timestamp"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>
                            • {timestamp}
                        </div>
                    </div>
                    <div className="chat-post-content"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {post.text}
                    </div>

                    {/* DOCUMENTS */}
                    {post.documents && post.documents.length > 0 &&
                        <div className="chat-post-documents-wrapper">
                            {post.documents.map((document) => (
                                <Document
                                    key={document.id}
                                    document={document}
                                />
                            ))}
                        </div>
                    }

                    {/* EVENTS */}
                    {post.events && post.events.length > 0 &&
                        <div className="chat-post-events-wrapper">
                            {post.events.map((evt) => (
                                <Event
                                    key={evt.id}
                                    event={evt}
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>

            {/* PHOTOS */}
            {post.photoUrls && post.photoUrls.length > 0 &&
                <div className="chat-post-gallery-wrapper">
                    <Gallery key={post.key} post={post} />
                </div>
            }

            {/* VIDEOS */}
            {post.videoUrls && post.videoUrls.length > 0 &&
                <div className="chat-post-gallery-wrapper">
                    <VideoGallery key={post.key} post={post} />
                </div>
            }

            {/* OBJECTS */}
            {post.objects && post.objects.length > 0 &&
                <div className="chat-post-object-wrapper">
                    {post.objects.map((object) => (
                        <div key={object.id} className="chat-post-object"
                            style={{
                                backgroundColor: theme.backgroundColorFaded
                            }}
                            onClick={() => handleObjectClick(object)}>
                            <Object object={object} />
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default ChatPost;
