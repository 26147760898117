import React from 'react';

// Styles
import './FormAddButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

const FormAddButton = ({onClick}) => {
    const { theme } = useTheme();

    return (

        <div className="form-add-button-container"
            style={{
                backgroundColor: theme.highlightBackgroundColor,
                color: theme.highlightForegroundColor
            }}
            onClick={onClick}>
            Add
        </div>
    );
};

export default FormAddButton;
