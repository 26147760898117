import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Constants
import { FormMode } from '../common/utilities/Constants';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Desktop.css';

// Theme
import { useStyle, useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AppBar from '../common/components/appbar/AppBar';
import AppUsers from '../common/appusers/AppUsers';
import AppUserProfile from '../common/appusers/AppUserProfile';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channels/chat/Chat';
import FieldSelector from '../common/form/fieldselector/FieldSelector';
import Form from '../common/form/Form';
import Forum from '../common/channels/forum/Forum';
import Menu from '../common/menu/Menu';
import Messages from '../common/messages/Messages';
import Objects from '../common/objects/Objects';
import RunHome from '../run/forum/RunHome';
import TitleBar from '../common/titlebar/TitleBar';

// Styled Components
const InlineStyles = useStyle`
    
    body {
      margin: 0;
      font-family: ${(props) => props.fontFamily};
      display: flex;
      flex-direction: column;
    }

    ::selection {
        background: ${(props) => props.selectionBackground};
        color: ${(props) => props.selectionForeground};
    }

    input::selection, textarea::selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

    input::-moz-selection, textarea::-moz-selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

`;

/**
 * Desktop Component
 * 
 * This component renders a desktop version of the app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Desktop = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    accountAboutVisible,
    accountVisible,
    appUsersVisible,
    calendarVisible,
    chatVisible,
    fieldSelectorVisible,
    forumVisible,
    messagesVisible,
    objectsView,
    runHomeVisible,
    selectedApp,
    setFormMode
  } = useContext(Global);

  /** 
   * In desktop mode, show the add form on the right side of the screen
   */
  useEffect(() => {
    if (!isMobile) {
      setFormMode(FormMode.ADD);
    }
  }, [setFormMode]);

  const renderContent = () => {
    if (chatVisible) return <Chat />;
    if (forumVisible) return <Forum />;
    if (runHomeVisible) return <RunHome />;
    if (calendarVisible) return <Calendar />;
    if (messagesVisible) return <Messages />;
    if (appUsersVisible) return (
      <>
        <AppUsers />
        <AppUserProfile />
      </>
    );
    if (accountVisible) return (
      <>
        <AccountMenu />
        {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
      </>
    );
    return (
      <>
        <Objects />
        {objectsView !== "TABLE" &&
          <>
            {fieldSelectorVisible ? (
              <div className="desktop-field-selector">
                <div className="desktop-field-selector-header"
                  style={{ color: theme.foregroundColorFaded }}>
                  Add Field
                </div>
                <FieldSelector
                  size="LARGE"
                />
              </div>
            ) : (
              <Form />
            )}
          </>
        }
      </>
    );
  };

  /**
   * Function to load only the regular variant of a font.
   * 
   * @param {string} fontName - Name of the font.
   */
  const loadGoogleFont = (fontName) => {
    const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    if (!selectedApp) return;
    loadGoogleFont(selectedApp.fontFamily || 'Red Hat Display');
  }, [selectedApp]);

  return (
    <>
      <InlineStyles
        fontFamily={selectedApp && selectedApp.fontFamily || 'Red Hat Display'}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{ backgroundColor: theme.backgroundColor }}>
        {selectedApp && (
          <div className="desktop-title-bar">
            <TitleBar />
          </div>
        )}
        <div className="desktop-center" style={{  }}>
          <AppBar />
          <Menu />
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default Desktop;
