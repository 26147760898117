import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ForumToolBar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import ForumIcon from '../../../common/svg/ForumIcon';

/**
 * ForumToolbar Component
 * 
 * This component renders a forum toolbar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ForumToolBar = () => {
    const { theme } = useTheme();
    const {
        selectedChannel
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="forum-toolbar-container">

                {/* LEFT */}
                <div className="forum-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}>
                    {selectedChannel &&
                        <>
                            <ForumIcon
                                color={theme.foregroundColorFaded}
                                width="20"
                                height="20"
                            />

                            {selectedChannel.title}
                        </>
                    }
                </div>

                {/* RIGHT */}
                <div className="forum-toolbar-right">

                </div>

            </div>
        </>
    );
};

export default ForumToolBar;
