export function generateKey(length = 15) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function randomizeLove() {
  const love = 'love';
  let randomizedLove = '';
  for (let i = 0; i < love.length; i++) {
    // Randomly choose to uppercase or lowercase each character
    randomizedLove += Math.random() > 0.5 ? love[i].toUpperCase() : love[i].toLowerCase();
  }
  return randomizedLove;
}

export function generateLove() {
  // Generate the base key
  let baseKey = generateKey(16);
  
  // Choose a random start index for 'love' within the first 3 positions
  const startIndex = Math.floor(Math.random() * 3);
  
  // Generate a randomized version of 'love'
  const love = randomizeLove();
  
  // Construct the final key
  const inviteKey = baseKey.substring(0, startIndex) + love + baseKey.substring(startIndex);
  
  return inviteKey;
}
