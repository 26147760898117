import React from 'react';

// Styles
import './ForumPostFormDocuments.css';

// Components
import ForumPostFormDocument from './ForumPostFormDocument';

const ForumPostFormDocuments = ({ files }) => {

  return (
    <>
      {files && files.length > 0 &&
        <div className="forum-post-form-documents-container">
          {files.map(({ file, url }, index) => (
            <ForumPostFormDocument
              key={index}
              file={file}
              url={url}
            />
          ))}
        </div>
      }
    </>
  );
};

export default ForumPostFormDocuments;
