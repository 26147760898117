import React, { useEffect, useState } from 'react';

// Styles
import './IntegerUnitInput.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const IntegerUnitInput = ({ value, onChange, property, ...props }) => {
    const { theme } = useTheme();

    const sizeUnits = [
        { value: 'px', label: 'px' },
        { value: '%', label: '%' }
    ];

    // This mapping indicates which unit types should be displayed
    // according to the property
    const propertyUnitsMapping = {
        borderWidth: sizeUnits,
        borderRadius: sizeUnits,
        borderLeftWidth: sizeUnits,
        borderTopWidth: sizeUnits,
        borderRightWidth: sizeUnits,
        borderBottomWidth: sizeUnits,
        fontSize: [
            { value: 'px', label: 'px' },
            { value: 'pt', label: 'pt' },
        ],
        letterSpacing: [
            { value: 'px', label: 'px' },
            { value: 'em', label: 'em' },
            { value: 'rem', label: 'rem' },
            { value: '%', label: '%' }
        ],
        padding: sizeUnits,
        paddingLeft: sizeUnits,
        paddingTop: sizeUnits,
        paddingRight: sizeUnits,
        paddingBottom: sizeUnits,
        height: sizeUnits,
        left: sizeUnits,
        top: sizeUnits,
        width: sizeUnits,
        wordSpacing: [
            { value: 'px', label: 'px' },
            { value: 'em', label: 'em' },
            { value: 'rem', label: 'rem' },
            { value: '%', label: '%' }
        ],
    };

    const getUnitsForProperty = (property) => {
        return propertyUnitsMapping[property] || [];
    };

    // Extract the number and unit from the value string
    const extractNumber = (val) => val.match(/-?\d+/) ? val.match(/-?\d+/)[0] : '';
    const extractUnit = (val) => val.match(/[a-zA-Z%]+/) ? val.match(/[a-zA-Z%]+/)[0] : 'px';

    const initialNumber = extractNumber(value);
    const initialUnit = extractUnit(value);

    const [number, setNumber] = useState(initialNumber);
    const [selectedUnit, setSelectedUnit] = useState(initialUnit);
    const units = getUnitsForProperty(property);

    useEffect(() => {
        setNumber(extractNumber(value));
        setSelectedUnit(extractUnit(value));
    }, [value]);

    const handleNumberChange = (e) => {
        const val = e.target.value;
        if (/^-?\d*$/.test(val) || val === '') { // Allow digits, optional minus sign, and empty string
            setNumber(val);
            onChange(val === '' ? '' : `${val}${selectedUnit}`);
        }
    };

    const handleUnitChange = (e) => {
        const newUnit = e.target.value;
        setSelectedUnit(newUnit);
        onChange(number === '' ? '' : `${number}${newUnit}`);
    };

    return (
        <div>
            <input
                type="text"
                value={number}
                onChange={handleNumberChange}
                {...props}
                className="integer-unit-input"
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    borderRightColor: theme.backgroundColor,
                }}
            />
            <select 
                className="number-unit-select"
                value={selectedUnit} 
                onChange={handleUnitChange}
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    borderRightColor: theme.backgroundColor,
                }}>
                {units.map((unit) => (
                    <option key={unit.value} value={unit.value}>
                        {unit.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default IntegerUnitInput;
