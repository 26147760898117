import React from 'react';

const PlusIcon = ({ color = "white", width = "44.7266", height = "44.3555" }) => (
  <svg 
    version="1.1" 
    width={width} 
    height={height} 
    viewBox="0 0 44.7266 44.3555"
  >
    <g>
      <rect height="44.3555" opacity="0" width="44.7266" x="0" y="0"/>
      <path 
        d="M0 22.1875C0 24.8633 2.16797 27.0117 4.82422 27.0117L17.3438 27.0117L17.3438 39.5312C17.3438 42.1875 19.5117 44.3555 22.168 44.3555C24.8438 44.3555 27.0117 42.1875 27.0117 39.5312L27.0117 27.0117L39.5312 27.0117C42.1875 27.0117 44.3555 24.8633 44.3555 22.1875C44.3555 19.5117 42.1875 17.3633 39.5312 17.3633L27.0117 17.3633L27.0117 4.84375C27.0117 2.16797 24.8438 0 22.168 0C19.5117 0 17.3438 2.16797 17.3438 4.84375L17.3438 17.3633L4.82422 17.3633C2.16797 17.3633 0 19.5117 0 22.1875Z" 
        fill={color}
      />
    </g>
  </svg>
);

export default PlusIcon;
