// Firebase
import { collection, deleteDoc, doc, getDoc, getDocs, increment, query, onSnapshot, setDoc, Timestamp, updateDoc, where, writeBatch } from 'firebase/firestore';

import { collections, db, storage } from '../../firebaseConfig';
import { ref, deleteObject } from 'firebase/storage';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DocumentManager from './DocumentManager';
import IndexManager from './IndexManager';
import ModelManager from './ModelManager';

const documentManager = new DocumentManager();
const indexManager = new IndexManager();
const modelManager = new ModelManager();

class ObjectManager {

    /**
     * Method to add an object to a menu item.
     * 
     * @param {string} appKey - App key.
     * @param {string} objectKey - The new key of the object.
     * @param {string} modelKey - The key of the object model type.
     * @param {object} data - Object to add.
     * 
     * @returns {object} - New object.
     */
    async add(appKey, modelKey, key, data) {
        // Set the object autonumber if autonumber fields exist in the model
        const { fieldKey, count } = await this.getAutonumber(modelKey);
        if (fieldKey) {
            data[fieldKey] = count;
        }

        // Add the object to its model's collection
        await setDoc(doc(db, modelKey, key), data);

        // Create an index record for searching:
        // - Fetch the model and determine the title field key.
        // - Get the title value from the data by using the title field key
        // - Add the index record
        const model = await modelManager.get(appKey, modelKey);

        const titleFieldKey = model.titleFieldKey;

        const objectTitle = data[titleFieldKey];

        await indexManager.add(appKey, modelKey, key, objectTitle);

        // Log 2 writes to the activity log.
        activity.log(appKey, 'writes', 1);
    }

    /**
     * Utility for the addObject method to find the next autonumber for models that contain
     * autonumber fields.
     * 
     * @param {string} modelKey - The key of the object model type.
     * 
     * @returns {object} - { fieldKey: key of the autonumber field, count: value to place in the field }
    */
    async getAutonumber(modelKey) {
        // Reference to the fields collection
        const fieldsRef = collection(db, collections.fields);
        // Query to get the fields with the given modelKey
        const fieldsSnapshot = await getDocs(query(fieldsRef, where('modelKey', '==', modelKey)));
        let count;
        for (const fieldDoc of fieldsSnapshot.docs) {
            const field = fieldDoc.data();
            if (field.type === 'autonumber') {
                // If count is not defined, initialize it
                if (count === undefined) {
                    const collectionRef = collection(db, modelKey);
                    const objectsSnapshot = await getDocs(collectionRef);
                    count = objectsSnapshot.size + 1;
                } else {
                    count++;
                }
                // Return an object with both field.key and count
                return { fieldKey: field.key, count: count };
            }
        }
        // Default return value if no autonumber field is found
        return { fieldKey: null, count: 1 };
    }

    /**
     * Deletes an object, its relationships, and related summaries.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The model key of the object.
     * @param {string} object - The object being deleted.
     * @param {array} formField - An array of field objects.
    */
    async delete(appKey, modelKey, object, formFields) {

        // Filter gallery-type fields (image and video)
        const galleryFields = formFields.filter(field => field.type === 'gallery' || field.type === 'videogallery');

        // Extract image URLs and delete images from Firebase Storage
        for (const galleryField of galleryFields) {
            const fieldKey = galleryField.key;
            const urls = object[fieldKey] || [];

            for (const url of urls) {
                try {
                    const fileref = ref(storage, url);
                    await deleteObject(fileref);
                    console.log(`Image deleted successfully: ${url}`);
                } catch (error) {
                    console.error(`Error deleting image: ${url}`, error);
                }
            }
        }

        // Filter document-type fields
        const documentFields = formFields.filter(field => field.type === 'documents');

        // Extract document URLs and delete documents from Firebase Storage
        for (const documentField of documentFields) {
            const fieldKey = documentField.key;

            const results = await documentManager.fetchFieldDocuments(object.key, fieldKey);
            for (const result of results) {
                const documentUrl = result.url;
                try {
                    const documentRef = ref(storage, documentUrl);
                    await deleteObject(documentRef);
                    console.log(`Document deleted successfully: ${documentUrl}`);
                } catch (error) {
                    console.error(`Error deleting document: ${documentUrl}`, error);
                }
            }
        }

        // Step 1: Delete the object from its collection
        try {
            await deleteDoc(doc(db, modelKey, object.key));

            activity.log(appKey, 'deletes', 1);

        } catch (error) {
            console.error(`Error deleting ${modelKey} object with key ${object.key}:`, error);
            throw error; // Propagate the error if deletion fails
        }

        // Step 2: Delete related entries from the index collection
        const indexQuery = query(collection(db, collections.index), where("objectKey", "==", object.key));
        try {
            const querySnapshot = await getDocs(indexQuery);

            activity.log(appKey, 'reads', querySnapshot.docs.length);
            activity.log(appKey, 'deletes', querySnapshot.docs.length);

            if (querySnapshot.empty) {
                console.log(`No indexes found for key ${object.key}.`);
                return; // Early return if no index entries are found
            }
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
            console.log(`All related index entries for key ${object.key} deleted successfully.`);
        } catch (error) {
            console.error(`Error deleting index entries for key ${object.key}:`, error);
            throw error; // Propagate the error if deletion fails
        }

        // Step 3: Delete related entries from the "events" collection where objectKey == key
        const eventsQuery = query(collection(db, collections.events), where("objectKey", "==", object.key));
        try {
            const querySnapshot = await getDocs(eventsQuery);
            if (querySnapshot.empty) {
                console.log(`No events found for objectKey ${object.key}.`);
                return; // Early return if no events are found
            }
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);

                activity.log(appKey, 'reads', 1);
                activity.log(appKey, 'deletes', 1);
            });
            console.log(`All related events for key ${object.key} deleted successfully.`);
        } catch (error) {
            console.error(`Error deleting events for key ${object.key}:`, error);
            throw error; // Propagate the error if deletion fails
        }
    }

    /**
     * Method to elete multiple objects.
     * 
     * @param {Array<string>} objectKeys - The keys of the objects to delete.
     * @param {string} appKey - The key of the selected app.
     * @param {string} modelKey - The key of the selected model.
     */
    async deleteMultiple(appKey, modelKey, objectKeys) {
        const collectionRef = collection(db, modelKey);
        const batch = writeBatch(db);

        for (const objectKey of objectKeys) {
            const q = query(collectionRef, where("key", "==", objectKey));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
            });
        }

        try {
            activity.log(appKey, 'deletes', batch.size);
            await batch.commit();
            console.log("Successfully deleted selected objects");
        } catch (error) {
            console.error("Error deleting selected objects: ", error);
        }
    }

    /**
     * Method to fetch a single object from the [modelKey] collection by its key (document ID).
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The name of the collection from which to fetch the document.
     * @param {string} key - The ID of the document to fetch.
     * 
     * @returns {Promise<Object>} - A promise that resolves to the fetched object, including its ID.
    */
    async fetch(appKey, modelKey, key) {

        try {
            // Create a reference to the specific document in the collection
            const docRef = doc(db, modelKey, key);

            // Fetch the document from Firestore
            const docSnapshot = await getDoc(docRef);

            activity.log(appKey, 'reads', 1);

            // Check if the document exists
            if (docSnapshot.exists()) {
                // Return the document data with the document ID included
                return { id: docSnapshot.id, key: key, ...docSnapshot.data() };
            } else {
                // Handle the case where the document does not exist
                console.log("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error fetching object:", error);
            throw error;
        }
    }

    /**
     * Fetches objects and subscribes to real-time updates.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key.
     * @param {function} onUpdate - Callback function that handles the update.
     * @returns {function} Unsubscribe function to stop listening for updates.
     */
    listAndSubscribe(appKey, modelKey, onUpdate) {
        try {
            // Create a reference to the model's collection
            const objectsCollection = collection(db, modelKey);

            // Create a query for the collection
            const q = query(objectsCollection);

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const objects = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(objects);
                    activity.log(appKey, 'reads', objects.length);
                }
            }, error => {
                console.error("Error fetching objects:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    };

    /**
     * Method to fetch all objects from the [modelKey] collection filtered by userKey
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key
     * @returns {Promise<Object>} - A promise that resolves to the fetched objects.
    */
    async list(appKey, modelKey) {

        try {
            // Reference to the collection
            const ref = collection(db, modelKey);

            const queryRef = query(ref,
                where("appKey", "==", appKey));

            // Execute the query
            const snapshot = await getDocs(queryRef);

            activity.log(appKey, 'reads', snapshot.docs.length);

            // Map through the documents in the snapshot to extract the document ID and data
            const objects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Return the filtered objects
            return objects;
        } catch (error) {
            console.error("Error fetching objects:", error);
            throw error;
        }
    }

    /**
     * Updates an object.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key.
     * @param {string} key - Object key.
     * @param {object} fields - Json object.
    */
    async update(appKey, modelKey, key, fields) {
        const ref = doc(db, modelKey, key);
        try {
            const now = Timestamp.now();

            const stamped = { ...fields, dateModified: now };

            // Update the object's document
            await updateDoc(ref, stamped);

            // If any of the fields are the title field for the object's model,
            // we'll need to update the index record with the new value.
            // - Fetch the model and determine the title field key.
            // - Get the title value from the data by using the title field key
            // - Add the index record
            const model = await modelManager.get(appKey, modelKey);
            const titleFieldKey = model.titleFieldKey;
            if (fields[titleFieldKey]) {
                const objectTitle = fields[titleFieldKey];
                await indexManager.update(appKey, key, objectTitle);
            }

            // Log 2 writes to the activity log.
            activity.log(appKey, 'writes', 2);

            console.log("Object updated successfully");
        } catch (error) {
            console.error("Error updating object:", error);
            throw error;
        }
    }

    /**
     * Updates a specific object field with a new value.
     * 
     * @param {string} modelKey - The model key of the object.
     * @param {string} objectKey - Key of the object to update.
     * @param {string} fieldKey - Key of the field to update.
     * @param {string} newValue - New value to set.
    */
    async updateField(modelKey, objectKey, fieldKey, newValue) {
        try {
            const ref = doc(db, modelKey, objectKey);
            await updateDoc(ref, { [fieldKey]: newValue });
        } catch (error) {
            console.error("Error updating document: ", error);
            throw error;
        }
    }

    /**
 * Updates the view count for a list of objects.
 * 
 * @param {string} appKey - The key for the app.
 * @param {string} modelKey - The key for the model.
 * @param {string[]} objectKeys - An array of object keys that have been viewed.
 */
    async updateViewCount(appKey, modelKey, objectKeys) {
        const batch = writeBatch(db);  // Use Firestore's batch feature for bulk updates
        const now = Timestamp.now();

        try {
            for (let key of objectKeys) {
                const ref = doc(db, modelKey, key);

                // Check if the document exists
                const docSnapshot = await getDoc(ref);
                if (docSnapshot.exists()) {
                    // Increment view count atomically in Firestore
                    batch.update(ref, {
                        viewCount: increment(1),
                        dateModified: now
                    });
                } else {
                    console.warn(`Document with key ${key} does not exist, skipping update.`);
                }
            }

            // Commit the batched writes
            await batch.commit();

            // Log batch write activity (1 write per updated object)
            activity.log(appKey, 'writes', objectKeys.length);

            console.log(`View counts updated for ${objectKeys.length} objects.`);
        } catch (error) {
            console.error("Error updating view counts:", error);
            throw error;
        }
    }


}

export default ObjectManager;
