import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppTitle.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppTitle = () => {
    const { theme } = useTheme();
    const {
        currentTheme,
        selectedApp
    } = useContext(Global);

    // State Va
    const [logoSrc, setLogoSrc] = useState(null);

    useEffect(() => {
        if (!selectedApp) return;

        if (currentTheme === 'DARK') {
            if (selectedApp.logoDark) {
                setLogoSrc(selectedApp.logoDark);
            } else {
                setLogoSrc(null);
            }
        } else {
            if (selectedApp.logoLight) {
                setLogoSrc(selectedApp.logoLight);
            } else {
                setLogoSrc(null);
            }
        }
    }, [selectedApp, currentTheme]);

    return (
        <>
            {/* CONTAINER */}
            <div className="app-title-container">

                {/* IMAGE */}
                {logoSrc &&
                    <img
                        className="app-title-image"
                        src={logoSrc}
                        alt={selectedApp.title}
                    />
                }

                {/* TITLE */}
                <div
                    className="app-title-name"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: selectedApp && selectedApp.fontFamily
                    }}>
                    {selectedApp.title}
                </div>
            </div>
        </>
    );
};

export default AppTitle;
