import React from 'react';

// Styles
import './PasswordPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import EyeIcon from '../../../../common/svg/EyeIcon';

const PasswordPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <>
            <div className="password-preview-container"
                style={{backgroundColor: theme.backgroundColorFaded}}>
            <div className="password-preview-label"
                style={{color: theme.foregroundColorFaded}}
            >
                {placeholder}
            </div>
            <div className="password-preview-wrapper">
                <input
                    type="password"
                    value={placeholder}
                    className="password-preview-input"
                    style={{border: borderStyle, color: theme.foregroundColor}}
                    readOnly
                    tabIndex={-1} // This makes the input unfocusable via keyboard
                />
                <div
                    style={{marginRight: "24px", marginTop: "14px"}}
                >
                    <EyeIcon color={theme.foregroundColor} width="20" height="20" />
                </div>
            </div>
            </div>
        </>
    );
};

export default PasswordPreview;
