import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ViewMenu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import DownIcon from '../svg/DownIcon';
import DateIcon from '../svg/DateIcon';
import ExportIcon from '../svg/ExportIcon';
import FieldIcon from '../svg/FieldIcon';
import GroupsIcon from '../svg/GroupsIcon';
import ImportIcon from '../svg/ImportIcon';
import ListIcon from '../svg/ListIcon';
import PencilIcon from '../svg/PencilIcon';
import TableIcon from '../svg/TableIcon';
import UpIcon from '../svg/UpIcon';

// Components
import Designer from '../../desktop/designer/Designer';
import ContextMenuButton from '../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../components/contextmenu/ContextMenuPopup';

const ViewMenu = () => {
  const { theme } = useTheme();
  const {
    appFields,
    objects,
    selectedModel,
    setCurrentCanvas,
    setCurrentDevice,
    setSortDirection,
    setSortField,
    setObjectsView,
    setGroups,
    setSelectedLookup,
    sortDirection
  } = useContext(Global);

  // State Variables
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [canvasVisible, setCanvasVisible] = useState(false);
  const [dynamicSortOptions, setDynamicSortOptions] = useState([]);
  const [lookupFields, setLookupFields] = useState([]);

  /**
   * Effect to generate dynamic sort options based on the selected model's fields
   */
  useEffect(() => {

    const sortTypes = [
      'autonumber',
      'countries',
      'currency',
      'day',
      'lookup',
      'month',
      'number',
      'phone',
      'states',
      'text',
      'time',
      'year'
    ];

    if (!objects || objects.length === 0) return;

    const modelFields = appFields
      .filter(field => field.modelKey === selectedModel.key && sortTypes.includes(field.type))
      .sort((a, b) => a.sort - b.sort);

    let newDynamicSortOptions = [];

    for (var i = 0; i < modelFields.length; i++) {
      const { key, title, type } = modelFields[i];

      if (sortTypes.includes(type)) {
        newDynamicSortOptions.push({ title, key: key, direction: 'ASC' });
      }

      setDynamicSortOptions(newDynamicSortOptions);
    }
  }, [objects, appFields, selectedModel.key]);

  /**
   * Effect to generate grouping options based upon lookup options
   */
  useEffect(() => {

    const groupTypes = [
      'lookup',
      /*'countries',
      'day',
      'month',
      'states',
      'time',
      'year'*/
    ];

    setLookupFields([]);

    const modelFields = appFields
      .filter(field => field.modelKey === selectedModel.key && groupTypes.includes(field.type));

    let newLookupFields = [];

    for (var i = 0; i < modelFields.length; i++) {
      const { key, title } = modelFields[i];

      if (groupTypes.includes(modelFields[i].type)) {
        newLookupFields.push({ title, key: key, direction: 'ASC' });
      }

      setLookupFields(newLookupFields);
    }
  }, [appFields, selectedModel.key]);

  // Toggle visibility function
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  }

  const handleAscending = () => {
    setSortDirection("ASC");
    setMenuVisible(false);
  }

  const handleDescending = () => {
    setSortDirection("DESC");
    setMenuVisible(false);
  }

  const handleGenerateUI = () => {
    setCurrentDevice('DESKTOP');
    setCurrentCanvas('SUMMARY');

    // Show the canvas
    setCanvasVisible(true);

    // Hide the menu
    setMenuVisible(false);
  }

  const handleImport = () => {

  }

  const handleExport = () => {

  }

  const handleFieldSelection = (key) => {
    setMenuVisible(false);
    setSortField(key);
    setSortDirection(sortDirection);
  };

  const handleListClick = () => {
    setObjectsView("LIST");
    setMenuVisible(false);
  };

  const handleTableClick = () => {
    setObjectsView("TABLE");
    setMenuVisible(false);
  };

  const handleLookupSelection = (fieldKey) => {
    // Set the view to "GROUPS"
    setObjectsView("GROUPS");

    // Find the selected field and set the selected groups from the options
    const selectedField = appFields.find(field => field.key === fieldKey);

    setSelectedLookup(selectedField);
    setGroups(selectedField.options);
    setMenuVisible(false);
  };

  return (
    <div>

      {/* BUTTON */}
      <ContextMenuButton onToggle={toggleMenu} />

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={200}
        position={menuPosition}>

        {/* VIEW SECTION */}
        <ContextMenuGroup title="View">

          {/* LIST */}
          <ContextMenuOption
            icon={ListIcon}
            text="List"
            onClick={handleListClick}
          />

          {/* TABLE */}
          <ContextMenuOption
            icon={TableIcon}
            text="Table" onClick={handleTableClick}
            iconSize="12"
          />

        </ContextMenuGroup>

        {/* GROUP BY SECTION */}
        {lookupFields.length > 0 &&

          <ContextMenuGroup title="Group By">

            {/* DYNAMIC GROUPS BY LOOKUP */}
            {lookupFields.map((lookup) => (

              <ContextMenuOption
                key={lookup.key}
                icon={GroupsIcon}
                text={lookup.title}
                onClick={() => handleLookupSelection(lookup.key)}
                iconSize="15"
              />

            ))}

          </ContextMenuGroup>

        }

        {/* TOOLS SECTION */}
        <ContextMenuGroup title="Tools">

          {/* EDIT SUMMARY */}
          {!isMobile &&
            <ContextMenuOption
              icon={PencilIcon}
              text="Edit Card Layout"
              onClick={handleGenerateUI}
              iconSize="11"
            />
          }

          {/* IMPORT */}
          <ContextMenuOption
            icon={ImportIcon}
            text="Import"
            onClick={handleImport}
            iconSize="11"
          />

          {/* EXPORT */}
          <ContextMenuOption
            icon={ExportIcon}
            text="Export"
            onClick={handleExport}
            iconSize="11"
          />

        </ContextMenuGroup>

        {/* SORT BY SECTION */}
        <ContextMenuGroup title="Sort By">

          {/* DYNAMIC FIELDS */}
          {dynamicSortOptions.map((option) => (

            <ContextMenuOption
              key={option.key}
              icon={FieldIcon}
              text={option.title}
              onClick={() => handleFieldSelection(option.key)}
              iconSize="15"
            />

          ))}

          {/* DATE CREATED */}
          <ContextMenuOption
            icon={DateIcon}
            text="Date Added"
            onClick={() => handleFieldSelection('CREATED')}
          />

          {/* DATE MODIFIED */}
          <ContextMenuOption
            icon={DateIcon}
            text="Last Modified"
            onClick={() => handleFieldSelection('MODIFIED')}
          />

        </ContextMenuGroup>

        {/* SORT DIRECTION SECTION */}
        <ContextMenuGroup title="Sort Direction">

          {/* ASCENDING */}
          <ContextMenuOption
            icon={UpIcon}
            text="Ascending"
            onClick={handleAscending}
            iconSize="11"
          />

          {/* DESCENDING */}
          <ContextMenuOption
            icon={DownIcon}
            text="Descending"
            onClick={handleDescending}
            iconSize="11"
          />

        </ContextMenuGroup>

      </ContextMenuPopup>

      <Designer visible={canvasVisible} setVisible={setCanvasVisible} />

    </div>
  );
};

export default ViewMenu;
