import React from 'react';

// Utilities
import { generateKey } from '../../utilities/Keys';

// Styles
import './EventFieldSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DocumentIcon from '../../svg/DocumentIcon';
import GalleryIcon from '../../svg/GalleryIcon';
import LocationIcon from '../../svg/LocationIcon';
import PasswordIcon from '../../svg/PasswordIcon';
import PhoneIcon from '../../svg/PhoneIcon';
import TextIcon from '../../svg/TextIcon';
import UsersIcon from '../../svg/UsersIcon';
import VideoGalleryIcon from '../../svg/GalleryIcon';

// Components
import EventDivider from './EventDivider';

const EventFieldSelector = ({ fields, onFieldAdd }) => {
    const { theme } = useTheme();

    // Available event fields
    const options = [
        { type: 'text', title: 'Add a Note', placeholder: 'Note' },
        { type: 'videogallery', title: 'Add Videos', placeholder: 'Videos' },
        { type: 'gallery', title: 'Add Images', placeholder: 'Images' },
        { type: 'location', title: 'Add a Location', placeholder: 'Location' },
        { type: 'userlist', title: 'Add People', placeholder: 'People' },
        { type: 'phone', title: 'Add a Phone Number', placeholder: 'Phone Number' },
        { type: 'documents', title: 'Add Documents', placeholder: 'Attach Documents' },
        { type: 'password', title: 'Add a Password', placeholder: 'Password' },
    ];

    const iconMap = {
        location: LocationIcon,
        userlist: UsersIcon,
        text: TextIcon,
        phone: PhoneIcon,
        documents: DocumentIcon,
        gallery: GalleryIcon,
        password: PasswordIcon,
        videogallery: VideoGalleryIcon
    };

    /**
     * Displays adds a field to the event.
     */
    const handleAddOption = async (option) => {
        const field = {
            key: generateKey(),
            type: option.type,
            title: option.placeholder,
        };

        onFieldAdd(field);
    };

    // Filter options to exclude types already present in fields
    const availableOptions = options.filter(
        (option) => !fields.some((field) => field.type === option.type)
    );

    return (
        <>
            <div className="event-field-selector-container">
                {/* OPTIONS */}
                {availableOptions.map((option) => {

                    const IconComponent = iconMap[option.type];
                    return (

                        <React.Fragment key={option.type}>

                            {/* OPTION */}
                            <div
                                className="event-field-selector-add-wrapper"
                                onClick={() => handleAddOption(option)}>

                                {/* ICON */}
                                <div
                                    className="event-field-selector-add-button">
                                    <IconComponent color={theme.foregroundColorFaded} width="14" height="14" />
                                </div>

                                {/* LABEL */}
                                <div
                                    className="event-field-selector-add-label"
                                    style={{
                                        color: theme.foregroundColorFaded
                                    }}>
                                    {option.title}
                                </div>

                            </div>

                            {/* DIVIDER */}
                            <EventDivider />

                        </React.Fragment>

                    );
                })}
            </div>
        </>
    );
};

export default EventFieldSelector;
