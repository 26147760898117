import React, { useEffect, useRef, useState } from 'react';

// Styles
import './ContextMenuPopup.css';

/**
 * ContextMenuPopup Component
 * 
 * This component renders popup for displaying context menu items.
 * 
 * @param {boolean} visible - Indicates whether the popup is visible.
 * @param {function} setVisible - The function to call to set the visibility of the popup.
 * @param {string} backgroundColor - The background color of the popup.
 * @param {object} position - The position of the popup.
 * @param {integer} width - The width of the popup.
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuPopup = ({ visible, setVisible, backgroundColor, position, width, children }) => {

  // References
  const menuRef = useRef(null);

  // Track the position state
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  // Update the position when the position prop changes
  useEffect(() => {
    if (position) {
      setMenuPosition({ x: position.x, y: position.y });
    }
  }, [position]);

  // Handle click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setVisible(false);
      }
    }

    // Add the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setVisible]);

  return (
    <>
      {visible &&
        <>
          {/* CONTAINER */}
          <div ref={menuRef}
            className="context-menu-popup-container"
            style={{
              top: menuPosition.y,
              left: menuPosition.x - width - 10,
              width: width,
              backgroundColor: backgroundColor,
              position: 'fixed',
            }}>
            {children}
          </div>
        </>
      }
    </>
  );
};

export default ContextMenuPopup;
