import React from 'react';

// Styles
import './ChecklistAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import NewIcon from '../../../../common/svg/NewIcon';

/**
 * Component for rendering a checklist add button.
 *
 * @param {function} onClick - Function to call when the button is clicked.
 * 
 * @return {JSX.Element} - The checklist add button component.
 */
const ChecklistAddButton = ({
    onClick
}) => {
    const { theme } = useTheme();

    return (

        <div onClick={onClick}
            className="checklist-add-button-container"
            style={{ color: theme.foregroundColorFaded }}>

            {/* ICON */}
            <div className="checklist-add-button-image">
                <NewIcon
                    color={theme.foregroundColorFaded}
                    width="24"
                    height="24"
                />
            </div>

            {/* TEXT */}
            <div className="checklist-add-button-text"
                style={{ color: theme.foregroundColorFaded }}>
                Add List Item
            </div>

        </div>

    );
};

export default ChecklistAddButton;
