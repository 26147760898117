import React, { useCallback, useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../../common/utilities/Constants';
import { generateKey } from '../../common/utilities/Keys';

// Firebase
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import { Timestamp } from 'firebase/firestore';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Form.css';

// Components
import FormAddButton from './FormAddButton';
import FormDeleteConfirmation from './FormDeleteConfirmation';
import FormField from './FormField';
import FormFooter from './FormFooter';
import FormSaveButton from './FormSaveButton';
import FormStatusMessage from './FormStatusMessage';
import FormToolBar from './FormToolBar';

// Managers
import HeadlineManager from '../../common/managers/HeadlineManager';
import ModelManager from '../../common/managers/ModelManager';
import ObjectManager from '../../common/managers/ObjectManager';

const headlineManager = new HeadlineManager();
const modelManager = new ModelManager();
const objectManager = new ObjectManager();

const Form = () => {
    const {
        formMode,
        appFields,
        changesMade,
        currentUser,
        formFields,
        hideProgress,
        selectedApp,
        selectedModel,
        selectedObject,
        setBackVisible,
        setBackButtonAction,
        setFormMode,
        setCalendarVisible,
        setChangesMade,
        setFieldSelectorVisible,
        setFormFields,
        setSelectedObject,
        showProgress,
        userRole
    } = useContext(Global);

    // Initialize fields as an array
    const [fields, setFields] = useState([]);

    // Status message
    const [statusMessage, setStatusMessage] = useState('');
    const [showStatusMessage, setShowStatusMessage] = useState(false);

    // Create an object for editing
    const [currentObject, setCurrentObject] = useState({}); // Default ADD object

    // Visibility of the delete confirm dialog
    const [confirmOpen, setConfirmOpen] = useState(false);

    // Fade
    const [isAnimating, setIsAnimating] = useState(false);

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setSelectedObject(null);
                setBackVisible(false);
            };
        });

        if (selectedObject) {
            setBackVisible(true);
        }

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [selectedObject, setBackButtonAction, setBackVisible]);

    // Determine the model's fields and sort them
    useEffect(() => {
        if (!selectedModel) return;
        const filtered = appFields.filter(f => f.modelKey === selectedModel.key)
        const sorted = filtered.sort((a, b) => a.sort - b.sort);
        setFields(sorted);
        setFormFields(sorted);
    }, [selectedModel, appFields, setFormFields]);

    // Hide the status message when the object changes
    useEffect(() => {
        setShowStatusMessage(false);
    }, [selectedObject]);

    // If the formMode changes to add, clear the currentObject
    useEffect(() => {
        if (formMode === FormMode.ADD) {
            setCurrentObject({});
            setShowStatusMessage(false);
        }
    }, [formMode]);

    // If we're in EDIT mode, load selectedObject into currentObject
    useEffect(() => {
        if (formMode !== FormMode.EDIT) return;
        if (!selectedObject) return;
        setCurrentObject(selectedObject);
    }, [formMode, selectedObject]);

    // Update the field's value in currentObject
    const onUpdate = useCallback((field, value) => {
        setChangesMade(true);
        setShowStatusMessage(false);
        setCurrentObject(prev => ({ ...prev, [field.key]: value }));
    }, [setChangesMade]);

    /**
     * Adds a new object record.
     */
    const handleAdd = async () => {
        if (!validateTitleField()) return;

        showProgress("Adding...");

        const now = Timestamp.now();

        const key = generateKey();

        // Handle special fields
        await prepareSpecialFields(key); // Use the new key in an add

        console.log('currentObject', currentObject);

        const data = {
            key: key,
            appKey: selectedApp.key,
            modelKey: selectedModel.key,
            userKey: currentUser.key,
            userFirstName: currentUser.firstName,
            userLastName: currentUser.lastName,
            username: currentUser.username,
            dateCreated: now,
            dateModified: now,
            ...currentObject
        };

        await objectManager.add(
            selectedApp.key,
            selectedModel.key,
            key,
            data
        );

        setCurrentObject(data);
        setSelectedObject(data);
        setFormMode(userRole === "ADMIN" ? FormMode.EDIT : FormMode.VIEW);

        setStatusMessage("Record added successfully.");

        postProcess();
    };

    /**
     * Saves changes to the currently selected record.
     */
    const handleSave = async () => {
        if (!validateTitleField()) return;

        showProgress("Saving...");

        // Handle special fields
        await prepareSpecialFields(selectedObject.key);

        // Proceed with saving the updated object
        await objectManager.update(
            selectedApp.key,
            selectedModel.key,
            selectedObject.key,
            currentObject
        );

        setStatusMessage("Record saved successfully.");

        postProcess();
    };

    /**
    * Prepares special fields for saving or adding.
    */
    const prepareSpecialFields = async (objectKey) => {
        for (const field of formFields) {
            // Handle gallery fields
            if (field.type === 'gallery') {
                await saveGallery(field, objectKey);
            }

            // Handle videogallery fields
            if (field.type === 'videogallery') {
                await saveVideoGallery(field, objectKey);
            }
        }
    };

    /**
    * Saves photo gallery and uploads files if necessary.
    */
    const saveGallery = async (field, objectKey) => {
        const galleryItems = currentObject[field.key] || [];
        const uploadedImages = [];
        const uploadPromises = [];

        for (const item of galleryItems) {
            if (item.url.startsWith('blob:')) {
                // Upload the local file
                const uploadPromise = new Promise((resolve, reject) => {
                    const image = new Image();
                    image.onload = async () => {
                        try {
                            const maxDimension = 1000;
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            const ratio = Math.min(maxDimension / image.width, maxDimension / image.height);
                            canvas.width = image.width * ratio;
                            canvas.height = image.height * ratio;
                            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                            const key = generateKey();
                            const fileExtension = 'jpeg'; // Assuming all images are JPEGs for simplicity
                            canvas.toBlob(async (blob) => {
                                try {
                                    const storageRef = ref(storage, `galleries/${selectedApp.key}/${selectedModel.key}/${field.key}/${objectKey}/${key}.${fileExtension}`);
                                    await uploadBytes(storageRef, blob);
                                    const fileUrl = await getDownloadURL(storageRef);

                                    // Store the remote URL and the caption
                                    uploadedImages.push({ url: fileUrl, caption: item.caption || '' });
                                    resolve();
                                } catch (error) {
                                    console.error("Error uploading file:", error);
                                    reject(error);
                                }
                            }, `image/${fileExtension}`);
                        } catch (error) {
                            console.error("Error processing image:", error);
                            reject(error);
                        }
                    };
                    image.src = item.url;
                });

                uploadPromises.push(uploadPromise);
            } else {
                // Already a remote URL, just keep it as is
                uploadedImages.push(item);
            }
        }

        await Promise.all(uploadPromises);

        // Update the currentObject with the new URLs and captions
        currentObject[field.key] = uploadedImages;
    };

    /**
     * Saves video gallery data and uploads files if necessary.
     */
    const saveVideoGallery = async (field, objectKey) => {

        const galleryItems = currentObject[field.key] || [];
        const uploadedVideos = [];
        const uploadPromises = [];

        for (const item of galleryItems) {
            if (item.url.startsWith('blob:')) {
                // Upload the local video file
                const uploadPromise = new Promise(async (resolve, reject) => {
                    try {
                        const response = await fetch(item.url);
                        const file = await response.blob();
                        const fileExtension = file.type.split('/').pop();
                        const key = generateKey();

                        // Optional file size check
                        const maxFileSizeMB = 30;
                        const fileSizeMB = file.size / (1024 * 1024);
                        if (fileSizeMB > maxFileSizeMB) {
                            alert(`File size exceeds ${maxFileSizeMB} MB. Please select a smaller file.`);
                            return reject(`File size exceeds ${maxFileSizeMB} MB.`);
                        }

                        // Log the size of the file in bytes
                        activity.log(selectedApp.key, 'uploads', file.size);

                        // Prepare the storage reference
                        const storageRef = ref(storage, `videogalleries/${selectedApp.key}/${selectedModel.key}/${field.key}/${objectKey}/${key}.${fileExtension}`);

                        // Upload the video
                        await uploadBytes(storageRef, file);
                        const fileUrl = await getDownloadURL(storageRef);

                        // Store the remote URL and the caption
                        uploadedVideos.push({ url: fileUrl, caption: item.caption || '' });
                        resolve();
                    } catch (error) {
                        console.error("Error during file upload:", error);
                        reject(error);
                    }
                });

                uploadPromises.push(uploadPromise);
            } else {
                // Already a remote URL, just keep it as is
                uploadedVideos.push(item);
            }
        }

        await Promise.all(uploadPromises);

        // Update the currentObject with the new URLs
        currentObject[field.key] = uploadedVideos;
    };

    /**
     * Displays a delete confirmation dialog.
     */
    const handleShowConfirm = async () => {
        setConfirmOpen(true);
    };

    /**
     * Delete the currently selected record.
     */
    const handleDelete = async () => {
        deleteObject();
    };

    /**
     * Handles a cancel response in the confirm dialog.
     */
    const handleCancel = async () => {
        setConfirmOpen(false);
    };

    /**
     * Deletes an object.
     */
    const deleteObject = async () => {
        setConfirmOpen(false);

        showProgress("Deleting...");

        if (!selectedObject) {
            alert('No object selected!');
            return;
        }

        try {
            const modelKey = selectedObject.modelKey;

            addObjectDeleteHeadline(modelKey);

            // Iterate over form fields to find and delete gallery files and headlines
            for (const field of formFields) {
                if (field.type === 'gallery' || field.type === 'videogallery') {
                    // Delete the headline
                    await headlineManager.deleteGalleryHeadlines(selectedObject.key)
                }
            }

            // Delete the object
            await objectManager.delete(selectedApp.key, modelKey, selectedObject, formFields);

            setSelectedObject(null);

            setStatusMessage('Item deleted successfully.');
        } catch (error) {
            console.error("Error deleting object and related data: ", error);
            setStatusMessage('Error deleting item.');
        }

        setFormMode(FormMode.ADD);

        hideProgress();
    };

    /**
     * Adds a headline when the object is deleted.
     */
    const addObjectDeleteHeadline = async (modelKey) => {

        if (selectedModel.titleFieldKey) {

            // Get the object title
            const objectTitle = selectedObject[selectedModel.titleFieldKey];

            // Get the model title
            const model = await modelManager.get(selectedApp.key, modelKey);
            const modelTitle = model.title;

            headlineManager.addDeletedHeadline(
                selectedApp.key,
                currentUser.key,
                currentUser.firstName,
                currentUser.lastName,
                objectTitle,
                modelKey,
                modelTitle
            );
        }
    };

    /**
     * Dislays the field selector for adding a feature to the form.
     */
    const handleAddFeature = async () => {
        setFieldSelectorVisible(true);
    };

    /**
     * Validates that the title field is non-empty.
     */
    const validateTitleField = () => {
        let title = currentObject[selectedModel.titleFieldKey]
        if (!title || title.length === 0) {
            const field = fields.find(f => f.key === selectedModel.titleFieldKey);
            alert(`Please enter a ${field.title}.`);
            return false;
        }
        return true;
    }

    /**
     * Routes the user after ADD and EDIT operations, according to device.
     */
    const postProcess = () => {
        if (isMobile) {
            setSelectedObject(null);
        } else {
            setShowStatusMessage(true);
        }

        setChangesMade(false);
        hideProgress();
    }

    // Fade
    useEffect(() => {
        if (selectedObject || formMode === FormMode.ADD) {
            // Trigger the animation
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
        }
    }, [selectedObject, formMode]);

    return (

        <div className={(isMobile || isTablet) ? "form-container-mobile" : "form-container"}
            style={{
                opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
            }}>

            <FormToolBar
                onFeatureClick={handleAddFeature}
                onSaveClick={handleSave}
                onDeleteClick={handleShowConfirm}
            />

            <div
                className={`form-scroller ${changesMade || formMode === FormMode.ADD ? 'shrink' : 'expand'
                    }`}>

                {/* STATUS MESSAGE */}
                {showStatusMessage &&
                    <FormStatusMessage
                        message={statusMessage}
                    />
                }

                {/* FIELDS */}
                {fields.map((field) => (
                    <FormField
                        object={selectedObject}
                        key={field.id}
                        field={field}
                        onUpdate={onUpdate}
                        readOnly={formMode === FormMode.VIEW}
                    />
                ))}

                {/* FOOTER */}
                <FormFooter />

            </div>

            {/* BUTTONS */}
            <div
                className={`form-buttons ${isMobile || changesMade || formMode === FormMode.ADD ? 'visible' : 'hidden'
                    }`}>
                {formMode === FormMode.EDIT ? (
                    <FormSaveButton onClick={handleSave} />
                ) : (
                    <>
                        {formMode === FormMode.ADD && selectedModel && <FormAddButton onClick={handleAdd} />}
                    </>
                )}
            </div>

            <FormDeleteConfirmation
                isOpen={confirmOpen}
                setIsOpen={setConfirmOpen}
                onConfirm={handleDelete}
                onCancel={handleCancel}
            />

        </div>
    );
};

export default Form;
