// Firebase
import { collection, writeBatch, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Managers
import { activity } from './ActivityManager';

class RoleManager {

    /**
     * Method to add a new role.
     * 
     * @param {string} appKey - App key.
     * @param {string} key - The new key of the role.
     * @param {string} title - Title for the role.
     * @returns {role} - New role.
    */
    async addRole(appKey, key, userKey, userFirstName, userLastName, title, summaryHeight = "50px") {

        // Current timestamp
        const now = Timestamp.now();

        const role = {
            key: key,
            appKey: appKey,
            userKey: userKey,
            userFirstName: userFirstName,
            userLastName: userLastName,
            title: title,
            summaryHeight: summaryHeight,
            dateCreated: now,
            dateModified: now,
        };

        await setDoc(doc(db, collections.roles, key), role);

        activity.log(appKey, 'writes', 1);

        return role;
    }

    /**
      * Fetches roles for an app.
      * 
      * @param {string} appKey - App key to get roles for.
      */
    async fetchRoles(appKey) {
        try {
            const rolesCollection = collection(db, collections.roles);
            const q = query(rolesCollection, where("appKey", "==", appKey), orderBy("title"));

            try {
                const snapshot = await getDocs(q);
                activity.log(appKey, 'reads', snapshot.docs.length);
                return snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            } catch (error) {
                console.error("Error fetching roles:", error);
                throw error;
            }
        } catch (error) {
            console.error("Error setting up role fetch:", error);
            throw error;
        }
    }

    /**
      * Fetches roles and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchRolesAndSubscribe(appKey, onUpdate) {
        try {
            // Create a reference to the roles collection
            const rolesCollection = collection(db, collections.roles);

            // Create a query to find roles by appKey and sort them by title
            const q = query(rolesCollection, where("appKey", "==", appKey), orderBy("title"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const roleList = snapshot.docs.map(doc => ({
                    id: doc.id, // Include the document ID if needed
                    ...doc.data() // Spread operator to include all fields from the document
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(roleList);
                    activity.log(appKey, 'reads', roleList.length);
                }
            }, error => {
                console.error("Error fetching roles:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    /**
      * Fetches a role by its role key.
      * 
      * @param {string} appKey - App key.
      * @param {string} roleKey - Key of the role to retrieve.
      */
    async fetchRole(appKey, roleKey) {
        try {
            // Create a reference to a specific role document using roleKey
            const roleRef = doc(db, collections.roles, roleKey);

            // Get the document
            const docSnap = await getDoc(roleRef);

            activity.log(appKey, 'reads', 1);

            if (docSnap.exists()) {
                // If the document exists, construct and return the role object
                return {
                    id: docSnap.id, // Include the document ID
                    ...docSnap.data() // Include all fields from the document
                };
            } else {
                // If no document exists for the provided key, handle accordingly
                console.log("No role found with the given roleKey.");
                return null; // Or you could throw an error, depending on your use case
            }
        } catch (error) {
            console.error("Error fetching role:", error);
            throw error; // Rethrow the error to handle it appropriately
        }
    }

    /**
      * Updates an role's data in the Firestore database.
      * 
      * @param {string} appKey - App key.
      * @param {string} roleKey - The key (document ID) of the role to update.
      * @param {string} title - Title of the role.
      * @param {string} share - Indicates whether the author allows the role to be shared.
      * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async updateRole(appKey, roleKey, title) {
        try {

            // Current timestamp
            const now = Timestamp.now();

            const data = {
                title: title,
                dateModified: now
            };

            // Update the document in Firestore
            await updateDoc(doc(db, collections.roles, roleKey), data);

            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Role updated successfully." };
        } catch (error) {
            console.error('Error updating role:', error);
            // Return an error message
            return { success: false, message: "Error updating role." };
        }
    }

    /**
     * Updates a role's data in the Firestore database.
     *
     * @param {string} appKey - App key.
     * @param {string} roleKey - The key (document ID) of the role to update.
     * @param {object} data - An object containing the role properties and their new values.
     */
    async updateRoleData(appKey, roleKey, data) {
        try {
            const now = Timestamp.now();

            // Ensure dateModified is always updated to the current timestamp
            data.dateModified = now;

            // Update the document in Firestore
            await updateDoc(doc(db, collections.roles, roleKey), data);

            // Log the activity - assuming an activity logging function exists
            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Role updated successfully." };
        } catch (error) {
            console.error('Error updating role:', error);
            // Return an error message
            return { success: false, message: "Error updating role." };
        }
    }

    /**
      * Deletes a role from the Firestore database.
      * 
      * @param {string} appKey - App key.
      * @param {string} roleKey - The key of the role to delete.
     */
    async deleteRole(appKey, roleKey) {
        try {
            // Start a batch
            const batch = writeBatch(db);

            // Fetch the role to get the roleKey
            const roleRef = doc(db, collections.roles, roleKey);
            const roleSnap = await getDoc(roleRef);

            activity.log(appKey, 'reads', 1);

            if (!roleSnap.exists()) {
                console.error("Role does not exist");
                return;
            }

            // Delete the selected role
            batch.delete(roleRef);

            activity.log(appKey, 'deletes', 1);

            // Function to delete documents from a given collection
            const deleteFromCollection = async (collectionPath) => {
                const querySnapshot = await getDocs(query(collection(db, collectionPath), where("roleKey", "==", roleKey)));
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                    activity.log(appKey, 'deletes', 1);
                });
            };

            // Delete fields associated with the role
            await deleteFromCollection(collections.fields);

            // Delete objects from their specific collection
            const objectsQuerySnapshot = await getDocs(collection(db, roleKey));

            activity.log(appKey, 'reads', objectsQuerySnapshot.docs.length);

            objectsQuerySnapshot.forEach((doc) => {
                batch.delete(doc.ref);

                activity.log(appKey, 'deletes', 1);
            });

            // Commit the batch
            await batch.commit();

        } catch (error) {
            console.error('Error deleting role:', error);
            // Return an error message
            return { success: false, message: "Error deleting role." };
        }
    }

    /**
      * Determines whether a role has a permission.
      * 
      * @param {string} roleKey - Key of the role.
      * @param {array} models - Array of models.
      * @param {string} modelKey - Key of the model.
      * @param {string} permission - Permission to check ("MODELVIEW", "ADD", "EDIT", or "DELETE")
      * @returns {checkbox} Result of the check.
     */
    async roleHasPermission(roleKey, models, modelKey, permission) {
        if (roleKey === "ADMIN") return true;
        
        // Find the model using the modelKey
        const model = models.find(model => model.key === modelKey);
        if (!model) {
            console.error("Model not found");
            return false;
        }
    
        // Map permissions to the relevant fields in the model
        const permissionMap = {
            MODELVIEW: 'noview',
            ADD: 'noadd',
            EDIT: 'noedit',
            DELETE: 'nodelete'
        };
    
        // Get the relevant no-access array from the model based on the permission
        const noAccessArray = model[permissionMap[permission.toUpperCase()]];
        if (!noAccessArray) {
            console.error("Invalid permission type");
            return false;
        }
    
        // Check if the roleKey is in the no-access array
        return !noAccessArray.includes(roleKey);
    }
}

export default RoleManager;
