import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../../common/utilities/Constants';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Utilities
import { getElapsedTime } from '../../common/utilities/DateTime';

// Styles
import './RunHome.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import RemoveIcon from '../../common/svg/RemoveIcon';

// Components
import CheckedHeadline from './headlines/CheckedHeadline';
import CommentedHeadline from './headlines/CommentedHeadline';
import DocumentAddedHeadline from './headlines/DocumentAddedHeadline';
import DeletedHeadline from './headlines/DeletedHeadline';
import EditedHeadline from './headlines/EditedHeadline';
import EventAddedHeadline from './headlines/EventAddedHeadline';
import GalleryHeadline from './headlines/GalleryHeadline';
import JoinedHeadline from './headlines/JoinedHeadline';
import ListedHeadline from './headlines/ListedHeadline';
import PostHeadline from './headlines/PostHeadline';
import RunHomePost from './RunHomePost';
import RunHomeToolBar from './RunHomeToolBar';
import UserSelectedHeadline from './headlines/UserSelectedHeadline';

// Managers
import AppUserManager from '../../common/managers/AppUserManager';
import HeadlineManager from '../../common/managers/HeadlineManager';
import ObjectManager from '../../common/managers/ObjectManager';

const appUserManager = new AppUserManager();
const headlineManager = new HeadlineManager();
const objectManager = new ObjectManager();

const RunHome = () => {
  const { theme } = useTheme();
  const {
    editingHeadlines,
    events,
    headlines,
    models,
    resetVisibility,
    selectedApp,
    setHeadlines,
    setFormMode,
    setAppUsersVisible,
    setEventVisible,
    setSelectedAppUser,
    setSelectedEvent,
    setSelectedModel,
    setSelectedObject,
    userRole
  } = useContext(Global);

  useEffect(() => {
    const handleUpdate = (items) => {
      setHeadlines(items);
    };

    const unsubscribe = headlineManager.fetchHeadlinesAndSubscribe(selectedApp.key, handleUpdate);

    return () => unsubscribe();
  }, [selectedApp, setHeadlines]);

  const handleUserClick = async (userKey) => {
    const appUser = await appUserManager.fetchAppUser(userKey, selectedApp.key);
    resetVisibility();
    setSelectedAppUser(appUser);
    setAppUsersVisible(true);
  };

  const handleObjectClick = async (modelKey, objectKey) => {
    resetVisibility();
    const model = models.find(model => model.key === modelKey);
    setSelectedModel(model);
    const object = await objectManager.fetch(selectedApp.key, modelKey, objectKey);
    setSelectedObject(object);
    setFormMode(userRole === "ADMIN" ? FormMode.EDIT : FormMode.VIEW);
  };

  const handleEventClick = async (eventKey) => {
    const event = events.find(e => e.key === eventKey);
    setSelectedEvent(event);
    setEventVisible(true);
  };

  const handleModelClick = (modelKey) => {
    resetVisibility();
    const model = models.find(model => model.key === modelKey);
    setSelectedModel(model);
  };

  const handleFileClick = (fileUrl) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  const handleRemove = (headline) => {
    headlineManager.delete(headline.key);
  };

  const getTimestamp = (headline) => {
    return <span
      className="run-home-headline-timestamp"
      style={{
        color: theme.foregroundColorFaded
      }}
    >
      {getElapsedTime(headline.dateCreated)}
    </span>
  };

  return (
    <>
      <div className={(isMobile || isTablet) ? "run-home-container-mobile" : "run-home-container"}>
      {/*
        <RunHomePost />
        <div className={(isMobile || isTablet) ? "run-home-feed-mobile" : "run-home-feed"}
          style={{
            borderRightColor: (isMobile || isTablet) ? "transparent" : theme.backgroundColorFaded
          }}>
          <RunHomeToolBar />
          {headlines.map((headline) => (
            <div key={headline.key}>
              <div className="run-home-headline-wrapper">
                <div
                  className="run-home-headline"
                  style={{
                    color: theme.foregroundColorFaded
                  }}
                >
                  {headline.type === "POST" &&
                    <PostHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "JOINED" &&
                    <JoinedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "DELETED" &&
                    <DeletedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleModelClick={handleModelClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "EDITED" &&
                    <EditedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleObjectClick={handleObjectClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "EVENTADDED" &&
                    <EventAddedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleEventClick={handleEventClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "USERSELECTED" &&
                    <UserSelectedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleObjectClick={handleObjectClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "CHECKED" &&
                    <CheckedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleObjectClick={handleObjectClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "COMMENTED" &&
                    <CommentedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleObjectClick={handleObjectClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "LISTED" &&
                    <ListedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleObjectClick={handleObjectClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "DOCADDED" &&
                    <DocumentAddedHeadline
                      headline={headline}
                      handleUserClick={handleUserClick}
                      handleFileClick={handleFileClick}
                      handleObjectClick={handleObjectClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }

                  {headline.type === "GALLERYADDED" &&
                    <GalleryHeadline
                      headline={headline}
                      handleObjectClick={handleObjectClick}
                      handleUserClick={handleUserClick}
                      timestamp={getTimestamp(headline)}
                    />
                  }
                </div>
                {userRole === "ADMIN" && editingHeadlines &&
                  <div className="run-home-headline-remove-container" onClick={() => handleRemove(headline)}>
                    <RemoveIcon
                      color={theme.foregroundColorFaded}
                      width="16"
                      height="16"
                    />
                  </div>
                }
              </div>
              <div
                className="run-home-divider"
                style={{
                  borderBottomColor: theme.backgroundColorFaded
                }}
              ></div>
            </div>
          ))}
        </div>
      */}
      </div>
    </>
  );
};

export default RunHome;
