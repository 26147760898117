import React from 'react';

// Styles
import './ChannelTypeItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ChannelTypeItem Component
 * 
 * This component renders a channel type.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelTypeItem = ({ icon: IconComponent, label, isFirst = false, isLast = false, selected = false, onClick }) => {
    const { theme } = useTheme();

    return (
        <div className="channel-type-item-container"
            style={{
                backgroundColor: selected ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                borderTopLeftRadius: isFirst ? '10px' : '0',
                borderTopRightRadius: isFirst ? '10px' : '0',
                borderBottomLeftRadius: isLast ? '10px' : '0',
                borderBottomRightRadius: isLast ? '10px' : '0',
                borderBottom: isLast ? 'none' : `1px solid ${theme.backgroundColor}`,
                cursor: 'pointer' // Change cursor to pointer for better UX
            }}
            onClick={onClick}>

            {/* ICON */}
            <div className="channel-type-item-icon">
                <IconComponent
                    color={selected ? theme.highlightForegroundColor : theme.foregroundColor}
                    width="16"
                    height="16"
                />
            </div>

            {/* LABEL */}
            <div className="channel-type-item-text"
                style={{
                    color: selected ? theme.highlightForegroundColor : theme.foregroundColor
                }}>
                {label}
            </div>

        </div>
    );
};

export default ChannelTypeItem;
