import React from 'react';

// Styles
import './TimePreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const TimePreview = ({ 
    placeholder = '', 
    selected = false,
    value = ''
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="time-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
            <div className="time-preview-label"
                style={{color: theme.foregroundColorFaded}}
            >
                {placeholder}
            </div>
            <input type="text"
                className="time-preview-input"
                style={{color: theme.foregroundColor}}
                readOnly
                value="10:00 AM"
                tabIndex={-1}>
            </input>
        </div>
    );
};

export default TimePreview;
