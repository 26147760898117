import React from 'react';

// Styles
import './ChatPostFormVideos.css';

// Components
import ChatPostFormVideo from './ChatPostFormVideo';

const ChatPostFormVideos = ({ urls }) => {

  return (
    <>
      {urls && urls.length > 0 &&
        <div className="chat-post-form-videos-container">
          {urls.map((url, index) => (
            <ChatPostFormVideo 
              index={index}
              url={url}
            />
          ))}
        </div>
      }
    </>
  );
};

export default ChatPostFormVideos;
