import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { generateKey } from '../../common/utilities/Keys';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Styles
import './ConversationAddButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import PlusIcon from '../../common/svg/PlusIcon';

// Components
import UserSearch from '../usersearch/UserSearch';

// Managers
import ConversationManager from '../../common/managers/ConversationManager';

const conversationManager = new ConversationManager();

const ConversationAddButton = () => {
  const { theme } = useTheme();
  const { 
    currentUser,
    selectedApp,
    setUserSearchVisible 
  } = useContext(Global);

  const handleAdd = async () => {
    setUserSearchVisible(true);
  };

  const onSearch = async (entry) => {
    // Create a new conversation by adding the current user and the selected user
    const participants = [
      {
        userKey: currentUser.key,
        userName: currentUser.firstName + ' ' + currentUser.lastName
      },
      {
        userKey: entry.userKey,
        userName: entry.userName
      }
    ];

    // Current timestamp
    const now = Timestamp.now();

    const key = generateKey();

    const data = {
      appKey: selectedApp.key,
      key: key,
      participants: participants,
      lastMessageRead: false,
      dateCreated: now
    };

    await conversationManager.add(selectedApp.key, key, data);

    // Close the search dialog
    setUserSearchVisible(false);
  };

  return (
    <>
      <div className="conversation-add-button-container"
        style={{ backgroundColor: theme.highlightBackgroundColor }}
        onClick={handleAdd}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>

      <UserSearch
        onSearch={onSearch}
      />
    </>
  );
};

export default ConversationAddButton;
