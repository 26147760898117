import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { getElapsedTime } from '../../../utilities/DateTime';

// Styles
import './ForumPost.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Document from '../../components/document/Document';
import ForumPostComments from '../ForumPostComments';
import Gallery from '../../components/gallery/Gallery';
import Object from '../../../objects/Object';
import UserDot from '../../../components/userdot/UserDot';
import VideoGallery from '../../components/videogallery/VideoGallery';

const ForumPost = () => {
    const { theme } = useTheme();
    const {
        selectedForumPost,
        setBackButtonAction, // Navigation
        setBackVisible, // Navigation
        setSelectedForumPost
    } = useContext(Global);

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setSelectedForumPost(null);
                setBackVisible(false);
            };
        });

        if (selectedForumPost) {
            setBackVisible(true);
        }

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [selectedForumPost, setBackButtonAction, setBackVisible]);

    // State Variables
    const [post, setPost] = useState(null);

    /**
     * Set the post from the selected post.
     */
    useEffect(() => {
        if (!selectedForumPost) return;
        setPost(selectedForumPost);
    }, [selectedForumPost]);

    const getTimestamp = (post) => {
        return <span
            className="forum-post-timestamp"
            style={{
                color: theme.foregroundColorFaded
            }}>
            {getElapsedTime(post.dateCreated)}
        </span>
    };

    if (!post) return null;

    return (
        <div className="forum-post-container">

            {/* HEADER */}
            <div className="forum-post-header">

                {/* LEFT */}
                <div className="forum-post-header-left">

                    {/* USER DOT */}
                    <UserDot
                        letter={post.username && post.username.toUpperCase()[0]}
                        size="23"
                    />

                    {/* USERNAME */}
                    <div className="forum-post-user"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {post.username}
                    </div>

                    {/* TIMESTAMP */}
                    <div className="forum-post-timestamp"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        • {getTimestamp(post)}
                    </div>

                </div>
                <div className="forum-post-header-right">

                </div>

            </div>

            {/* WRAPPER */}
            <div className="forum-post-wrapper">

                {/* TITLE */}
                <div className="forum-post-title"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {post.title}
                </div>

                {/* CONTENT */}
                <div className="forum-post-content"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {post.content}
                </div>

                {/* DOCUMENTS */}
                {post.documents && post.documents.length > 0 &&
                    <div className="forum-post-documents-wrapper">
                        {post.documents.map((document) => (
                            <Document
                                key={document.id}
                                document={document}
                            />
                        ))}
                    </div>
                }

            </div>

            {/* PHOTOS */}
            {post.photoUrls && post.photoUrls.length > 0 &&
                <div className="forum-post-gallery-wrapper">
                    <Gallery key={post.key} post={post} />
                </div>
            }

            {/* VIDEOS */}
            {post.videoUrls && post.videoUrls.length > 0 &&
                <div className="forum-post-gallery-wrapper">
                    <VideoGallery key={post.key} post={post} />
                </div>
            }

            {/* OBJECTS */}
            {post.objects && post.objects.length > 0 &&
                <div className="forum-post-object-wrapper">
                    {post.objects.map((object) => (
                        <div key={object.id} className="forum-post-object"
                            style={{
                                backgroundColor: theme.backgroundColorFaded
                            }}>
                            <Object object={object} />
                        </div>
                    ))}
                </div>
            }

            {/* COMMENTS */}
            <ForumPostComments
                post={post}
            />
        </div>
    );
};

export default ForumPost;
