import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountEmail.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers
import UserManager from '../../../common/managers/UserManager';

const userManager = new UserManager();

/**
 * AccountEmail Component
 * 
 * This component renders an input for a new user's email address.
 * 
 * @param {function} onChange - The function to call with updated values.
 * @param {string} defaultValue - The default email value.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountEmail = ({ onChange, defaultValue = null }) => {
  const { theme } = useTheme();
  const { selectedApp, studio } = useContext(Global);

  // State Variables
  const [emailValue, setEmailValue] = useState(defaultValue || ''); // Initialize with defaultValue or empty string
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    // If defaultValue is present, set the value and prevent further editing
    if (defaultValue) {
      setEmailValue(defaultValue);
    }
  }, [defaultValue]);

  /**
   * Method to validate email format.
   * @param {string} email - Email to validate.
   * @returns {boolean} - Whether the email is valid.
   */
  const isValidEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex for validation
    return emailRegex.test(email);
  };

  /**
   * Method to handle email input changes and validation.
   * 
   * @param {Event} e - Change event
   */
  const handleEmailChange = async (e) => {
    const email = e.target.value.trim(); // Remove any spaces
    setEmailValue(email); // Update the local state for email

    onChange(email, false); // Assume invalid initially

    // Validate email presence
    if (!email) {
      setEmailError('A valid email address is required.');
      return;
    }

    // Validate email format
    if (!isValidEmailFormat(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    // Check if the email already exists asynchronously
    const exists = await userManager.emailExists(email);
    if (exists) {
      setEmailError('An account with this email already exists.');
      return;
    }

    // If the email is valid and doesn't exist, clear the error
    setEmailError('');

    // Pass the valid email back to the parent
    onChange(email, true);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="account-email-container">

        {/* EMAIL INPUT */}
        <input
          type="email"
          className="account-email-input"
          style={{
            color: selectedApp ? 
              (!!defaultValue ? theme.foregroundColorFaded : theme.foregroundColor) : 
              (!!defaultValue ? studio.foregroundColorFaded : studio.foregroundColor),
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded,
          }}
          value={emailValue} // Controlled input value from state
          onChange={defaultValue ? null : handleEmailChange} // Disable onChange if defaultValue is set
          placeholder="Email Address"
          required
          readOnly={!!defaultValue} // Make the input read-only if defaultValue is provided
        />

        {/* ERROR MESSAGE */}
        {emailError && (
          <div
            className="account-email-error"
            style={{
              color: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
            }}>
            {emailError}
          </div>
        )}
      </div>
    </>
  );
};

export default AccountEmail;
