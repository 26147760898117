import React from 'react';

const SearchIcon = ({ color = "white", width = "16", height = "16" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 16 16" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
    <path d="m11.25 11.25 3 3" />
    <circle cx="7.5" cy="7.5" r="4.75" />
  </svg>

);

export default SearchIcon;
