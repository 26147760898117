import React from 'react';

const SelectIcon = ({ color = "white", width = "16", height = "16" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 16 16" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="m5.75 12.25 4.5-4.25-4.5-4.25" />
    </svg>
);

export default SelectIcon;
