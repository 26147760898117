import React from 'react';

// Styles
import './ResizeHandles.css';

// Components
import ResizeHandle from '../resizehandle/ResizeHandle';

/**
 * ResizeHandles Component
 * 
 * This component renders resize handles for an element on the canvas.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ResizeHandles = ({ element }) => {

  return (
    <div>
      <ResizeHandle element={element} direction="top-left" />
      <ResizeHandle element={element} direction="top-right" />
      <ResizeHandle element={element} direction="bottom-left" />
      <ResizeHandle element={element} direction="bottom-right" />
    </div>
  );

};

export default ResizeHandles;
