import React from 'react';

// Styles
import './ForumPostFormPhoto.css';

const ForumPostFormPhoto = ({ index, url }) => {

  return (
    <img key={index} src={url} alt="attachment" className="forum-post-form-photo" />
  );
};

export default ForumPostFormPhoto;
