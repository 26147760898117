import axios from 'axios';

// Utilities
import { generateKey } from '../utilities/Keys';
import { defaultSummaryHeight, defaultSummaryTitleStyles } from '../utilities/Defaults';
import { FormMode } from '../utilities/Constants';

// Managers
import AdminManager from './AdminManager';
import FieldManager from './FieldManager';
import ModelManager from './ModelManager';

const adminManager = new AdminManager();
const fieldManager = new FieldManager();
const modelManager = new ModelManager();

class PromptManager {

    async send(preparedPrompt) {
        const apiKey = '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'; // Use your actual API key
        const endpoint = 'https://api.openai.com/v1/chat/completions'; // API endpoint URL

        try {
            const response = await axios.post(
                endpoint,
                {
                    model: "gpt-4o", // Model specification, adjust if necessary
                    messages: [{
                        role: "user",
                        content: preparedPrompt
                    }]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${apiKey}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Check if the response has the expected structure and content
            if (response.data && response.data.choices && response.data.choices.length > 0) {
                return response.data.choices[0].message.content; // Return the content as a string
            }
            return 'No response content found.'; // Return a default message if the response structure is unexpected
        } catch (error) {
            console.error('Error calling the ChatGPT API:', error);
            if (error.response.status === 429) {
                const retryAfter = error.response.headers['retry-after'];
                console.log(`Rate limit exceeded. Retry after ${retryAfter} seconds.`);
            }
            return 'Failed to get response from the API.'; // Return error message as a string
        }
    }

    async prepareAppPrompt(prompt) {

        let instructions = `
[APP CREATION - START PRELIMINARY INSTRUCTIONS]

[GOAL]
The goal of your response is to define the structure of an app that will allow people to manage data. 
Your response should contain ONLY JSON, and no surrounding explanation or any other text. 
The entire response MUST be formatted as valid JSON, or I will be unable to process your response. 
Please do not add comments or characters that might lead to the inability to parse the JSON.

[TITLE AND DESCRIPTION]
The app will need a title and description. The prompter may suggest an app title and description. If so, use those. 
Otherwise, populate those items based upon the type of app the user is requesting. The max length of the title 
should be 40 and the max length of the description should be 160.

[THEME]
The app will also need a theme. We need two color schemes for the app, one for dark mode and one for light mode.
Please choose a color scheme for the app that might appeal to potential users and that reflects the purpose of the app. 
Please provide unique hex values for the following:
- backgroundColorDark
- backgroundColorDarkFaded
- foregroundColorDark
- foregroundColorDarkFaded
- highlightBackgroundColorDark (button backgrounds)
- highlightForegroundColorDark (button foregrounds)
- backgroundColorLight
- backgroundColorLightFaded
- foregroundColorLight
- foregroundColorLightFaded
- highlightBackgroundColorLight
- highlightForegroundColorLight

[MODELS]
The app is based upon models. The models will have fields eventually, but for now I simply want to identify as many models
as possible that could make as comprehensive and useful an app as possible. Model titles should be plural. Titles will be used
as menu items in the app, so please be user friendly with the titles. Model descriptions will
provide some useful information about the purpose of each model in the app. There is no need to provide models that are
related to scheduling or calendar functions, as those are already built into the app.

Please return the response in JSON format exactly as I have outlined. Do not add any nesting, do not introduce any new fields, and do not structure the response differently from the example provided below. Here's the exact format:

[EXAMPLE RESPONSE]
{
    "appTitle": "My Realtor App",
    "appDescription": "An app to help realtors at a realty to manage listings, listings and other information.",
    "backgroundColorDark": "#1F2937",
    "backgroundColorDarkFaded": "#374151",
    "foregroundColorDark": "#FFFFFF",
    "foregroundColorDarkFaded": "#999999",
    "highlightColorDark": "#C9700F",
    "backgroundColorLight": "#111111",
    "backgroundColorLightFaded": "#444444",
    "foregroundColorLight": "#222222",
    "foregroundColorLightFaded": "#444444",
    "highlightColorLight": "#C9700F",
    "models": [
        {
            "title": "Realtors",
            "description": "The realtors in our group."
        },
        {
            "title": "Listings",
            "description": "All listings in our firm."
        },
        {
            "title": "Leads",
            "description": "A list of potential buyers."
        },
        {
            "title": "Contacts",
            "description": "Helpful list of contacts for our realtors."
        },
        {
            "title": "Appointments",
            "description": "Scheduling for all realtors."
        },
    ]
}

The actual prompt is:

[END PRELIMINARY INSTRUCTIONS]

        `;

        return instructions + " " + prompt;
    }

    async prepareModelPrompt(appTitle, appDescription, modelTitle, modelDescription, models) {

        let instructions = `
[GOAL - CREATE A MODEL FIELD LIST]
The purpose of our system is to utilize AI to assist users to create useful apps. It is based upon models. Each model has fields. 
Each field represents an attribute about the model. 

The purpose of this query is to use a model title and description to identify as many fields as possible that could make sense for
the given model and provide as comprehensive and useful a field list as possible. There will be 3 things I need for each field:

    - title
    - description
    - type

Your response should contain ONLY JSON, and no surrounding explanation or any other text. 
The entire response MUST be formatted as valid JSON, or I will be unable to process your response. 
Please do not add comments or characters that might lead to the inability to parse the JSON.

For context, here is some preliminary information that describes the app and model.

[APP INFORMATION]
App Title: "${appTitle}"
App Description: "${appDescription}"

[MODEL INFORMATION]
Model Title: "${modelTitle}"
`;
        if (modelDescription !== "") {
            instructions += `Model Description: "${modelDescription}"`
        }

        instructions += `
        
Our fields will have a title, description, and a type. Fields of a model are limited to the following core types:

- checkbox
- checklist
- comments (comments section)
- countries
- currency
- date
- day (list of weekdays)
- documents (stores attached documents)
- gallery (image gallery)
- image (single image)
- lookup (drop-down list of options)
- month (list of months)
- number (decimals to any length)
- password
- phone
- states (US states)
- text (text field)
- time (list of times in a day)
- year
        
If you cannot determine the best type of field to use for a particular type of data, just use “text”.

It is very important that we have one field as the priority 1 field for the model. This field must be of 
type "text". The title would reflect the most logical name for the type of model. 

Each model MUST have at least one field of type "text", sorted before any other text types, which can be used
as the identifying title field of any objects of this model type. For example, for a person 
it might be "Full Name". For a movie it might be "Title". When returning the field list, place the priority 1
field before any other text fields. You can have other fields before it, just don't put any other text fields
before it. When processing your response, I will search for the first "text" field, and process it as the priority one field.

For models that might need images associated with them, please add a gallery as the first field.

For lookups, if there is a well-defined set of options, such as baseball positions for a "Baseball Player" model, please
provide the list of options.

There is a special field type, "userlist". This field type
handles the relationships between models and users of the app.

[COMMENTS]
Another useful field is the "comments" field type, which places a comment section in the form for an item.If you think users may
want to have a conversation about an item, please add this field type.

[SORTING]
Please return the field list in order from what might be most important to least important.

[SUMMARY FIELDS]
The app you are creating will display lists of items.In these cases, we will be displaying summaries of each
database record which will allow app users to click on the summary and navigate to details.

Please return the response in JSON format exactly as I have outlined. Do not add any nesting, do not introduce any new fields, and do not structure the response differently from the example provided below. Here's the exact format:

[EXAMPLE RESPONSE - "Baseball Player" MODEL]
            {
                "fields": [
                    {
                        "title": "Full Name",
                        "description": "Full name of the player.",
                        "type": "text",

                    },
                    {
                        "title": "Phone Number",
                        "description": "Player phone number.",
                        "type": "phone"
                    },
                    {
                        "title": "Birthdate",
                        "description": "Player date of birth.",
                        "type": "date"
                    },
                    {
                        "title": "Position",
                        "description": "Preferred player position.",
                        "type": "lookup",
                        "options": [
                            "Pitcher",
                            "Catcher",
                            "First Base"
                        ]
                    },
                    {
                        "title": "Supporters",
                        "description": "A list of app users who support the player.",
                        "type": "userlist",
                        "relatedModel": "Player"
                    }
                ]
            }
            `;

        return instructions;
    }

    async processModelResponse(
        r,
        app,
        model,
        showProgress,
        setSelectedModel,
        setFormMode,
        resetVisibility,
        reportFields
    ) {
        try {

            showProgress("Generating " + model.title + " user interface...");

            setSelectedModel(model);

            console.log(r);

            const jsonString = this.cleanJSON(r);
            const jsonData = JSON.parse(jsonString);

            if (jsonData.fields && Array.isArray(jsonData.fields)) {
                var fieldSort = 0;
                var fieldIndex = 0; // Counter for the number of fields selected for the summary

                var titleFieldKey = "";

                let titleField = null;

                let elements = [];

                jsonData.fields.forEach((field) => {
                    const modelKey = model.key;
                    const fieldKey = generateKey();
                    const options = [];
                    if (field.options && Array.isArray(field.options)) {
                        // Iterate over each option title
                        field.options.forEach((optionTitle) => {
                            // Generate a key for each option
                            const optionKey = generateKey();
                            // Add the option with key and title to the options array
                            options.push({ key: optionKey, title: optionTitle });
                        });
                    }

                    let type = field.type;

                    let styles = [];

                    const data = {
                        appKey: app.key,
                        key: fieldKey,
                        title: field.title,
                        description: field.description,
                        type: type,
                        sort: fieldSort,
                        modelKey: model.key,
                        modelTitle: model.title,
                        modelDescription: model.description,
                        options: options
                    }

                    // Check if the field type is appropriate for the summary
                    const appropriateTypes = ["countries", "phone", "states", "text"];
                    if (appropriateTypes.includes(type)) {
                        if (fieldIndex === 0) {
                            // Priority #1 field
                            titleField = data;
                            titleFieldKey = fieldKey;
                            styles = defaultSummaryTitleStyles;
                            fieldIndex++;
                        }
                    }

                    fieldManager.add(
                        app.key,
                        fieldKey,
                        modelKey,
                        data
                    );

                    if (type === "currency") {
                        reportFields.push({ ...data });
                    }

                    fieldSort++;
                });

                // Create an element for the title field to add to the model's elements array
                let element = {
                    key: generateKey(),
                    field: titleField,
                    styles: defaultSummaryTitleStyles,
                    title: titleField.title,
                    type: "field"
                };
                
                elements.push(element);

                // Update the title field key in the model
                const modelData = {
                    titleFieldKey: titleFieldKey,
                    summaryHeight: defaultSummaryHeight,
                    elements: elements
                }

                await modelManager.updateData(app.key, model.key, modelData);

                // Merge the original model with the new property
                const updatedModel = {
                    ...model,
                    ...modelData
                };

                if (resetVisibility) {
                    resetVisibility();
                }

                // Set the selected model with the merged data
                setSelectedModel(updatedModel);

                setFormMode(FormMode.ADD);

            } else {
                console.log("No models found or invalid format.");
            }

        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };





    async prepareSummaryPrompt(app, model, fields) {

        let prompt = `

In my app I have models, and each model has fields. Users can generate models and their fields, so it is a flexible system in that regard.

In my UI I have a list of items, and I would like to display summaries of these items in a scrolling list. I need some UI layout help for the summary.

The items in the layout will be positioned absolutely. The width will be 420px, and the height will be 400px. Please confine all items within these constraints.
Provide me with the top, left, width, and height for each item in the summary layout.

Within width and height layout constraints of the entire card, I need to lay out attributes for the listing that might be important for a summary. For example, if the model is a real-estate listing, the user might want to see (within constraints of 420px x 400px.
- Photo (gallery)
- Title (text)
- Address (text)
- # Bedrooms
- # Bathrooms 
- Square feet

It's important that the layout is visually appealing and that the most important information is displayed prominently.

It's not necessary to include all fields in the response. Things need to fit comfortably within the 420px x 400px constraints once they are populated with data.

You will want to include only fields of the following types:

- currency
- date
- day 
- gallery
- lookup
- month 
- number
- phone
- states
- text
- time
- year

[EXAMPLE RESPONSE]
{
    "fields": [
        {
            "key": "4402983ut-02389ut",
            "title": "Photo Gallery",
            "type": "gallery",
            "top": "0px"
            "left": "0px"
            "width": "420px"
            "height": "300px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Address",
            "type": "text",
            "top": "200px"
            "left": "0px"
            "width": "420px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Number of Bedrooms",
            "type": "text",
            "top": "240px"
            "left": "0px"
            "width": "140px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Number of Bathrooms",
            "type": "text",
            "top": "240px"
            "left": "140px"
            "width": "140px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Square Feet",
            "type": "text",
            "top": "240px"
            "left": "280px"
            "width": "140px"
            "height": "50px"
        },
    ]
}

For context, I will give you information about the app, the model, and each possible field for the model.

        App Title: "` + app.title + `"
        App Description: "` + app.description + `"
        Model Name: "` + model.title + `"
        Model Description: "` + model.description + `"
        Model Fields:`;

        for (var i = 0; i < fields.length; i++) {
            prompt += ` - "` + fields[i].title + `" (type: ` + fields[i].type + `) (key: ` + fields[i].key + `)\n`;
        }

        return prompt;
    }





















    async prepareRolesPrompt(appTitle, appDescription) {

        let instructions = `
[GOAL - CREATE APP ROLES]
The purpose of our system is to utilize AI to assist users to create useful apps. To accomplish this, various groups
of users will need to have access to different security settings according to their role.

The purpose of this query is to use a app title and description to identify as many roles as possible that could make sense for
the given app.

It is not necessary for any kind of overall "Admin" or "Administrator" role, as there is already a built-in role to take care of that.

Your response should contain ONLY JSON, and no surrounding explanation or any other text. 
The entire response MUST be formatted as valid JSON, or I will be unable to process your response. 
Please do not add comments or characters that might lead to the inability to parse the JSON.

For context, here is some preliminary information that describes the app.

[APP INFORMATION]
App Title: "${appTitle}"
App Description: "${appDescription}"

[EXAMPLE RESPONSE]
{
    "roles": [
        {
            "title": "Coaches"
        },
        {
            "title": "Players"
        },
        {
            "title": "Parents"
        },
        {
            "title": "Managers"
        }
    ]
}
        `;

        return instructions;
    }

    cleanJSON = (jsonString) => {
        try {
            // Remove any characters before the first '{'
            const startIndex = jsonString.indexOf('{');
            // Remove any characters after the last '}'
            const endIndex = jsonString.lastIndexOf('}') + 1;

            // Extract the valid JSON string
            const cleanString = jsonString.substring(startIndex, endIndex);

            // Parse the cleaned JSON string
            return cleanString;
        } catch (e) {
            throw new Error('Invalid JSON string');
        }
    };

    async prepareFieldsPrompt(app, models, model, fields) {

        let prompt = `

        App Title: "${app.title}"
        App Description: "${app.description}"
        
        Model Title: "${model.title}"

        Existing Fields:
        `;

        for (var i = 0; i < fields.length; i++) {
            prompt += ` - "` + fields[i].title + `"
            `;
        }

        prompt += `

        Existing Models:
        `;

        for (var x = 0; x < models.length; x++) {
            prompt += ` - "${models[x].title}" (key: ${models[x].key})
        `;
        }

        const instructions = await adminManager.fetchSetting("FIELDS_PROMPT");
        return instructions.value + " " + prompt;
    };
}



export default PromptManager;

