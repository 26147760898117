import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './FormFooter.css';

// Theme
import { useTheme } from '../../ThemeContext';

const FormFooter = () => {
    const { theme } = useTheme();
    const {
        selectedObject,
    } = useContext(Global);

    const DateDisplay = ({ label, date }) => (
        <div className="form-footer-date-container">
            <div className="form-footer-date-label"
                style={{ color: theme.foregroundColorFaded }}>
                {label}:&nbsp;
            </div>
            <div className="form-footer-date-value"
                style={{ color: theme.foregroundColorFaded }}>
                {formatDate(date)}
            </div>
        </div>
    );

    const formatDate = (firestoreTimestamp) => {
        // Check if the firestoreTimestamp is undefined or null
        if (!firestoreTimestamp) {
            return "N/A";
        }

        // Convert Firestore Timestamp to JavaScript Date object
        const date = firestoreTimestamp.toDate();

        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <>
            <br />

            {selectedObject && selectedObject.dateCreated &&
                <DateDisplay label="Created" date={selectedObject.dateCreated} />
            }
            {selectedObject && selectedObject.dateModified &&
                <DateDisplay label="Modified" date={selectedObject.dateModified} />
            }

            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default FormFooter;
