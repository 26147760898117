import React, { useRef } from 'react';

// Styles
import './ForumPostFormDocumentButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DocumentIcon from '../../../svg/DocumentIcon';

const ForumPostFormDocumentButton = ({ documentFiles, setDocumentFiles }) => {
  const { theme } = useTheme();

  // References
  const documentInputRef = useRef(null);

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    const newDocumentFiles = files.map(file => ({
        file,
        url: URL.createObjectURL(file)
    }));
    setDocumentFiles([...documentFiles, ...newDocumentFiles]);
    documentInputRef.current.value = "";
};

  return (
    <>
      {/* CONTAINER */}
      <div className="forum-post-form-document-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => documentInputRef.current.click()}>

        {/* ICON */}
        <DocumentIcon
          color={theme.foregroundColor}
          width="16"
          height="16"
        />

        {/* INPUT */}
        <input
          ref={documentInputRef}
          id="document-upload"
          type="file"
          accept=".pdf,.doc,.docx,.txt,.ppt,.pptx,.xls,.xlsx,.csv,.rtf,.html,.zip"
          onChange={handleDocumentUpload}
          style={{ display: 'none' }}
          multiple
        />

      </div>
    </>
  );
};

export default ForumPostFormDocumentButton;
