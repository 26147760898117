export const LoginMode = {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP'
};

export const FormMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};

export const ChannelType = {
    TEXT: 'TEXT',
    FORUM: 'FORUM',
    VOICE: 'VOICE',
};