import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountRequiredField.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers
import UserManager from '../../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * AccountRequiredField Component
 * 
 * This component renders an input for a user's value.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountRequiredField = ({ fieldKey, fieldName, onChange, defaultValue = '' }) => {
  const { theme } = useTheme();
  const {
    currentUser,
    selectedApp,
    studio
  } = useContext(Global);

  // State Variables
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  /**
   * Initializes the field value.
   */
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * Method to handle value input change.
   * 
   * @param {Event} e - Change event
   */
  const handleRequiredFieldValueChange = async (e) => {
    const value = e.target.value.trim(); // Trim any unnecessary spaces

    onChange(value, false); // Assume invalid initially

    // Validate presence
    if (!value) {
      setError('A valid value is required.');
      return;
    }

    // Validate length (3 to 20 characters)
    if (value.length < 3 || value.length > 20) {
      setError('Value must be between 3 and 20 characters.');
      return;
    }

    // Check if handle already exists (asynchronously)
    if (fieldKey === 'handle') {

      // Validate character set (alphanumeric, underscore, hyphen, period)
      const valueRegex = /^[a-zA-Z0-9_.@-]{3,20}$/; // Don't allow spaces
      if (!valueRegex.test(value)) {
        setError('Value can only contain letters, numbers, underscores, hyphens and periods.');
        return;
      }

      // Make sure the handle is prefixed with an '@' symbol
      if (!value.startsWith('@')) {
        setError('Handle must start with an "@" symbol.');
        return;
      }

      // Check if handle already exists, and if the currentUser
      // exists, exclude the current user from the check
      const exists = await userManager.handleExists(value);
      if (exists && (!currentUser || currentUser.handle !== value)) {
        setError('An account with this handle already exists.');
        return;
      }
    } else {

      // Validate character set (alphanumeric, underscore, hyphen, period)
      const valueRegex = /^[a-zA-Z0-9_.@ -]{3,20}$/; // Allow spaces
      if (!valueRegex.test(value)) {
        setError('Value can only contain letters, numbers, underscores, hyphens, spaces and periods.');
        return;
      }

    }

    // If we have a valid value and it doesn't exist, clear the error
    setError('');

    // Let the parent know the value is valid
    onChange(value, true);
  };

  return (
    <>

      {/* CONTAINER */}
      <div className="account-required-field-container">

        {/* INPUT */}
        <input
          type="text"
          className="account-required-field-input"
          style={{
            color: studio.foregroundColor,
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded
          }}
          value={value}
          onChange={handleRequiredFieldValueChange}
          placeholder={fieldName}
          required
        />

        {/* ERROR MESSAGE */}
        {error && (
          <div
            className="account-required-field-error"
            style={{
              color: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
            }}>
            {error}
          </div>
        )}
      </div>

    </>
  );
};

export default AccountRequiredField;
