import React from 'react';

// Utilities
import { truncateFileName } from '../../../../../utilities/Strings';

// Styles
import './ChatPostFormVideo.css';

// Images
import VideoIcon from '../../../../../svg/VideoIcon';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

const ChatPostFormVideo = ({ url }) => {
  const { theme } = useTheme();

  // Utility function to extract the file name from the URL
  const getFileNameFromUrl = (url) => {
    // Split the URL by '/' and take the last segment (the file name)
    return url.split('/').pop();
  };

  // Get the file name from the URL
  const fileName = getFileNameFromUrl(url);

  return (
    <div className="chat-post-form-document-container"
      style={{
        backgroundColor: theme.backgroundColorFaded
      }}>
      <VideoIcon
        color={theme.highlightBackgroundColor}
        width="14"
        height="14"
      />
      <div
        className="chat-post-form-document-filename"
        style={{
          color: theme.foregroundColor
        }}>
        {/* Pass the extracted file name to the truncate function */}
        {truncateFileName(fileName, 100)}
      </div>
    </div>
  );
};

export default ChatPostFormVideo;
