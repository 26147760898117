import React from 'react';

// Styles
import './LookupPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const LookupPreview = ({ 
    placeholder = '', 
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="lookup-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
            <div className="lookup-preview-label"
                style={{color: theme.foregroundColorFaded}}
            >
                {placeholder}
            </div>
            <select 
                className="lookup-preview-input"
                style={{color: theme.foregroundColor}}
                readOnly
                >
                <option value="0">Option A</option>
            </select>
        </div>
    );
};

export default LookupPreview;
