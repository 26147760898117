import React, { useEffect, useState } from 'react';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './EventObjectForm.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import FormField from '../../../common/form/FormField';

const EventObjectForm = ({ eventObject }) => {
    const { theme } = useTheme();

    // State Variables
    const [object, setObject] = useState(null);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        setObject(eventObject.object);
        setFields(eventObject.fields);
    }, [eventObject]);

    const onUpdate = (field, value) => {
    
        // There will be no updates on embedded objects

    };

    return (

        <div className={(isMobile || isTablet) ? "event-object-form-container-mobile" : "event-object-form-container"}>

            {/* FIELDS */}
            {fields.map((field) => (
                <div style={{
                    color: theme.foregroundColor
                }}>
                    {/* FIELD */}
                    <FormField
                        object={object}
                        key={field.key}
                        field={field}
                        onUpdate={onUpdate}
                        showFieldMenu={false}
                        readOnly={true}
                    />
                </div>
            ))}

        </div>
    );
};

export default EventObjectForm;
