import React from 'react';

// Styles
import './ChatPostFormEvents.css';

// Components
import ChatPostFormEvent from './ChatPostFormEvent';

const ChatPostFormEvents = ({ events }) => {

  return (
    <>
      {events && events.length > 0 &&
        <div className="chat-post-form-events-container">
          {events.map((evt, index) => (
            <ChatPostFormEvent
              key={index}
              event={evt} 
            />
          ))}
        </div>
      }
    </>
  );
};

export default ChatPostFormEvents;
