import React, { useContext, useEffect, useRef, useState } from 'react';

// Managers
import EventManager from '../../common/managers/EventManager';
import ObjectManager from '../../common/managers/ObjectManager';

// Styles
import './CalendarWeek.css';

// Global context and styles
import { Global } from '../../Global';

const CalendarWeek = () => {
    const {
        activeDate,
        events,
        monthPickerVisible,
        selectedApp,
        setBackButtonAction, // Navigation
        setBackVisible, // Navigation
        setCalendarTitle,
        setCalendarVisible,
        setSelectedObject
    } = useContext(Global);

    const [daysToShow, setDaysToShow] = useState([]);
    const [weekDayEvents, setWeekDayEvents] = useState([]);

    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setCalendarVisible(true);
                setSelectedObject(null);
            };
          });

        setBackVisible(true);

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [setBackButtonAction, setBackVisible]);

    useEffect(() => {
        if (activeDate) {
            // Create a new Date object based on activeDate
            const baseDate = new Date(activeDate);
    
            // Calculate the previous Sunday
            // `getDay()` returns the day of the week (0 for Sunday, 1 for Monday, etc.)
            baseDate.setDate(baseDate.getDate() - baseDate.getDay());
    
            // Create an array of the week's days starting from the calculated Sunday
            const newDaysToShow = Array.from({ length: 7 }, (_, index) => {
                return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate() + index);
            });
    
            // Update the state with the new week days array
            setDaysToShow(newDaysToShow);
        }
    }, [activeDate]);

    // Scroll to the current hour
    const hourBlocksRef = useRef(null);
    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * 70; // Adjust if needed
        hourBlocksRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    // Initialize title
    useEffect(() => {
        // Only run this if daysToShow has week days as expected
        if (daysToShow.length === 7) {
            setCalendarTitle(`${formatDateLong(daysToShow[0])} - ${formatDateLong(daysToShow[6])}`);
        }
    }, [daysToShow, setCalendarTitle]);

    // Gather events
    useEffect(() => {
        if (daysToShow.length === 7) {
            const eventManager = new EventManager();
            setWeekDayEvents(eventManager.getEventsBetweenDates(events, daysToShow[0], daysToShow[6]));
        }
    }, [selectedApp, activeDate, events, daysToShow]);

    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);

    const handleHourBlockClick = (date, hour) => {
        const selectedDate = new Date(date.setHours(hour));
        alert(`Selected time: ${selectedDate.toLocaleString()}`);
    };

    // Function to format the date
    const formatDateLong = (date) => {
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    };

    // Calculate the dynamic height based on the state
    const wrapperHeight = `calc(100vh - ${monthPickerVisible ? '365px' : '150px'})`;

    // Use inline style to adjust height dynamically
    const wrapperStyle = {
        height: wrapperHeight,
        width: '100%',
    };

    const handleEventClick = (modelKey, objectKey) => {
        const objectManager = new ObjectManager();
        objectManager.fetch(selectedApp, modelKey, objectKey).then(fetchedObject => {
            setCalendarVisible(false);
            setSelectedObject(fetchedObject);
        }).catch(error => {
            console.error("Failed to fetch object:", error);
            setSelectedObject(null);
        });
    }

    // Calculate left position for each event
    const calculateLeftPosition = (dayIndex) => {
        const totalWidth = window.innerWidth - 60; // Total width minus the 60px for the hours
        const columnWidth = totalWidth / 7; // There are week days
        return 60 + (columnWidth * dayIndex); // 60px offset + the width of each day times the index
    };

    // Calculate the dynamic height based on the state
    const scrollerHeight = `calc(100vh - ${monthPickerVisible ? '354px' : '140px'})`;

    // Use inline style to adjust height dynamically
    const scrollerStyle = {
        height: scrollerHeight,
        position: 'relative',
        overflowY: 'auto',
        zIndex: '2',
        width: '100%',
    };

    return (
        <div style={wrapperStyle} ref={hourBlocksRef}>
            <div className="calendar-week-days">
                {daysToShow.map((day, index) => (
                    <div key={index} className="calendar-week-day">
                        {daysOfWeek[index]} {/* Using the formatDateShort function to display the date */}
                    </div>
                ))}
            </div>
            <div style={scrollerStyle}>
                {hours.map((hour, hourIndex) => (
                    <div className="calendar-week-hour-block" key={hourIndex}>
                        <div className="calendar-week-hour-label">{hour}</div>
                        {daysToShow.map((date, dayIndex) => (
                            <div key={dayIndex} className="calendar-week-hour-content" onClick={() => handleHourBlockClick(date, hourIndex)}>
                            </div>
                        ))}
                    </div>
                ))}

                {/* Events overlay, positioned to overlap the hour blocks but still part of the scrollable content */}
                <div className="calendar-week-events-overlay">

                    {weekDayEvents && weekDayEvents.map((event, index) => {
                        const startTime = event.startDate.toDate();
                        const endTime = event.endDate.toDate();
                        const modelKey = event.modelKey;
                        const objectKey = event.objectKey;

                        // Determine which day the event is on
                        const eventDate = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
                        const dayIndex = daysToShow.findIndex(d =>
                            d.getFullYear() === eventDate.getFullYear() &&
                            d.getMonth() === eventDate.getMonth() &&
                            d.getDate() === eventDate.getDate());

                        const top = (startTime.getHours() + startTime.getMinutes() / 60) * 70; // Assuming 70px per hour block
                        const height = ((endTime - startTime) / (1000 * 60 * 60)) * 70; // Duration in hours * height per hour
                        const left = calculateLeftPosition(dayIndex);

                        return (
                            <div
                                key={index}
                                className="calendar-week-event"
                                onClick={() => handleEventClick(modelKey, objectKey)}
                                style={{
                                    top: `${top}px`,
                                    height: `${height}px`,
                                    left: `${left}px`, // Set left as a pixel value
                                    position: 'absolute', // Ensure positioning is absolute
                                    width: `calc((100% - 80px) / 7)` // Dynamic width calculation for each day
                                }}
                            >
                                <div className="calendar-week-event-object">
                                    {event.objectTitle}
                                </div>
                                <div className="calendar-week-event-field">
                                    {event.fieldTitle}
                                </div>
                            </div>
                        );
                    })}


                </div>
            </div>
        </div>
    );
};

export default CalendarWeek;
