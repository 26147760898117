import React from 'react';

// Styles
import './ChatPostFormButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import SendIcon from '../../../../common/svg/SendIcon';

const ChatPostFormButton = ({ onClick }) => {
  const { theme } = useTheme();

  return (
    <div className="chat-post-form-button-container"
      style={{
        backgroundColor: theme.backgroundColorFaded,
        color: theme.foregroundColor
      }}
      onClick={onClick}>
      <SendIcon
          color={theme.foregroundColor}
          width="20"
          height="20"
      />
    </div>
  );
};

export default ChatPostFormButton;
