import React, { useState, useRef, useEffect } from 'react';

// Style
import './Slider.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const Slider = ({ value, onChange, onBlur }) => {
    const { theme } = useTheme();

    const [isDragging, setIsDragging] = useState(false);
    const [opacity, setOpacity] = useState(value || 1.0);
    const sliderRef = useRef(null);

    useEffect(() => {
        if (value > 1) setOpacity(1);
    }, [opacity, value]);

    useEffect(() => {

        const handleMouseMove = (event) => {
            if (!isDragging) return;
            const rect = sliderRef.current.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            const newOpacity = Math.min(1, Math.max(0, offsetX / rect.width));
            onChange(newOpacity);
            setOpacity(newOpacity);
        };
    
        const handleMouseUp = (event) => {
            setIsDragging(false);
            const rect = sliderRef.current.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            const newOpacity = Math.min(1, Math.max(0, offsetX / rect.width));
            setOpacity(newOpacity);
            if (onBlur) {
                onChange(newOpacity);
                onBlur(newOpacity);
            }
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, onBlur, onChange]);

    const handleThumbMouseDown = () => {
        setIsDragging(true);
    };

    return (

        <div className="slider-container" style={{ color: theme.foregroundColor }}>
            <div
                className="slider-track"
                ref={sliderRef}
                style={{
                    background: theme.highlightBackgroundColor,
                }}>
                <div
                    className="slider-thumb"
                    style={{
                        left: `${opacity * 100}%`,
                        backgroundColor: theme.foregroundColor,
                    }}
                    onMouseDown={handleThumbMouseDown}
                />
            </div>
        </div>

    );
};

export default Slider;
