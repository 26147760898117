import React from 'react';

const MessageIcon = ({ color = "white", width = "24", height = "24" }) => (
  <svg 
  version="1.1"
  width={width}
  height={height}
  viewBox="0 0 24 24">
    <path d='M3 .565h14a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-6.958l-6.444 4.808A1 1 0 0 1 2 18.57v-4.006a2 2 0 0 1-2-2v-9a3 3 0 0 1 3-3z'
      fill={color} />
  </svg>
);

export default MessageIcon;
