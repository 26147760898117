import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

// Styles
import './ChecklistItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DragIcon from '../../../../common/svg/DragIcon';
import CheckOffIcon from '../../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../../common/svg/CheckOnIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';

const ChecklistItem = ({
    item,
    index,
    moveItem,
    onChange,
    onRemove
}) => {
    const { theme } = useTheme();

    const ref = useRef(null);

    // Drag and Drop Setup
    const [, drop] = useDrop({
        accept: 'CHECKLIST_ITEM',
        hover: (draggedItem) => {
            if (!ref.current) return;
            const dragIndex = draggedItem.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;

            moveItem(dragIndex, hoverIndex);
            draggedItem.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'CHECKLIST_ITEM',
        item: { type: 'CHECKLIST_ITEM', index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className="checklist-item-container"
            style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
            <div className="checklist-item-drag-handle">
                <DragIcon
                    color={theme.foregroundColorFaded}
                    width="21"
                    height="21"
                />
            </div>
            <div className="checklist-item-checkbox" onClick={() => onChange(item.key)}>
                {item.checked ? (
                    <CheckOnIcon
                        color={theme.highlightBackgroundColor}
                        checkColor={theme.highlightForegroundColor}
                        width="21"
                        height="21"
                    />
                ) : (
                    <CheckOffIcon
                        color={theme.foregroundColor}
                        width="21"
                        height="21"
                    />
                )}
            </div>

            <div className="checklist-item-middle">

                {/* TITLE */}
                <div
                    className="checklist-item-title"
                    onClick={() => onChange(item.key)}
                    style={{ color: theme.foregroundColor }}>
                    {item.title}
                </div>

                {/* INSTRUCTIONS */}
                <div
                    className="checklist-item-instructions"
                    style={{ color: theme.foregroundColorFaded }}>
                    {item.instructions}
                </div>
            </div>
            <div
                className="checklist-item-remove-container"
                onClick={() => onRemove(item.key)}>
                <RemoveIcon
                    color={theme.foregroundColorFaded}
                    width="20"
                    height="20"
                />
            </div>
        </div>
    );
};

export default ChecklistItem;
