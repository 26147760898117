import React, { useEffect, useState } from 'react';

// Styles
import './CountriesCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * CountriesCell Component
 * 
 * This component renders an drop-down list for selecting a country.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CountriesCell = ({
  object,
  field,
  handleBlur
}) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');

  const backgroundColor = "transparent";
  const foregroundColor = theme.foregroundColor;

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function sets the value to the input value.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    handleBlur(object.key, field.key, inputValue);
  };

  // Hardcoded list of all US states as name/value pairs
  const countries = [
    { name: "AAFG", value: "Afghanistan" },
    { name: "ALA", value: "Aland Islands" },
    { name: "ALB", value: "Albania" },
    { name: "DZA", value: "Algeria" },
    { name: "ASM", value: "American Samoa" },
    { name: "AND", value: "Andorra" },
    { name: "AGO", value: "Angola" },
    { name: "AIA", value: "Anguilla" },
    { name: "ATA", value: "Antarctica" },
    { name: "ATG", value: "Antigua and Barbuda" },
    { name: "ARG", value: "Argentina" },
    { name: "ARM", value: "Armenia" },
    { name: "ABW", value: "Aruba" },
    { name: "AUS", value: "Australia" },
    { name: "AUT", value: "Austria" },
    { name: "AZE", value: "Azerbaijan" },
    { name: "BHS", value: "Bahamas" },
    { name: "BHR", value: "Bahrain" },
    { name: "BGD", value: "Bangladesh" },
    { name: "BRB", value: "Barbados" },
    { name: "BLR", value: "Belarus" },
    { name: "BEL", value: "Belgium" },
    { name: "BLZ", value: "Belize" },
    { name: "BEN", value: "Benin" },
    { name: "BMU", value: "Bermuda" },
    { name: "BTN", value: "Bhutan" },
    { name: "BOL", value: "Bolivia" },
    { name: "BIH", value: "Bosnia and Herzegovina" },
    { name: "BWA", value: "Botswana" },
    { name: "BVT", value: "Bouvet Island" },
    { name: "BRA", value: "Brazil" },
    { name: "VGB", value: "British Virgin Islands" },
    { name: "IOT", value: "British Indian Ocean Territory" },
    { name: "BRN", value: "Brunei Darussalam" },
    { name: "BGR", value: "Bulgaria" },
    { name: "BFA", value: "Burkina Faso" },
    { name: "BDI", value: "Burundi" },
    { name: "KHM", value: "Cambodia" },
    { name: "CMR", value: "Cameroon" },
    { name: "CAN", value: "Canada" },
    { name: "CPV", value: "Cape Verde" },
    { name: "CYM", value: "Cayman Islands" },
    { name: "CAF", value: "Central African Republic" },
    { name: "TCD", value: "Chad" },
    { name: "CHL", value: "Chile" },
    { name: "CHN", value: "China" },
    { name: "HKG", value: "Hong Kong (SAR China)" },
    { name: "MAC", value: "Macao (SAR China" },
    { name: "CXR", value: "Christmas Island" },
    { name: "CCK", value: "Cocos (Keeling) Islands" },
    { name: "COL", value: "Colombia" },
    { name: "COM", value: "Comoros" },
    { name: "COG", value: "Congo (Brazzaville)" },
    { name: "COD", value: "Congo (Kinshasa)" },
    { name: "COK", value: "Cook Islands" },
    { name: "CRI", value: "Costa Rica" },
    { name: "CIV", value: "Côte d'Ivoire" },
    { name: "HRV", value: "Croatia" },
    { name: "CUB", value: "Cuba" },
    { name: "CYP", value: "Cyprus" },
    { name: "CZE", value: "Czech Republic" },
    { name: "DNK", value: "Denmark" },
    { name: "DJI", value: "Djibouti" },
    { name: "DMA", value: "Dominica" },
    { name: "DOM", value: "Dominican Republic" },
    { name: "ECU", value: "Ecuador" },
    { name: "EGY", value: "Egypt" },
    { name: "SLV", value: "El Salvador" },
    { name: "GNQ", value: "Equatorial Guinea" },
    { name: "ERI", value: "Eritrea" },
    { name: "EST", value: "Estonia" },
    { name: "ETH", value: "Ethiopia" },
    { name: "FLK", value: "Falkland Islands (Malvinas)" },
    { name: "FRO", value: "Faroe Islands" },
    { name: "FJI", value: "Fiji" },
    { name: "FIN", value: "Finland" },
    { name: "FRA", value: "France" },
    { name: "GUF", value: "French Guiana" },
    { name: "PYF", value: "French Polynesia" },
    { name: "ATF", value: "French Southern Territories" },
    { name: "GAB", value: "Gabon" },
    { name: "GMB", value: "Gambia" },
    { name: "GEO", value: "Georgia" },
    { name: "DEU", value: "Germany" },
    { name: "GHA", value: "Ghana" },
    { name: "GIB", value: "Gibraltar" },
    { name: "GRC", value: "Greece" },
    { name: "GRL", value: "Greenland" },
    { name: "GRD", value: "Grenada" },
    { name: "GLP", value: "Guadeloupe" },
    { name: "GUM", value: "Guam" },
    { name: "GTM", value: "Guatemala" },
    { name: "GGY", value: "Guernsey" },
    { name: "GIN", value: "Guinea" },
    { name: "GNB", value: "Guinea-Bissau" },
    { name: "GUY", value: "Guyana" },
    { name: "HTI", value: "Haiti" },
    { name: "HMD", value: "Heard and Mcdonald Islands" },
    { name: "VAT", value: "Holy See (Vatican City State)" },
    { name: "HND", value: "Honduras" },
    { name: "HUN", value: "Hungary" },
    { name: "ISL", value: "Iceland" },
    { name: "IND", value: "India" },
    { name: "IDN", value: "Indonesia" },
    { name: "IRN", value: "Iran" },
    { name: "IRQ", value: "Iraq" },
    { name: "IRL", value: "Ireland" },
    { name: "IMN", value: "Isle of Man" },
    { name: "ISR", value: "Israel" },
    { name: "ITA", value: "Italy" },
    { name: "JAM", value: "Jamaica" },
    { name: "JPN", value: "Japan" },
    { name: "JEY", value: "Jersey" },
    { name: "JOR", value: "Jordan" },
    { name: "KAZ", value: "Kazakhstan" },
    { name: "KEN", value: "Kenya" },
    { name: "KIR", value: "Kiribati" },
    { name: "PRK", value: "Korea (North)" },
    { name: "KOR", value: "Korea (South)" },
    { name: "KWT", value: "Kuwait" },
    { name: "KGZ", value: "Kyrgyzstan" },
    { name: "LAO", value: "Lao PDR" },
    { name: "LVA", value: "Latvia" },
    { name: "LBN", value: "Lebanon" },
    { name: "LSO", value: "Lesotho" },
    { name: "LBR", value: "Liberia" },
    { name: "LBY", value: "Libya" },
    { name: "LIE", value: "Liechtenstein" },
    { name: "LTU", value: "Lithuania" },
    { name: "LUX", value: "Luxembourg" },
    { name: "MKD", value: "Macedonia" },
    { name: "MDG", value: "Madagascar" },
    { name: "MWI", value: "Malawi" },
    { name: "MYS", value: "Malaysia" },
    { name: "MDV", value: "Maldives" },
    { name: "MLI", value: "Mali" },
    { name: "MLT", value: "Malta" },
    { name: "MHL", value: "Marshall Islands" },
    { name: "MTQ", value: "Martinique" },
    { name: "MRT", value: "Mauritania" },
    { name: "MUS", value: "Mauritius" },
    { name: "MYT", value: "Mayotte" },
    { name: "MEX", value: "Mexico" },
    { name: "FSM", value: "Micronesia" },
    { name: "MDA", value: "Moldova" },
    { name: "MCO", value: "Monaco" },
    { name: "MNG", value: "Mongolia" },
    { name: "MNE", value: "Montenegro" },
    { name: "MSR", value: "Montserrat" },
    { name: "MAR", value: "Morocco" },
    { name: "MOZ", value: "Mozambique" },
    { name: "MMR", value: "Myanmar" },
    { name: "NAM", value: "Namibia" },
    { name: "NRU", value: "Nauru" },
    { name: "NPL", value: "Nepal" },
    { name: "NLD", value: "Netherlands" },
    { name: "ANT", value: "Netherlands Antilles" },
    { name: "NCL", value: "New Caledonia" },
    { name: "NZL", value: "New Zealand" },
    { name: "NIC", value: "Nicaragua" },
    { name: "NER", value: "Niger" },
    { name: "NGA", value: "Nigeria" },
    { name: "NIU", value: "Niue" },
    { name: "NFK", value: "Norfolk Island" },
    { name: "MNP", value: "Northern Mariana Islands" },
    { name: "NOR", value: "Norway" },
    { name: "OMN", value: "Oman" },
    { name: "PAK", value: "Pakistan" },
    { name: "PLW", value: "Palau" },
    { name: "PSE", value: "Palestinian Territory" },
    { name: "PAN", value: "Panama" },
    { name: "PNG", value: "Papua New Guinea" },
    { name: "PRY", value: "Paraguay" },
    { name: "PER", value: "Peru" },
    { name: "PHL", value: "Philippines" },
    { name: "PCN", value: "Pitcairn" },
    { name: "POL", value: "Poland" },
    { name: "PRT", value: "Portugal" },
    { name: "PRI", value: "Puerto Rico" },
    { name: "QAT", value: "Qatar" },
    { name: "REU", value: "Réunion" },
    { name: "ROU", value: "Romania" },
    { name: "RUS", value: "Russian Federation" },
    { name: "RWA", value: "Rwanda" },
    { name: "BLM", value: "Saint-Barthélemy" },
    { name: "SHN", value: "Saint Helena" },
    { name: "KNA", value: "Saint Kitts and Nevis" },
    { name: "LCA", value: "Saint Lucia" },
    { name: "MAF", value: "Saint-Martin (French part)" },
    { name: "SPM", value: "Saint Pierre and Miquelon" },
    { name: "VCT", value: "Saint Vincent and Grenadines" },
    { name: "WSM", value: "Samoa" },
    { name: "SMR", value: "San Marino" },
    { name: "STP", value: "Sao Tome and Principe" },
    { name: "SAU", value: "Saudi Arabia" },
    { name: "SEN", value: "Senegal" },
    { name: "SRB", value: "Serbia" },
    { name: "SYC", value: "Seychelles" },
    { name: "SLE", value: "Sierra Leone" },
    { name: "SGP", value: "Singapore" },
    { name: "SVK", value: "Slovakia" },
    { name: "SVN", value: "Slovenia" },
    { name: "SLB", value: "Solomon Islands" },
    { name: "SOM", value: "Somalia" },
    { name: "ZAF", value: "South Africa" },
    { name: "SGS", value: "South Georgia and the South Sandwich Islands" },
    { name: "SSD", value: "South Sudan" },
    { name: "ESP", value: "Spain" },
    { name: "LKA", value: "Sri Lanka" },
    { name: "SDN", value: "Sudan" },
    { name: "SUR", value: "Suriname" },
    { name: "SJM", value: "Svalbard and Jan Mayen Islands" },
    { name: "SWZ", value: "Swaziland" },
    { name: "SWE", value: "Sweden" },
    { name: "CHE", value: "Switzerland" },
    { name: "SYR", value: "Syrian Arab Republic (Syria)" },
    { name: "TWN", value: "Taiwan" },
    { name: "TJK", value: "Tajikistan" },
    { name: "TZA", value: "Tanzania" },
    { name: "THA", value: "Thailand" },
    { name: "TLS", value: "Timor-Leste" },
    { name: "TGO", value: "Togo" },
    { name: "TKL", value: "Tokelau" },
    { name: "TON", value: "Tonga" },
    { name: "TTO", value: "Trinidad and Tobago" },
    { name: "TUN", value: "Tunisia" },
    { name: "TUR", value: "Turkey" },
    { name: "TKM", value: "Turkmenistan" },
    { name: "TCA", value: "Turks and Caicos Islands" },
    { name: "TUV", value: "Tuvalu" },
    { name: "UGA", value: "Uganda" },
    { name: "UKR", value: "Ukraine" },
    { name: "ARE", value: "United Arab Emirates" },
    { name: "GBR", value: "United Kingdom" },
    { name: "USA", value: "United States of America" },
    { name: "UMI", value: "US Minor Outlying Islands" },
    { name: "URY", value: "Uruguay" },
    { name: "UZB", value: "Uzbekistan" },
    { name: "VUT", value: "Vanuatu" },
    { name: "VEN", value: "Venezuela (Bolivarian Republic)" },
    { name: "VNM", value: "Viet Nam" },
    { name: "VIR", value: "Virgin Islands" },
    { name: "WLF", value: "Wallis and Futuna Islands" },
    { name: "ESH", value: "Western Sahara" },
    { name: "YEM", value: "Yemen" },
    { name: "ZMB", value: "Zambia" },
    { name: "ZWE", value: "Zimbabwe" }
  ];

  return (
    <div className="countries-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}>
      <select
        className="countries-cell-input"
        style={{ color: theme.foregroundColor }}
        value={value}
        onChange={handleChange}>
        <option value="" {...(value !== '' ? { disabled: true } : {})}></option>
        {countries.map(country => (
          <option key={country.name} value={country.name}>{country.value}</option>
        ))}
      </select>
    </div>
  );
};

export default CountriesCell;
