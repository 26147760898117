import React from 'react';

// Styles
import './AddMenuItemButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import NewIcon from '../../common/svg/NewIcon';

const AddMenuItemButton = ({ onClick, title }) => {
    const { theme } = useTheme();

    return (
        <div
            className="add-menu-item-button-container"
            onClick={onClick}>
            <div className="add-menu-item-button-image">
                <NewIcon
                    color={theme.foregroundColor}
                    width="18"
                    height="14"
                />
            </div>
            <div className="add-menu-item-button-text"
                style={{color: theme.foregroundColor}}>
                {title}
            </div>
        </div>
    );
};

export default AddMenuItemButton;
