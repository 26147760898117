import React from 'react';

const ExpandMoreIcon = ({ color = "white", width = "12", height = "7" }) => (
    <svg 
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 12 7"
    >
         <g id="Icons" stroke="none" strokeWidth="1" fill="none">
            <g id="Rounded" transform="translate(-214.000000, -3485.000000)">
                <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                    <g id="-Round-/-Navigation-/-expand_more" transform="translate(108.000000, 98.000000)">
                        <g>
                            <polygon id="Path" opacity="0.87" points="24 24 0 24 0 0 24 0"></polygon>
                            <path d="M15.88,9.29 L12,13.17 L8.12,9.29 C7.73,8.9 7.1,8.9 6.71,9.29 C6.32,9.68 6.32,10.31 6.71,10.7 L11.3,15.29 C11.69,15.68 12.32,15.68 12.71,15.29 L17.3,10.7 C17.69,10.31 17.69,9.68 17.3,9.29 C16.91,8.91 16.27,8.9 15.88,9.29 Z" id="🔹-Icon-Color" 
                                fill={color}></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default ExpandMoreIcon;
