import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './LogoLarge.css';

// Images
import est from '../images/est.png';

// Components
import AppLogo from '../applogo/AppLogo';

const LogoLarge = () => {
  const {
    selectedApp,
    studio
  } = useContext(Global);

  return (
    <div className="logo-large-container">

      {selectedApp ? (
        <div className="logo-large-app-logo">
          <AppLogo
            size={180}
          />
        </div>
      ) : (
        <>
          {/* DEFAULT APPDECK LOGO */}
          <div className="logo-large-company"
            style={{ color: studio && studio.foregroundColor }}>
            APPDECK
          </div>
          <div className="logo-large-tagline"
            style={{ color: studio && studio.highlightBackgroundColor }}>
            CREATE THE APP YOU NEED
          </div>
          <div className="logo-large-est-wrapper">
            <img src={est} alt="Logo" className="logo-large-est" />
          </div>
        </>
      )}
    </div>
  );
};

export default LogoLarge;

