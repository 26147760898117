import React, { useEffect, useState } from 'react';

// React Window
import { FixedSizeList as List } from 'react-window';

// Styles
import './AppSettingsFont.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Key
const API_KEY = 'AIzaSyAsfRYzVuoxJeL0AaJdDU65eppoT0MRic4';

/**
 * AppSettingsFont Component
 * 
 * This component allows the app creator to choose a font for the app.
 * 
 * @param {string} fontFamily - The currently selected font.
 * @param {function} onChange - The function to call when the user selects a font.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettingsFont = ({ fontFamily, onChange }) => {
    // Theme
    const { theme } = useTheme();

    // State Variables
    const [fonts, setFonts] = useState([]);
    const [loadedFonts, setLoadedFonts] = useState(new Set());  // Keeps track of loaded fonts

    /**
     * Function to load the Google Fonts list.
     */
    const fetchFonts = async () => {
        const response = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`);
        const data = await response.json();
        const fontNames = data.items.map(font => font.family);  // Extract only font names
        setFonts(fontNames);  // Set font names in the state
    };

    /**
     * Fetch fonts on component mount.
     */
    useEffect(() => {
        fetchFonts();
    }, []);

    /**
     * Function to load only the regular variant of a font.
     * 
     * @param {string} fontName - Name of the font.
     */
    const loadGoogleFont = (fontName) => {
        if (!loadedFonts.has(fontName)) {
            const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
            setLoadedFonts(prev => new Set(prev).add(fontName));  // Track loaded fonts
        }
    };

    /**
     * Detects when rows come into view and load the fonts.
     * 
     * @param {string} visibleStartIndex - Visible start index.
     * @param {string} visibleStopIndex - Visible stop index.
     */
    const handleItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
        for (let i = visibleStartIndex; i <= visibleStopIndex; i++) {
            loadGoogleFont(fonts[i]);
        }
    };

    /**
     * Handles font selection.
     * 
     * @param {string} fontValue - Selected font name.
     */
    const handleFontSelect = (fontValue) => {
        onChange(fontValue);
    };

    /**
     * Row renderer for react-window.
     */
    const renderRow = ({ index, style }) => (
        <div
            key={fonts[index]}
            className="app-settings-font-item"
            style={{
                cursor: 'pointer',
                backgroundColor: fontFamily === fonts[index] ? theme.highlightBackgroundColor : theme.backgroundColor,
                ...style
            }}
            onClick={() => handleFontSelect(fonts[index])}
        >
            <div className="app-settings-font-label"
                style={{
                    color: fontFamily === fonts[index] ? theme.highlightForegroundColor : theme.foregroundColorFaded,
                }}>
                {fonts[index]}
            </div>
            <div className="app-settings-font-value"
                style={{
                    color: fontFamily === fonts[index] ? theme.highlightForegroundColor : theme.foregroundColor,
                    fontFamily: fonts[index]
                }}>
                The quick brown fox jumps over the lazy dog
            </div>
        </div>
    );

    return (
        <div className="app-settings-font-container">
            <div 
                className="app-settings-font-header-label" 
                style={{
                    color: theme.foregroundColorFaded
                }}>
                Font
            </div>
            {fonts.length > 0 ? (
                <List
                    height={430}
                    itemCount={fonts.length}
                    itemSize={80}
                    width="100%"
                    onItemsRendered={handleItemsRendered}  // Detect when items come into view
                >
                    {renderRow}
                </List>
            ) : (
                <div className="app-settings-font-loading"
                    style={{
                        color: theme.backgroundColorFaded,
                    }}>
                    Loading fonts...
                </div>
            )}
        </div>
    );
};

export default AppSettingsFont;
