import React from 'react';

const DotsVerticalIcon = ({ color = "white", width = "4", height = "16" }) => (
  
  <svg version="1.1"
    width={width}
    height={height}
    viewBox="0 0 4 16">
    <path stroke={color} strokeLinecap="round" strokeWidth="3" d="M1.5 2h.01M1.5 8h.01m-.01 6h.01" />
  </svg>

);

export default DotsVerticalIcon;
