import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../../firebaseConfig';

// Activity
import { activity } from '../../../../common/managers/ActivityManager';

// Utilities
import { generateKey } from '../../../../common/utilities/Keys';

// Styles
import './LookupEditor.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PlusIcon from '../../../../common/svg/PlusIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';

const LookupEditor = ({ field, isOpen, setOpen }) => {
  const { theme } = useTheme();
  const {
    formFields,
    selectedApp,
    setFormFields,
  } = useContext(Global);

  const [isAnimating, setIsAnimating] = useState(false);

  const [options, setOptions] = useState(field.options || []);
  const [newOptionTitle, setNewOptionTitle] = useState('');

  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // Trigger the animation
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [isOpen]);

  const handleRemoveOption = (keyToRemove) => {
    const updatedOptions = options.filter(option => option.key !== keyToRemove);
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    if (!newOptionTitle.trim()) return;

    const newOption = { key: generateKey(), title: newOptionTitle };
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    setNewOptionTitle('');
  };

  const handleOptionsSave = async (newOptions) => {
    const data = {
      options: newOptions
    };

    try {
      await updateDoc(doc(db, collections.fields, field.key), data);

      // Update the title in the formFields state
      const updatedFormFields = formFields.map(item =>
        item.key === field.key ? { ...item, options: newOptions } : item
      );
      setFormFields(updatedFormFields);

      activity.log(selectedApp.key, 'writes', 1);

      setOpen(false);
    } catch (error) {
      console.error('Error renaming field:', error);
    }
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <div
      className="lookup-editor-overlay"
      onMouseDown={() => setOpen(false)}
      style={{ opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms' }}>
      <div
        className="lookup-editor-content"
        style={{
          backgroundColor: theme.backgroundColor,
          opacity: isAnimating ? 1 : 0,
          transition: 'opacity 300ms, transform 300ms',
        }}
        ref={modalRef}
        onMouseDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}>
        <div className="lookup-editor-body">

          {/* LABEL */}
          <div className="lookup-editor-label"
            style={{ color: theme.foregroundColorFaded }}>
            {field.title} Options:
          </div>

          {/* OPTIONS */}
          <div className="lookup-editor-options-wrapper">
            {options.map(option => (
              <div key={option.key} className="lookup-editor-option-item">

                {/* OPTION LABEL */}
                <div className="lookup-editor-option-text"
                  style={{ color: theme.foregroundColor }}>
                  {option.title}
                </div>

                {/* REMOVE BUTTON */}
                <div className="lookup-editor-remove-container"
                  onClick={() => handleRemoveOption(option.key)}>
                  <RemoveIcon
                    color={theme.foregroundColorFaded}
                    width="14"
                    height="14"
                  />
                </div>
              </div>
            ))}

            {/* ADD SECTION */}
            <div className="lookup-editor-add-container">

              {/* ADD INPUT */}
              <div className="lookup-editor-add-text-container">
                <input
                  type="text"
                  placeholder="Add option"
                  value={newOptionTitle}
                  onChange={(e) => setNewOptionTitle(e.target.value)}
                  className="lookup-editor-add-input"
                  style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded
                  }}
                />
              </div>

              {/* ADD BUTTON */}
              <div
                className="lookup-editor-add-button-container"
                onClick={handleAddOption}>
                <PlusIcon
                  color={theme.foregroundColorFaded}
                  width="14"
                  height="14"
                />
              </div>
            </div>
          </div>

          {/* SAVE BUTTON */}
          <div
            className="lookup-editor-save-button"
            style={{ color: theme.foregroundColor }}
            onClick={() => handleOptionsSave(options)}>
            Save Changes
          </div>
        </div>
      </div>
    </div>
  );
};

export default LookupEditor;
