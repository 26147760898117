import React, { useEffect, useState } from 'react';

// Utilities
import { convertDecimalToPercentage } from '../../../common/utilities/Numbers';

// Styles
import './AppDot.css';

// Themes
import { useTheme } from '../../../ThemeContext';

/**
 * AppDot Component
 * 
 * This component renders an icon or letter representing an app.
 * 
 * @param {object} app - The app to represent.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppDot = ({ app }) => {
    const { currentTheme, theme } = useTheme();

    // State Variables
    const [font, setFont] = useState('');

    // Sets the font family for the app letter.
    useEffect(() => {
        if (app.fontFamily) {
            setFont(app.fontFamily);
        }
    }, [app, setFont]);

    // Size of the circle
    const size = 40;

    return (
        <>
            {/* CIRCLE */}
            <div className="app-dot-container"
                style={{
                    backgroundColor: currentTheme === 'DARK' ? app.themes.dark.backgroundColorFaded : app.themes.light.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded
                }}>

                {/* ICON */}
                {app.logoDark || app.logoLight ? (
                    <>
                        <img
                            className="app-dot-image"
                            src={currentTheme === 'DARK' ? app.logoDark : app.logoLight}
                            style={{
                                width: app.iconSize ? convertDecimalToPercentage(app.iconSize) : "100%"
                            }}
                            alt="App Logo"
                        />
                    </>
                ) : (
                    <>
                        {/* LETTER */}
                        <div className="app-dot-letter"
                            style={{
                                color: currentTheme === "DARK" ?
                                    app.themes.dark.highlightBackgroundColor :
                                    app.themes.light.highlightBackgroundColor,
                                '--dot-size': size + 'px',
                                '--dot-font-size': size * 0.5 + 'px',  // 50% of size
                                height: size + 'px',
                                width: size + 'px',
                                fontFamily: font || 'sans-serif'
                            }}>
                            {app.title.charAt(0)}
                        </div>
                    </>
                )}

            </div>
        </>
    );
};

export default AppDot;
