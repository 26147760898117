import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../../common/utilities/Constants';

// Styles
import './TitleBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import CalendarIcon from '../../common/svg/CalendarIcon';
import HomeIcon from '../../common/svg/HomeIcon';
import ListIcon from '../../common/svg/ListIcon';
import MessagesIcon from '../../common/svg/MessagesIcon';
import SearchIcon from '../../common/svg/SearchIcon';
import SettingsIcon from '../../common/svg/SettingsIcon';
import UsersIcon from '../../common/svg/UsersIcon';

// Components
import AppSettings from '../appsettings/AppSettings';
import AppSettingsButton from '../appsettingsbutton/AppSettingsButton';
import AppTitle from '../apptitle/AppTitle';
import AppTitleSlash from '../apptitle/AppTitleSlash';
import LogoSmall from '../logosmall/LogoSmall';
import ThemeToggle from '../../desktop/components/themetoggle/ThemeToggle';
import UserButton from '../components/userbutton/UserButton';

/**
 * TitleBar Component
 * 
 * This component renders the title bar at the top of the screen.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TitleBar = () => {
    const { theme } = useTheme();
    const {
        changePage,
        currentUser,
        resetVisibility,
        selectedApp,
        setCurrentDevice,
        setCurrentCanvas,
        userRole,
        isMobile,
        setAppUsersVisible,
        setCalendarVisible,
        setFormMode,
        setMessagesVisible,
        setRunHomeVisible
    } = useContext(Global);

    // State Variables
    const [isAppSettingsOpen, setAppSettingsOpen] = useState(false);

    /**
     * Method to handle the APPDECK logo click event.
     */
    const handleLogoClick = () => {

        resetVisibility();

        setCurrentDevice("MOBILE");
        setCurrentCanvas("FORM");

        if (currentUser) {
            changePage('HOME');
        } else {
            changePage('WELCOME');
        }
    }

    const handleItemClick = (visibilitySetter) => {
        resetVisibility();
        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }
        visibilitySetter(true);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="title-bar-container">

                {/* LEFT */}
                <div className="title-bar-left">

                    {/* APPDECK LOGO */}
                    <div className="title-bar-logo"
                        onClick={handleLogoClick}>
                        <LogoSmall />
                    </div>

                    {!isMobile && selectedApp && currentUser &&
                        <>
                            {/* APP INFO */}
                            <div className="title-bar-app">
                                {/** SLASH */}
                                <AppTitleSlash />

                                {/* APP TITLE */}
                                <AppTitle />
                            </div>

                            {/* BUTTONS */}
                            <div className="title-bar-buttons">
                                {selectedApp && currentUser &&
                                    <>
                                        <div className="title-bar-buttons">

                                            {/* SETTINGS BUTTON */}
                                            {userRole === 'ADMIN' &&
                                                <AppSettingsButton
                                                    onClick={() => setAppSettingsOpen(true)}
                                                />
                                            }

                                            {/* HOME */}
                                            {!isMobile &&
                                                <div className="title-bar-button"
                                                    onClick={() => handleItemClick(setRunHomeVisible)}>
                                                    <HomeIcon
                                                        color={theme.foregroundColorFaded}
                                                        width="16"
                                                        height="16"
                                                    />
                                                </div>
                                            }

                                            {/* CALENDAR */}
                                            {!isMobile &&
                                                <div className="title-bar-button"
                                                    onClick={() => handleItemClick(setCalendarVisible)}>
                                                    <CalendarIcon
                                                        color={theme.foregroundColorFaded}
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                            }

                                            {/* MESSAGES */}
                                            {!isMobile &&
                                                <div className="title-bar-button"
                                                    onClick={() => handleItemClick(setMessagesVisible)}>
                                                    <MessagesIcon
                                                        color={theme.foregroundColorFaded}
                                                        width="18"
                                                        height="18"
                                                    />
                                                </div>
                                            }

                                            {/* USERS */}
                                            {!isMobile &&
                                                <div className="title-bar-button"
                                                    onClick={() => handleItemClick(setAppUsersVisible)}>
                                                    <UsersIcon
                                                        color={theme.foregroundColorFaded}
                                                        width="17"
                                                        height="17"
                                                    />
                                                </div>
                                            }

                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>

                {/* RIGHT */}
                <div className="title-bar-right">

                    {/* THEME */}
                    {selectedApp &&
                        <ThemeToggle />
                    }

                    {/* USER BUTTON */}
                    {currentUser &&
                        <div className="title-bar-current-user" >
                            <UserButton />
                        </div>
                    }
                </div>

                {/* SETTINGS DIALOG */}
                {selectedApp &&
                    <AppSettings
                        appKey={selectedApp.key}
                        isAppSettingsOpen={isAppSettingsOpen}
                        setAppSettingsOpen={setAppSettingsOpen}
                    />
                }
            </div>
        </>
    );
};

export default TitleBar;
