import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './CalendarButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import DotsIcon from '../../common/svg/DotsIcon';

const CalendarButton = () => {
  const { theme } = useTheme();
  const {
    setSelectedCalendar
  } = useContext(Global);

  const [menuVisible, setMenuVisible] = useState(false);

  const calendarMenuRef = useRef(null);

  // Handle clicking outside the menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarMenuRef.current && !calendarMenuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle visibility function
  const toggleCalendarMenu = () => {
    setMenuVisible(!menuVisible);
  }

  const handleCalendarSelection = (key) => {
    setSelectedCalendar(key);
    setMenuVisible(false);
  };

  return (
    <div>
      {/* Button to toggle the calendar menu */}
      <div className="calendar-buttons">
        <div className="calendar-button" onClick={toggleCalendarMenu}>
          <DotsIcon
            color={theme.foregroundColor}
            width="22"
            height="22"
          />
        </div>
      </div>
      {/* FIELD MENU */}
      {menuVisible && (
        <div
          className="calendar-menu-background"
          style={{
            backgroundColor: theme.backgroundColorFaded,
            borderColor: theme.backgroundColorFaded
          }}
          ref={calendarMenuRef}
        >
          <div
            className="calendar-menu-item"
            onClick={() => handleCalendarSelection('SCHEDULE')}
            style={{
              color: theme.foregroundColor
            }}
          >
            Schedule
          </div>
          <div
            className="calendar-menu-item"
            onClick={() => handleCalendarSelection('DAY')}
            style={{
              color: theme.foregroundColor
            }}
          >
            Day
          </div>
          {/*
          <div className="calendar-menu-item" onClick={() => handleCalendarSelection('ALL')}>
            All Time
          </div>
          <div className="calendar-menu-item" onClick={() => handleCalendarSelection('YEAR')}>
            Year
          </div>
          <div className="calendar-menu-item" onClick={() => handleCalendarSelection('MONTH')}>
            Month
          </div>
          <div className="calendar-menu-item" onClick={() => handleCalendarSelection('WEEK')}>
            Week
          </div>
          <div className="calendar-menu-item" onClick={() => handleCalendarSelection('THREE')}>
            3 Days
          </div>
           */}
        </div>
      )}
    </div>
  );
};

export default CalendarButton;
