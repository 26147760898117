import React, { useContext, useState } from 'react';

// Firebase
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';

// Global
import { Global } from '../../Global';

// Styles
import './Login.css';

// Components
import LoginButton from './LoginButton';
import LoginLink from './LoginLink';
import LoginUsername from '../components/loginusername/LoginUsername';
import LoginPassword from '../components/loginpassword/LoginPassword';

// Managers
import UserManager from '../../common/managers/UserManager';
import InviteManager from '../../common/managers/InviteManager';

const userManager = new UserManager();
const inviteManager = new InviteManager();

/**
 * Login Component
 * 
 * This component renders a login form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function Login() {
    const {
        hideProgress,
        invite,
        setCurrentUser,
        setInvite,
        setLoginMode,
        setPage,
        setSelectedApp,
        showProgress,
        studio
    } = useContext(Global);

    // State Variables
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    /**
     * Method to handle submit of the login form.
     * 
     * @param {string} e - Click event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Regex for detecting email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Regex for detecting phone number format (international format without special characters)
        const phoneRegex = /^\d{10,15}$/;  // Adjusting the regex to accept only numeric values

        let userRecord = null;

        try {
            showProgress("Logging in...");

            // Step 1: Check if the input matches an email format
            if (emailRegex.test(username)) {
                // Fetch user by email
                userRecord = await userManager.fetchUserWithEmail(username);
            }
            // Step 2: Check if it's a phone number by stripping non-numeric characters
            else {
                // Remove all non-numeric characters
                const cleanedPhone = username.replace(/\D/g, '');

                // If it matches a valid phone number length (adjust this based on your needs)
                if (phoneRegex.test(cleanedPhone)) {
                    // Fetch user by cleaned phone number
                    userRecord = await userManager.fetchUserWithPhone(cleanedPhone);
                }
                // Step 3: If neither email nor valid phone, treat as username
                else {
                    // Fetch user by username
                    userRecord = await userManager.fetchUserWithUsername(username);
                }
            }

            // Step 4: If user record found, attempt to sign in with the user's email
            if (userRecord && userRecord.email) {
                await signInWithEmailAndPassword(auth, userRecord.email, password);

                // Set current user in global state
                const combinedUser = await userManager.fetchCurrentUser(auth.currentUser);
                setCurrentUser(combinedUser);

                // Handle invite if present
                if (invite) {
                    inviteManager.processInvite(
                        combinedUser.key,
                        combinedUser.firstName,
                        combinedUser.lastName,
                        combinedUser.email,
                        invite,
                        setInvite,
                        setSelectedApp,
                        setPage
                    );
                }

                // Navigate to the home page
                setPage("HOME");
            } else {
                setErrorMessage("User not found.");
            }
        } catch (error) {
            // Handle error (e.g., invalid credentials, network errors)
            setErrorMessage("Failed to log in. Please check your credentials.");
            console.error('Login error:', error);
        } finally {
            hideProgress();
        }
    };

    /**
     * Handles click on the sign up link.
     */
    const handleSignupClick = async () => {
        setLoginMode("SIGNUP");
    };

    /**
     * Method to handle username change.
     * @param {string} value - The current username value.
     */
    const handleUsernameChange = (value) => {
        setUsername(value);
        setSubmitEnabled(value.length > 0 && password.length > 0);
    };

    /**
     * Method to handle password change.
     * @param {string} value - The current password value.
     */
    const handlePasswordChange = (value) => {
        setPassword(value);
        setSubmitEnabled(value.length > 0 && username.length > 0);
    };

    return (
        <div className="login-container">

            {/* USERNAME */}
            <LoginUsername onChange={handleUsernameChange} />

            {/* PASSWORD */}
            <LoginPassword onChange={handlePasswordChange} />

            {errorMessage &&
                <div className="login-error"
                    style={{
                        color: studio.foregroundColor,
                        borderColor: studio.highlightBackgroundColor
                    }}>
                    {errorMessage}
                </div>
            }

            {/* SUBMIT */}
            <LoginButton onClick={handleSubmit} enabled={submitEnabled} />

            {/* SIGN-UP LINK */}
            <LoginLink onClick={handleSignupClick} />

        </div>
    );
}

export default Login;
