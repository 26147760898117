import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './FieldHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import FieldMenu from './fieldmenu/FieldMenu';

const FieldHeader = ({ field, value, readOnly = false, showFieldMenu = false, isTitle = false,  additionalOptions = [] }) => {
  const { theme } = useTheme();
  const {
      userRole
  } = useContext(Global);

  return (
    <div className="field-header-container">

          {/* TITLE */}
          <div className="field-header-label"
            style={{ color: theme.foregroundColorFaded }}>
            {field.title}
          </div>

          {/* FIELD MENU */}
          <div>
            {userRole === "ADMIN" && showFieldMenu &&
              <FieldMenu field={field} additionalOptions={additionalOptions} />
            }
          </div>
          
    </div>
  );
};

export default FieldHeader;
