import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import './CurrencyCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * CurrencyCell Component
 * 
 * This component renders an input field that accepts only numeric values and formats as currency.
 * 
 * @param {Object} props - The properties object.
 * @param {Object} props.object - The object containing field values.
 * @param {Object} props.field - The field metadata.
 * @param {Function} props.handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CurrencyCell = ({ object, field, handleBlur }) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || ''); 
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [foregroundColor, setForegroundColor] = useState(theme.foregroundColor || 'transparent');

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function formats the input to allow only numbers and decimal points,
   * and updates the value state.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === null || isNaN(inputValue)) return;
    // Allow numbers and decimal points, remove non-numeric characters
    const formattedValue = inputValue.replace(/[^0-9.]+/g, '');
    setValue(formattedValue);
  };

  /**
   * Format to currency
   * 
   * This function formats a number string to two decimal places for currency.
   * 
   * @param {string} value - The numeric string to format.
   * @returns {string} The formatted currency string.
   */
  const formatToCurrency = (value) => {
    const numericValue = parseFloat(value);
    return numericValue.toFixed(2);
  };

  /**
   * Handle input blur
   * 
   * This function resets the background and foreground colors,
   * formats the value to currency,
   * and calls the handleBlur function passed as a prop.
   * 
   * @param {Object} e - The event object.
   */
  const handleFieldBlur = (e) => {
    setBackgroundColor('transparent');
    setForegroundColor(theme.foregroundColor);
    // Ensure value is not NaN or empty
    const sanitizedValue = value === '' ? '0' : value;
    const formattedValue = formatToCurrency(sanitizedValue);
    setValue(formattedValue);
    handleBlur(object.key, field.key, formattedValue);
  };

  /**
   * Handle input focus
   * 
   * This function sets the background and foreground colors to highlight colors
   * when the input is focused.
   * 
   * @param {Object} e - The event object.
   */
  const handleFocus = (e) => {
    setBackgroundColor(theme.backgroundColorFaded);
    setForegroundColor(theme.backgroundColorFaded);
  };

  return (
    <div
      className="currency-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}
    >
      <input
        type="text"
        className="currency-cell-input"
        style={{
          color: theme.foregroundColor,
        }}
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleFieldBlur}
      />
    </div>
  );
};

CurrencyCell.propTypes = {
  object: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

CurrencyCell.defaultProps = {
  object: {},
  field: {},
  handleBlur: () => {},
};

export default CurrencyCell;
