import React from 'react';

const TableIcon = ({ color = "white", width = "16", height = "16" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 16 16" fill={color}>
    <path d="M13.5 2h-12l-.5.5v11l.5.5h12l.5-.5v-11l-.5-.5zM2 3h11v1H2V3zm7 4H6V5h3v2zm0 1v2H6V8h3zM2 5h3v2H2V5zm0 3h3v2H2V8zm0 5v-2h3v2H2zm4 0v-2h3v2H6zm7 0h-3v-2h3v2zm0-3h-3V8h3v2zm-3-3V5h3v2h-3z" />
  </svg>
);

export default TableIcon;
