import React from 'react';

// Styles
import './GallerySummaryPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
//import nature from '../../../common/images/nature.jpg';
import nature from '../../../common/images/nature.jpg';

const GallerySummaryPreview = ({ element }) => {
    const { theme } = useTheme();

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    return (
        <div className="gallery-summary-preview-container"
            style={{ backgroundColor: theme.backgroundColorFaded }}>
            <div className="gallery-summary-preview-wrapper" style={filteredStyles}>
                <img
                    src={nature}
                    alt="Nature"
                    className="gallery-summary-preview-image"
                />
            </div>
        </div>
    );
};

export default GallerySummaryPreview;
