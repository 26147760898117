import React from 'react';

// Styles
import './ForumPostFormObjects.css';

// Components
import ForumPostFormObject from './ForumPostFormObject';

const ForumPostFormObjects = ({ objects }) => {

  return (
    <>
      {objects && objects.length > 0 &&
        <div className="forum-post-form-objects-container">
          {objects.map((obj, index) => (
            <ForumPostFormObject
              key={index}
              object={obj}  // Pass the entire object without destructuring it
            />
          ))}
        </div>
      }
    </>
  );
};

export default ForumPostFormObjects;
