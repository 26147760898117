import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Circle Loader
import { CircleLoader } from 'react-spinners';

// Styles
import './Progress.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * Progress Component
 * 
 * This component renders a masked progress indicator.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Progress = () => {
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    progressVisible,
    statusMessage
  } = useContext(Global);

  if (!progressVisible) return null;

  return (
    <>
      {/* MASK */}
      <div className="progress-mask">

        {/* CONTENT */}
        <div className="progress-content">

          {/* MESSAGE */}
          <div className="progress-status-message"
            style={{
              color: theme.foregroundColor
            }}>
            {statusMessage}
          </div>

          {/* CIRCLE */}
          <div className="progress-circle">
            <CircleLoader
              size={50}
              color={theme.highlightBackgroundColor}
              loading={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Progress;
