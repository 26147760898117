import React from 'react';

// Styles
import './AppUsersToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
//import ViewMenu from './ViewMenu';

const AppUsersToolBar = () => {
    const { theme } = useTheme();

    return (

        <div className="app-users-toolbar-container">
            <div className="app-users-toolbar-buttons">
                <div className="app-users-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}
                >
                    Users
                </div>
                <div className="app-users-toolbar-right" >
                    {/**
                    {selectedModel &&
                        <ViewMenu />
                    }
                    */}
                </div>
            </div>
        </div>
    );
};

export default AppUsersToolBar;
