import React from 'react';

// Styles
import './ButtonSummaryPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import TextIcon from '../../../common/svg/TextIcon';
import BookmarkIcon from '../../../common/svg/BookmarkIcon';
import CartIcon from '../../../common/svg/CartIcon';
import FavoriteIcon from '../../../common/svg/FavoriteIcon';
import FollowIcon from '../../../common/svg/FollowIcon';
import CommentIcon from '../../../common/svg/CommentIcon';
import DislikeIcon from '../../../common/svg/DislikeIcon';
import DotIcon from '../../../common/svg/DotIcon';
import DownloadIcon from '../../../common/svg/DownloadIcon';
import DownvoteIcon from '../../../common/svg/DownvoteIcon';
import EmojiIcon from '../../../common/svg/EmojiIcon';
import LikeIcon from '../../../common/svg/LikeIcon';
import PrintIcon from '../../../common/svg/PrintIcon';
import PriorityIcon from '../../../common/svg/PriorityIcon';
import ShareIcon from '../../../common/svg/ShareIcon';
import UpvoteIcon from '../../../common/svg/UpvoteIcon';

const ButtonSummaryPreview = ({ element }) => {
    const { theme } = useTheme();

    const iconMap = {
      bookmark: BookmarkIcon,
      cart: CartIcon,
      comment: CommentIcon,
      dislike: DislikeIcon,
      dot: DotIcon,
      download: DownloadIcon,
      downvote: DownvoteIcon,
      emoji: EmojiIcon,
      favorite: FavoriteIcon,
      follow: FollowIcon,
      like: LikeIcon,
      print: PrintIcon,
      priority: PriorityIcon,
      share: ShareIcon,
      text: TextIcon,
      upvote: UpvoteIcon,
    };

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { width, height } = styles;

    // Dynamically choose the correct icon based on element.buttonType
    const IconComponent = iconMap[element.buttonType];

    // Remove "px" from width and height, default to 14 if undefined
    const iconWidth = width ? parseInt(width, 10) : 14;
    const iconHeight = height ? parseInt(height, 10) : 14;

    return (
        <div className="button-summary-preview-container">
          <IconComponent
            color={theme.foregroundColor}
            width={iconWidth}
            height={iconHeight}
          />
        </div>
    );
};

export default ButtonSummaryPreview;
