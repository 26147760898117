import React from 'react';

// Styles
import './Element.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Helpers
import DragHelper from '../DragHelper';
const dragHelper = new DragHelper();

/**
 * Element Component
 * 
 * This component displays an element that can be added to the canvas.
 * 
 * An element consists of:
 * - type - The type of element ("field", "label", "button").
 * - icon - The icon to display before the element title.
 * - title - The title of the element to display.
 * - field - The field object of the element if the type is "field". 
 *           Consists of all field properties.
 * - button - The button object of the element if the type is "button". 
 *            Consists of:
 *            - type - "button".
 *            - buttonType - e.g. "bookmark", "share", "follow".
 *            - label - The label of the button.
 *            - icon - The icon of the button.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Element = ({ element, icon: IconComponent, title }) => {
  
  // Theme
  const { theme } = useTheme();

  /**
   * Method to handle drag start as the user begins dragging an element onto the canvas
   * 
   * @param {event} e - Drag event.
   * @param {object} element - Element that is being dragged
   */
  const handleDragStart = (e, elem) => {

    // Start the drag for a new element
    dragHelper.handleDragStart(e, elem, "new");

  };

  return (
    <>
      {/* CONTAINER */}
      <div 
        className="element-container"
        title={title}
        draggable="true"
        onDragStart={(event) => handleDragStart(event, element)}>

        {/* ICON */}
        <div className="element-icon">
          <IconComponent
            color={theme.foregroundColor}
            width="14"
            height="14"
          />
        </div>

        {/* TITLE */}
        <div className="element-title"
          style={{
            color: theme.foregroundColor,
          }}>
          {title}
        </div>

      </div>
    </>
  );

};

export default Element;
