import React, { useRef } from 'react';

// Styles
import './UserPhotoSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import UserThumb from '../../../common/components/userthumb/UserThumb';

const UserPhotoSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();
    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    const user = {
        key: object.userKey,
        username: object.username || ''
    };

    return (
        <div
            className="user-photo-summary-container"
            ref={containerRef}
            style={{ ...processed, height }}>
            <UserThumb 
                user={user} 
                size={parseInt(width)}
            />
        </div>
    );
};

export default UserPhotoSummary;
