import React from 'react';

// Styles
import './UserFieldsGroup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserFieldsGroup = ({ title, children }) => {
  const { theme } = useTheme();

  return (
    <>
      <div className="user-fields-group-section"
        style={{
          borderColor: theme.backgroundColorFaded
        }}>
        {title &&
          <div className="user-fields-group-title"
            style={{
              color: theme.foregroundColorFaded
            }}>
            {title}
          </div>
        }
        {children}
      </div>
    </>
  );
};

export default UserFieldsGroup;
