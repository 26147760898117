import React, { useEffect, useState } from 'react';

// Styles
import './MonthCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * MonthCell Component
 * 
 * This component renders an input field that accepts text values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MonthCell = ({
  object,
  field,
  handleBlur
}) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');

  const backgroundColor = "transparent";
  const foregroundColor = theme.foregroundColor;

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function sets the value to the input value.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    handleBlur(object.key, field.key, inputValue);
  };
    
  const months = [
    { name: "JAN", value: "January" },
    { name: "FEB", value: "February" },
    { name: "MAR", value: "March" },
    { name: "APR", value: "April" },
    { name: "MAY", value: "May" },
    { name: "JUN", value: "June" },
    { name: "JUL", value: "July" },
    { name: "AUG", value: "August" },
    { name: "SEP", value: "September" },
    { name: "OCT", value: "October" },
    { name: "NOV", value: "November" },
    { name: "DEC", value: "December" }
];

  return (
    <div className="month-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}>
      <select
        className="month-cell-input"
        style={{ color: theme.foregroundColor }}
        value={value}
        onChange={handleChange}>
        <option value="" {...(value !== '' ? { disabled: true } : {})}></option>
        {months.map(month => (
          <option key={month.name} value={month.name}>{month.value}</option>
        ))}
      </select>
    </div>
  );
};

export default MonthCell;
