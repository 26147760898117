import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './LogoSmall.css';

// Theme
import { useTheme } from '../../ThemeContext';

const LogoSmall = () => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  return (
    <div className="logo-small-container">
      <div className="logo-small-text-wrapper">
        <div className="logo-small-left"
          style={selectedApp ? {
            color: theme.foregroundColorFaded
          } : {
            color: studio.foregroundColorFaded
          }}>
            APP
        </div>
        <div className="logo-small-right"
          style={selectedApp ? {
            color: theme.foregroundColorFaded
          } : {
            color: studio.foregroundColorFaded
          }}>
          DECK
        </div>
      </div>
    </div>
  );
};

export default LogoSmall;

