
import { Timestamp } from 'firebase/firestore';

export function getElapsedTime(timestamp) {
  const now = Timestamp.now().toDate();
  const commentDate = timestamp.toDate();
  const diffInMs = now - commentDate;

  const minutes = Math.floor(diffInMs / 60000);
  const hours = Math.floor(diffInMs / 3600000);
  const days = Math.floor(diffInMs / 86400000);
  const months = Math.floor(diffInMs / 2592000000); // Approximate month
  const years = Math.floor(diffInMs / 31536000000); // Approximate year

  if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
  if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
  if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  return 'Just now';
};