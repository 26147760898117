import React, { useState } from 'react';

// Styles
import './ContextMenuOption.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
  .context-menu-option-container:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

/**
 * ContextMenuOption Component
 * 
 * This component renders an option for a context menu.
 * 
 * @param {object} icon - The icon component.
 * @param {string} text - The text to display.
 * @param {function} onClick - The function to call when the option is clicked.
 * @param {integer} iconSize - The size of the icon.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuOption = ({ icon: IconComponent, text, onClick, iconSize = 13 }) => {
  // Theme
  const { theme } = useTheme();

  // State Variables
  const [isHovered, setIsHovered] = useState(false);

  /**
   * Handles the mouse enter event.
   */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  /**
   * Handles the mouse leave event.
   */
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {/* INLINE STYLES */}
      <InlineStyles
        hoverColor={theme.highlightBackgroundColor}
        hoverTextColor={theme.highlightForegroundColor}
      />

      {/* CONTAINER */}
      <div
        onClick={onClick}
        className="context-menu-option-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          color: isHovered ? theme.highlightForegroundColor : theme.foregroundColor,
        }}>

        {/* ICON */}
        <div className="context-menu-option-icon">
          <IconComponent
            color={isHovered ? theme.highlightForegroundColor : theme.foregroundColor}
            width={iconSize}
            height={iconSize}
          />
        </div>

        {/* TEXT */}
        {text}
        
      </div>
    </>
  );
};

export default ContextMenuOption;
