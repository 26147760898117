import React from 'react';

// Styles
import './GalleryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import nature from '../../../../common/images/nature.jpg';

// Images
import BackIcon from '../../../../common/svg/BackIcon';
import ForwardIcon from '../../../../common/svg/ForwardIcon';

const GalleryPreview = ({
    selected = false,
    size
}) => {
    const { theme, hexToRgba } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="gallery-preview-container">
            <div className="gallery-preview-wrapper"
                style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
                <img
                    src={nature}
                    alt="Nature"
                    className="gallery-preview-image"
                />
            </div>
            <div
                className="gallery-preview-nav-button previous-button"
                style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                >
                <BackIcon
                    color={theme.foregroundColor}
                    width="20"
                    height="20"
                />
            </div>
            <div
                className="gallery-preview-nav-button next-button"
                style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                >
                <ForwardIcon
                    color={theme.foregroundColor}
                    width="20"
                    height="20"
                />
            </div>
            <div className="gallery-preview-image-count"
                    style={{
                        color: theme.foregroundColor,
                        backgroundColor: hexToRgba(theme.backgroundColor, 0.5)
                    }}>
                    7 of 14
                </div>
        </div>
    );
};

export default GalleryPreview;
