import React from 'react';

// Styles
import './CountriesPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CountriesPreview = ({ 
    placeholder = '', 
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";
    
    return (
        <div className="countries-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
            <div 
                className="countries-preview-label"
                style={{color: theme.foregroundColorFaded}}>
                {placeholder}
            </div>
            <select
                className="countries-preview-input"
                style={{color: theme.foregroundColor}}
                tabIndex={-1}>
                    <option value="USA">United States</option>
            </select>
        </div>
    );
};

export default CountriesPreview;
