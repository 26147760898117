import React from 'react';

const CloseIcon  = ({ color = "white", width = "14", height = "14" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 14 14"
  >
    <g stroke="none" fill="none">
        <g transform="translate(-649.000000, -3437.000000)">
            <g transform="translate(100.000000, 3378.000000)">
                <g transform="translate(544.000000, 54.000000)">
                    <g>
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <polygon fill={color} points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default CloseIcon;
