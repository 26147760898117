import React from 'react';

// Styles
import './CalendarYear.css';

const CalendarYear = () => {
    return (
        <div className="calendar-year-container">
            
        </div>
    );
};

export default CalendarYear;
