class KeyboardHelper {

    /**
     * Handles keyboard events to remove or move selected elements unless an input is focused.
     * 
     * @param {object} event - The keyboard event object.
     * @param {array} elements - Array of elements currently on the canvas.
     * @param {function} setElements - Function to update the elements on the canvas.
     * @param {object} selectedElement - The currently selected element.
     * @param {function} setSelectedElement - Function to update the selected element.
     */
    handleKeyPress(event, elements, setElements, selectedElement, setSelectedElement) {
        // Exit if no element is selected
        if (!selectedElement) return;

        // Check if the active element is an input, textarea, or other focusable element
        const activeElement = document.activeElement;
        const isInputFocused = activeElement.tagName === 'INPUT' ||
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.isContentEditable;

        // If an input is focused, do not remove or move the selected element
        if (isInputFocused) return;

        // Handle delete or backspace keys
        if (this.isDeleteKey(event.key)) {
            this.removeSelectedElement(elements, selectedElement, setElements, setSelectedElement);
        }
        // Handle arrow keys for moving elements
        else if (this.isArrowKey(event.key)) {
            this.moveSelectedElement(event.key, elements, setElements, selectedElement);
        }
    }


    /**
     * Checks if the pressed key is either 'Delete' or 'Backspace'.
     * 
     * @param {string} key - The key value from the keypress event.
     * @returns {boolean} - Returns true if the key is 'Delete' or 'Backspace', otherwise false.
     */
    isDeleteKey(key) {
        return key === 'Delete' || key === 'Backspace';
    }

    /**
     * Checks if the pressed key is an arrow key ('ArrowUp', 'ArrowDown', 'ArrowLeft', or 'ArrowRight').
     * 
     * @param {string} key - The key value from the keypress event.
     * @returns {boolean} - Returns true if the key is an arrow key, otherwise false.
     */
    isArrowKey(key) {
        return ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key);
    }

    /**
     * Moves the selected element in the specified direction based on the arrow key pressed.
     * 
     * @param {string} key - The arrow key that was pressed.
     * @param {Array} elements - The array of all elements.
     * @param {Function} setElements - Function to update the elements state.
     * @param {Object} selectedElement - The currently selected element.
     */
    moveSelectedElement(key, elements, setElements, selectedElement) {
        const keyToDirection = {
            'ArrowUp': { dx: 0, dy: -1 },
            'ArrowDown': { dx: 0, dy: 1 },
            'ArrowLeft': { dx: -1, dy: 0 },
            'ArrowRight': { dx: 1, dy: 0 }
        };

        const { dx, dy } = keyToDirection[key] || { dx: 0, dy: 0 };

        setElements(elements.map(element =>
            element.key === selectedElement.key
                ? this.updateElementPosition(element, dx, dy)
                : element
        ));
    }

    /**
     * Updates the position of an element by adjusting its left and top CSS styles.
     * 
     * @param {Object} element - The element to update.
     * @param {number} dx - The horizontal movement in pixels.
     * @param {number} dy - The vertical movement in pixels.
     * @returns {Object} - The updated element with new left and top styles.
     */
    updateElementPosition(element, dx, dy) {
        const currentLeft = parseInt(element.styles.left, 10) || 0;
        const currentTop = parseInt(element.styles.top, 10) || 0;

        return {
            ...element,
            styles: {
                ...element.styles,
                left: `${currentLeft + dx}px`,
                top: `${currentTop + dy}px`
            }
        };
    }

    /**
     * Removes the selected element from the list of elements.
     * 
     * @param {Array} elements - The array of all elements.
     * @param {Object} selectedElement - The element to be removed.
     * @param {Function} setElements - Function to update the elements state.
     * @param {Function} setSelectedElement - Function to clear the selected element state.
     */
    removeSelectedElement(elements, selectedElement, setElements, setSelectedElement) {
        const filteredElements = elements.filter(element => element.key !== selectedElement.key);
        setElements(filteredElements);
        setSelectedElement(null);  // Clear the selected element
    }
}

export default KeyboardHelper;
