import React from 'react';

// Styles
import './AppSettingsButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import SettingsIcon from '../svg/SettingsIcon';

const AppSettingsButton = ({ onClick }) => {
    const { theme } = useTheme();

    return (
        <div className="app-settings-button-container"
            onClick={onClick}>
            <SettingsIcon
                color={theme.foregroundColorFaded}
                width="19"
                height="19"
            />
        </div>
    );
};

export default AppSettingsButton;
