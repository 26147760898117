// Firebase
import { collection, deleteDoc, doc, documentId, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

// Utilities
import { generateKey } from '../utilities/Keys';
import { chunkArray } from '../utilities/Arrays';

class AppUserManager {

    /**
     * Method to add a new app user.
     * 
     * @param {string} appKey - The app key.
     * @param {string} appUserKey - The new key of the appuser.
     * @param {string} data - App data
     * @returns {appuser} - New appuser record.
    */
    async add(appKey, appUserKey, data) {

        await setDoc(doc(db, collections.appusers, appUserKey), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
      * Fetches app users and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(appKey, onUpdate) {
        try {
            // Create a reference to the appusers collection
            const appUsersCollection = collection(db, collections.appusers);

            // Create a query to find app users by appKey and sort them by username
            const q = query(appUsersCollection, where("appKey", "==", appKey), orderBy("username"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const appUserList = snapshot.docs.map(doc => ({
                    id: doc.id, 
                    ...doc.data() 
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(appUserList);
                    activity.log(appKey, 'reads', appUserList.length);
                }
            }, error => {
                console.error("Error fetching app users:", error);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; 
        }
    }

    /**
     * Creates a unique relationship between an object and a user if it doesn't already exist.
     * 
     * @param {string} appKey - App key.
     * @param {string} objectKey - The key of the object.
     * @param {string} userKey - The key of the app user.
     * @param {string} fieldKey - Key of the source field.
     * @returns {Promise<void>} A promise that resolves if the operation is successful.
     */
    async createRelationship(appKey, objectKey, userKey, fieldKey) {
        // Reference to the relationships collection in the database
        const relationshipsRef = collection(db, collections.userrelationships);

        // Query to find existing relationships that match the provided source and target
        const querySnapshot = await getDocs(query(
            relationshipsRef,
            where(`objectKey`, '==', objectKey),
            where(`userKey`, '==', userKey),
            where(`fieldKey`, '==', fieldKey)
        ));

        activity.log(appKey, 'reads', querySnapshot.docs.length);

        // Check if any documents are returned by the query (indicating an existing relationship)
        if (!querySnapshot.empty) {
            console.log(`Relationship between ${objectKey} and ${userKey} for field ${fieldKey} already exists.`);
            return; // Exit the function as the relationship already exists
        }

        // If no existing relationship is found, proceed to create a new one
        const relationshipKey = generateKey();
        const relationshipDocRef = doc(relationshipsRef, relationshipKey);
        const relationshipData = {
            key: relationshipKey,
            appKey: appKey,
            objectKey: objectKey,
            userKey: userKey,
            fieldKey: fieldKey,
        };

        try {
            // Attempt to create the new relationship document in the relationships collection
            await setDoc(relationshipDocRef, relationshipData);
            console.log(`Relationship created.`);
        } catch (error) {
            console.error(`Error creating relationship:`, error);
            throw error;
        }
    }

    /**
     * Lists app users related to an object.
     * 
     * @param {string} appKey - App key.
     * @param {string} objectKey - Object key.
     * @param {string} fieldKey - Field key.
     * 
     * @returns {array} - App user list.
    */
    async listRelatedAppUsers(appKey, objectKey, fieldKey) {
        const relationshipsRef = collection(db, collections.userrelationships);
        const querySnapshot = await getDocs(query(relationshipsRef,
            where(`objectKey`, '==', objectKey),
            where(`fieldKey`, '==', fieldKey),
        ));

        activity.log(appKey, 'reads', querySnapshot.docs.length);

        const targetKeys = querySnapshot.docs.map(doc => doc.data()[`userKey`]);

        return this.fetchAppUsersWithKeys(appKey, targetKeys);
    }

    /**
     * Fetches app users with specific keys, considering the 10-item limit
     * 
     * @param {string} appKey - App key.
     * @param {string} keys - Keys of the objects to fetch.
    */
    async fetchAppUsersWithKeys(appKey, keys) {
        // Ensure keys are valid (non-empty, non-undefined)
        const validKeys = keys.filter(key => key !== undefined && key !== '');
        if (validKeys.length === 0) {
            return [];
        }

        const ref = collection(db, collections.appusers);
        const chunks = chunkArray(validKeys, 10); // Split the keys into chunks of 10 to comply with Firestore's limit
        const appUsers = []; // Array to hold the fetched documents

        try {
            for (const chunk of chunks) {
                // Ensure the chunk has elements to avoid invalid queries
                if (chunk.length > 0) {
                    const q = query(ref,
                        where('appKey', '==', appKey), 
                        where('userKey', 'in', chunk)); // Create a query for the current chunk
                    const snapshot = await getDocs(q); // Execute the query and get the documents

                    // Log the fetch operation with the number of documents fetched

                    activity.log(appKey, 'reads', snapshot.docs.length);

                    snapshot.forEach(doc => {
                        // Add each document to the appUsers array
                        appUsers.push({ id: doc.id, ...doc.data() });
                    });
                }
            }
            return appUsers; // Return the fetched appUsers
        } catch (error) {
            console.error("Error fetching appUsers with keys:", error);
            throw error; // Re-throw the error to be handled by the caller
        }
    }

    /**
     * Deletes a relationship between a user and an object.
     * 
     * @param {string} appKey - App key.
     * @param {string} objectKey - Object key.
     * @param {string} userKey -  User key.
     * @param {string} fieldKey -  Field key.
    */
    async deleteRelationship(appKey, objectKey, userKey, fieldKey) {
        const relationshipsRef = collection(db, collections.userrelationships);
        const relationshipQuery = query(relationshipsRef,
            where(`objectKey`, "==", objectKey),
            where(`userKey`, "==", userKey),
            where(`fieldKey`, "==", fieldKey));

        try {
            const querySnapshot = await getDocs(relationshipQuery);

            activity.log(appKey, 'reads', querySnapshot.docs.length);

            if (querySnapshot.empty) {
                console.log("No matching relationship found to delete.");
                return;
            }

            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            console.log("Relationship deleted successfully.");
        } catch (error) {
            console.error(`Error deleting relationship:`, error);
            throw error;
        }
    }

    /**
     * Fetches users of an app along with their detailed information and roles.
     * 
     * @param {string} appKey - App key to get users for.
     * @returns {Promise<Array>} A promise that resolves to an array of user details and their roles.
     */
    async fetchAppUsers(appKey) {
        try {
            const appUsersCollection = collection(db, collections.appusers);
            const usersCollection = collection(db, collections.users);

            // Query app users based on the appKey only
            const q = query(appUsersCollection, where("appKey", "==", appKey));

            // Fetch the documents from the query
            const appUsersSnapshot = await getDocs(q);
            activity.log(appKey, 'reads', appUsersSnapshot.docs.length);

            // Extract user keys from the fetched documents
            const userKeys = appUsersSnapshot.docs.map(doc => doc.data().userKey);

            // Fetch user details for each user key
            const userDetailsPromise = userKeys.map(userKey =>
                getDocs(query(usersCollection, where("key", "==", userKey)))
            );

            // Wait for all user detail fetches to complete
            const userDetailsResults = await Promise.all(userDetailsPromise);

            // Combine app user data with user details
            const combinedResults = appUsersSnapshot.docs.map((doc, index) => {
                if (userDetailsResults[index].empty) {
                    console.error(`No user details found for userKey: ${doc.data().userKey}`);
                    return null; // or handle the error as needed
                }
                const userData = userDetailsResults[index].docs[0].data();

                return {
                    id: doc.id,
                    appKey: doc.data().appKey,
                    userKey: doc.data().userKey,
                    roleKey: doc.data().roleKey,
                    dateJoined: doc.data().dateJoined,
                    ...userData, // Spread the full user details here
                    key: doc.data().key
                };
            }).filter(result => result !== null); // Filter out the null results

            return combinedResults;
        } catch (error) {
            console.error("Error fetching app users:", error);
            throw error;
        }
    }

    /**
 * Returns an app user related to an object.
 * 
 * @param {string} appKey - App key.
 * @param {string} objectKey - Object key.
 * @param {string} fieldKey - Field key.
 * 
 * @returns {object|null} - App user object or null if not found.
 */
    async fetchRelatedAppUser(appKey, objectKey, fieldKey) {
        const relationshipsRef = collection(db, collections.userrelationships);

        // Query to fetch documents where the objectKey matches the objectKey and fieldKey matches the fieldKey
        const querySnapshot = await getDocs(query(relationshipsRef,
            where('objectKey', '==', objectKey),
            where('fieldKey', '==', fieldKey)
        ));

        // Log the number of documents read
        activity.log(appKey, 'reads', querySnapshot.docs.length);

        // Check if there is at least one document returned from the query
        if (!querySnapshot.empty) {
            // Get the first document from the query
            const firstDoc = querySnapshot.docs[0];

            // Extract the userKey from the document data
            const key = firstDoc.data().userKey;

            // Check if key exists
            if (key) {
                // Fetch the app user using the userKey and appKey
                const appUser = await this.fetchAppUserByKey(key);

                // Return the fetched app user
                if (appUser) {
                    return appUser;
                }
            }
        }

        // Return null if no valid userKey is found or app user does not exist
        return null;
    }

    /**
     * Fetches an app user document where userKey and appKey match the provided parameters.
     * 
     * @param {string} key - User key.
     * @returns {object|null} The document data if found, otherwise null.
    */
    async fetchAppUserByKey(key) {
        try {
            const appUsersCollection = collection(db, collections.appusers);

            const q = query(appUsersCollection, where('key', '==', key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching app user:", error);
            throw error;
        }
    }

    /**
     * Fetches an app user document where userKey and appKey match the provided parameters.
     * 
     * @param {string} userKey - User key.
     * @param {string} appKey - App key.
     * @returns {object|null} The document data if found, otherwise null.
    */
    async fetchAppUser(userKey, appKey) {
        try {
            const appUsersCollection = collection(db, collections.appusers);

            const q = query(appUsersCollection, where('userKey', '==', userKey), where('appKey', '==', appKey));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching app user:", error);
            throw error;
        }
    }

    /**
     * Updates an appuser's role key in the Firestore database.
     * 
     * @param {string} appKey - The app key.
     * @param {string} userKey - The user key.
     * @param {string} roleKey - New role key.
    */
    async updateAppUserRole(appKey, userKey, roleKey) {
        try {
            // First, query for the specific document
            const appUsersCollection = collection(db, collections.appusers);
            const q = query(appUsersCollection, where("appKey", "==", appKey), where("userKey", "==", userKey));
            const snapshot = await getDocs(q);

            // Check if the document exists and update it
            if (!snapshot.empty) {
                const docRef = snapshot.docs[0].ref;  // Get the reference of the first document
                await updateDoc(docRef, { roleKey });  // Update the role key
                activity.log(appKey, 'writes', 1);  // Log the activity using appKey
            } else {
                console.error("No matching document found to update.");
                throw new Error("No matching document found to update.");
            }
        } catch (error) {
            console.error("Error updating app user role:", error);
            throw error;
        }
    }

    /**
     * Updates an appuser's rating in the Firestore database.
     * 
     * @param {string} appKey - The app key.
     * @param {string} userKey - The user key.
     * @param {string} roleKey - New rating.
    */
    async updateAppUserRating(appKey, userKey, rating) {
        try {
            const appUsersCollection = collection(db, collections.appusers);
            const q = query(appUsersCollection, where("appKey", "==", appKey), where("userKey", "==", userKey));
            const snapshot = await getDocs(q);

            // Check if the document exists and update it
            if (!snapshot.empty) {
                const docRef = snapshot.docs[0].ref;  // Get the reference of the first document
                await updateDoc(docRef, { rating });  // Update the rating
                activity.log(appKey, 'writes', 1);  // Log the activity using appKey
            } else {
                console.error("No matching document found to update.");
                throw new Error("No matching document found to update.");
            }
        } catch (error) {
            console.error("Error updating app user rating:", error);
            throw error;
        }
    }

    /**
     * Updates the user's rating for an app in the Firestore database.
     *
     * @param {string} appKey - The key (document ID) of the app to update.
     * @param {string} userKey - The key (document ID) of the user providing the rating.
     * @param {number} rating - The rating value provided by the user.
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async updateUserRating(appKey, userKey, rating) {

        const q = query(
            collection(db, collections.appusers),
            where('appKey', '==', appKey),
            where('userKey', '==', userKey)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const appUserDocRef = querySnapshot.docs[0].ref;
            await updateDoc(appUserDocRef, { rating });
        }
    }

    /**
     * Removes an appuser from the Firestore database.
     * 
     * @param {string} appKey - The app key.
     * @param {string} appUser - The app user.
    */
    async removeAppUser(appKey, appUser) {
        try {
            const docRef = doc(db, collections.appusers, appUser.key);

            await deleteDoc(docRef);

            activity.log(appKey, 'deletes', 1);

            console.log(`Successfully deleted appuser with key: ${appUser.key}`);
        } catch (error) {
            console.error("Error deleting appuser:", error);
            throw error;
        }
    }
    
    /**
     * Updates the name of a user in the _appusers collection.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} userKey - Key of the user being updated.
     * @param {string} date -Data to update.
     */
    async updateUserInfo(appKey, userKey, data) {
        try {
            // Reference to the _appusers collection
            const appUsersRef = collection(db, collections.appusers);

            // Query to find all appUser records where the userKey matches
            const q = query(appUsersRef, where("userKey", "==", userKey));

            // Get all matching documents
            const querySnapshot = await getDocs(q);

            // Create a batch to update multiple documents
            const batch = writeBatch(db);

            querySnapshot.forEach((docSnapshot) => {
                // For each document, update the username
                batch.update(docSnapshot.ref, data);
            });

            activity.log(appKey, 'updates', batch.size);

            // Commit the batch update
            await batch.commit();

            console.log("User name updated in all relevant app user records.");

        } catch (error) {
            console.error("Error updating app user records: ", error);
            alert('Error updating app user records.');
        }
    }

}

export default AppUserManager;
