import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './BackButton.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import BackIcon from '../common/svg/BackIcon';

const BackButton = () => {
  const { theme } = useTheme();
  const { backVisible, backButtonAction } = useContext(Global);  // Use the backVisible and backButtonAction state

  /**
   * BACK BUTTON CLICK
   * Executes the backButtonAction if it's set.
   */
  const handleBack = () => {
    if (backButtonAction) {
      backButtonAction();  // Execute the passed action
    }
  };

  if (!backVisible) return null; // Don't render the back button if it's not visible

  return (
    <div className="back-button-container" onClick={handleBack}>
      <BackIcon 
        color={theme.foregroundColor} 
        width="16" 
        height="16" 
      />
    </div>
  );
};

export default BackButton;
