// Firebase
import { collection, deleteDoc, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class CommentManager {

    /**
     * Method to add a new comment.
     * 
     * @param {string} key - The new key of the comment.
     * @param {string} data - App data
     * @returns {comment} - New comment record.
    */
    async addComment(key, data) {

        await setDoc(doc(db, collections.comments, key), data);

        activity.log(key, 'writes', 1);

        return data;
    }

    /**
     * Fetches an comment.
     * 
     * @param {string} key - Comment key.
     * @returns {Promise<Object|null>} A promise that resolves to the comment object if found, or null if not found.
    */
    async fetchComment(key) {
        try {
            const commentCollection = collection(db, collections.comments);
            const q = query(commentCollection, where("key", "==", key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching comment:", error);
            throw error;
        }
    }

    /**
     * Fetches field comments.
     * 
     * @param {string} objectKey - Key of the object.
     * @param {string} fieldKey - Key of the field.
     * @returns {Promise<Array>} A promise that resolves to an array of comments.
     */
    async fetchFieldComments(objectKey, fieldKey) {
        try {
            // Fetch all comments for the object and field
            const commentsQuery = query(
                collection(db, collections.comments),
                where("objectKey", "==", objectKey),
                where("fieldKey", "==", fieldKey)
            );
            const snapshot = await getDocs(commentsQuery);
            const comments = snapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));

            return comments;
        } catch (error) {
            console.error('Error fetching field comments:', error);
            throw error;
        }
    }

    /**
     * Fetches headline comments.
     * 
     * @param {string} postKey - Key of the post.
     * @returns {Promise<Array>} A promise that resolves to an array of comments.
     */
    async fetchPostComments(postKey) {
        try {
            // Fetch all comments for the object and field
            const commentsQuery = query(
                collection(db, collections.comments),
                where("postKey", "==", postKey)
            );
            const snapshot = await getDocs(commentsQuery);
            const comments = snapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));

            return comments;
        } catch (error) {
            console.error('Error fetching post comments:', error);
            throw error;
        }
    }

    /**
     * Deletes an comment from the Firestore database.
     * 
     * @param {string} key - Comment key.
     */
    async deleteComment(key) {
        try {

            await deleteDoc(doc(db, collections.comments, key));

        } catch (error) {
            console.error('Error deleting comment:', error);
            // Return an error message
            return { success: false, message: "Error deleting comment." };
        }
    }
}

export default CommentManager;
