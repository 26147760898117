import React, { useState } from 'react';

// Styles
import './ObjectChecklistItemMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotsIcon from '../../../../common/svg/DotsIcon';

// Images
import PencilIcon from '../../../../common/svg/PencilIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';
import ShowIcon from '../../../../common/svg/ShowIcon';

// Components
import ContextMenuButton from '../../../../common/components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../../common/components/contextmenu/ContextMenuPopup';

const ObjectChecklistItemMenu = ({ item, onRemoveClick, onViewClick, onNoteClick }) => {
    const { theme } = useTheme();

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    const handleRemoveClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        onRemoveClick();
    };

    const handleViewClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        onViewClick();
    };

    const handleNoteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        onNoteClick();
    };

    return (
        <div className="menu-item-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="menu-item-menu-container">
                <ContextMenuButton 
                    title="Menu Options" 
                    icon={DotsIcon} 
                    iconSize={16}
                    onToggle={toggleMenu} 
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColor}
                width={220}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={item.objectTitle}>

                    <ContextMenuOption
                        icon={RemoveIcon}
                        text="Remove From List"
                        onClick={handleRemoveClick}
                    />

                    <ContextMenuOption
                        icon={ShowIcon}
                        text="View Details"
                        onClick={handleViewClick}
                    />

                    <ContextMenuOption
                        icon={PencilIcon}
                        text="Add Note"
                        onClick={handleNoteClick}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

        </div>
    );
};

export default ObjectChecklistItemMenu;
