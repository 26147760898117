import React from 'react';

// Styles
import './ChatPostFormDocuments.css';

// Components
import ChatPostFormDocument from './ChatPostFormDocument';

const ChatPostFormDocuments = ({ files }) => {

  return (
    <>
      {files && files.length > 0 &&
        <div className="chat-post-form-documents-container">
          {files.map(({ file, url }, index) => (
            <ChatPostFormDocument
              key={index}
              file={file}
              url={url}
            />
          ))}
        </div>
      }
    </>
  );
};

export default ChatPostFormDocuments;
