import React from 'react';

// Styles
import './ContextMenuGroup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ContextMenuGroup Component
 * 
 * This component renders a group container for context menu items.
 * 
 * @param {string} title - The title of the group.
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuGroup = ({ title, children }) => {
  const { theme } = useTheme();

  return (
    <>
      {/* TITLE */}
      <div className="context-menu-group-title"
        style={{ color: theme.foregroundColorFaded }}>
        {title}
      </div>

      {/* WRAPPER */}
      <div className="context-menu-group-wrapper">
        {children}
      </div>
    </>
  );
};

export default ContextMenuGroup;
