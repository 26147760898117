import React from 'react';

const DownvoteIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path fill={color} d="M20.901,10.566C20.734,10.221,20.385,10,20,10h-2h-2V7V3c0-0.553-0.447-1-1-1H9C8.447,2,8,2.447,8,3v5v2H7H4 c-0.385,0-0.734,0.221-0.901,0.566c-0.166,0.347-0.12,0.758,0.12,1.059l8,10C11.409,21.862,11.696,22,12,22 s0.591-0.138,0.781-0.375l8-10C21.021,11.324,21.067,10.913,20.901,10.566z" />
    </svg>
);

export default DownvoteIcon;
