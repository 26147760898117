import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumPostFormObject.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CubeIcon from '../../../svg/CubeIcon';

const ForumPostFormObject = ({ object }) => {
  const { theme } = useTheme();
  const {
      models
  } = useContext(Global);

  // State Variables
  const [objectTitle, setObjectTitle] = useState('');
  
  console.log('PostFormObject object2', object);

  useEffect(() => {
    if (!object) return;
    const model = models.find(model => model.key === object.modelKey);
    const title = object[model.titleFieldKey];
    console.log('PostFormObject title', title);
    setObjectTitle(title);
  }, [object]);

  return (
    <div className="forum-post-form-object-container"
      style={{
        backgroundColor: theme.backgroundColorFaded
      }}>
      <CubeIcon
        color={theme.highlightBackgroundColor}
        width="14"
        height="14"
      />
      <div
        className="forum-post-form-object-objectname"
        style={{
          color: theme.foregroundColor
        }}>
        {objectTitle}
      </div>
    </div>
  );
};

export default ForumPostFormObject;
