import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './Account.css';

// Components
import AccountAbout from './about/AccountAbout';
import AccountBilling from './billing/AccountBilling';
import AccountMenu from './menu/AccountMenu';
import Modal from '../common/components/modal/Modal';

const Account = () => {
    const {
        accountAboutVisible,
        setUserAccountOpen,
        userAccountOpen
    } = useContext(Global);


    return (
        <Modal
            title=""
            height="90%"
            isOpen={userAccountOpen}
            onClose={() => setUserAccountOpen(false)}
            width="900px">
            <div className="account-container">
                <AccountMenu />
                {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
            </div>
        </Modal>
    );
};

export default Account;
