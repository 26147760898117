import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { getElapsedTime } from '../../../utilities/DateTime';

// Styles
import './ForumPosts.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import ForumPostItem from '../forumpostitem/ForumPostItem';

// Managers
import ForumPostManager from '../../../managers/ForumPostManager';

const forumPostManager = new ForumPostManager();

const ForumPosts = () => {
  const { theme } = useTheme();
  const {
    selectedApp,
    selectedChannel,
    selectedForumPost
  } = useContext(Global);

  // State Variables
  const [posts, setPosts] = useState([]);

  // Load the posts
  useEffect(() => {

    if (!selectedApp) return;

    const handleUpdate = (items) => {
      console.log("Forum: handleUpdate", items);
      setPosts(items);
    };

    const unsubscribe = forumPostManager.fetchAndSubscribe(selectedApp.key, selectedChannel.key, handleUpdate);

    return () => unsubscribe();

  }, [selectedApp, setPosts]);


  const handleUserClick = async (userKey) => {

  };

  const getTimestamp = (post) => {
    return <span
      className="forum-headline-timestamp"
      style={{
        color: theme.foregroundColorFaded
      }}
    >
      {getElapsedTime(post.dateCreated)}
    </span>
  };

  return (
    <>
      <div className="forum-posts-container">

        {/* LIST */}
        {posts.map((post) => (

          <React.Fragment key={post.key}>

            <>

              <div className="forum-posts-post-wrapper">
                <div
                  className="forum-posts-post"
                  style={{
                    color: theme.foregroundColorFaded,
                    borderRightColor: selectedForumPost && selectedForumPost.key === post.key ? theme.highlightBackgroundColor : "transparent",

                  }}
                >
                  {post.type === "POST" &&
                    <ForumPostItem
                      post={post}
                      handleUserClick={handleUserClick}
                      timestamp={getTimestamp(post)}
                    />
                  }
                </div>

              </div>
              <div
                className="forum-divider"
                style={{
                  borderBottomColor: theme.backgroundColorFaded
                }}
              ></div>

            </>

          </React.Fragment>

        ))}
      </div>
    </>
  );

};

export default ForumPosts;
