import React from 'react';

// Styles
import './UserFieldsRow.css';

const UserFieldsRow = ({ children }) => {

  return (
    <div className="user-fields-row-container">
      {children}
    </div>
  );
};

export default UserFieldsRow;
