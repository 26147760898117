import React from 'react';

// Utilities
import { truncateFileName } from '../../../../common/utilities/Strings';

// Styles
import './Document.css';

// Images
import DocumentIcon from '../../../svg/DocumentIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

const Document = ({ document }) => {
    const { theme } = useTheme();

    const handleDocumentClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div key={document.url} className="document-container"
                style={{
                    backgroundColor: theme.backgroundColorFaded
                }}>
                <DocumentIcon
                    color={theme.highlightBackgroundColor}
                    width="14"
                    height="14"
                />
                <div
                    className="document-filename"
                    style={{
                        color: theme.foregroundColor
                    }}
                    onClick={() => handleDocumentClick(document.url)}
                >
                    {truncateFileName(document.name, 100)}
                </div>
            </div>
        </>
    );
};

export default Document;
