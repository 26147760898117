import React, { useContext, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

// Styles
import './MonthPicker.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Global
import { Global } from '../../Global';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const getMonthDays = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
};

const MonthPicker = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        currentYear, setCurrentYear,
        currentMonth, setCurrentMonth,
        monthPickerVisible,
        setActiveDate,
        setMonthPickerVisible
    } = useContext(Global);

    useEffect(() => {
        const today = new Date();
        setCurrentYear(today.getFullYear());
        setCurrentMonth(today.getMonth());
    }, [setCurrentYear, setCurrentMonth]);
    
    const daysInMonth = getMonthDays(currentYear, currentMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);
    const daysInPreviousMonth = getMonthDays(currentYear, currentMonth - 1);

    const days = [];
    // Previous month's days
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        days.push({
            day: daysInPreviousMonth - i,
            month: 'prev',
        });
    }
    // Current month's days
    for (let i = 1; i <= daysInMonth; i++) {
        days.push({
            day: i,
            month: 'current',
        });
    }
    // Next month's days to fill the week
    let nextMonthDays = 1;
    while (days.length % 7 !== 0) {
        days.push({
            day: nextMonthDays++,
            month: 'next',
        });
    }

    const handleDayClick = (day, month) => {
        const monthOffset = month === 'prev' ? -1 : month === 'next' ? 1 : 0;
        const date = new Date(currentYear, currentMonth + monthOffset, day);
        setActiveDate(date);
        setMonthPickerVisible(false);
    };

    const isActiveDate = (day, month) => {
        const monthOffset = month === 'prev' ? -1 : month === 'next' ? 1 : 0;
        const date = new Date(currentYear, currentMonth + monthOffset, day);
        return activeDate && date.toDateString() === activeDate.toDateString();
    };

    const getDayStyle = (day, month) => {
        const active = isActiveDate(day, month);
        return {
            color: active ? theme.highlightForegroundColor : (month === "prev" || month === "next" ? theme.backgroundColorFaded : theme.foregroundColor),
            borderBottomColor: theme.backgroundColorFaded,
            borderRightColor: theme.backgroundColorFaded,
            backgroundColor: active ? theme.highlightBackgroundColor : 'inherit'
        };
    };

    const renderDayCell = (day, index) => (
        <div 
            key={index} 
            className={`month-picker-cell ${day.month}`} 
            onClick={() => handleDayClick(day.day, day.month)}
            style={getDayStyle(day.day, day.month)}
        >
            {day.day}
        </div>
    );

    const handleSwipeLeft = () => {
        if (currentMonth === 11) {
            setCurrentYear(currentYear + 1);
            setCurrentMonth(0);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const handleSwipeRight = () => {
        if (currentMonth === 0) {
            setCurrentYear(currentYear - 1);
            setCurrentMonth(11);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div {...swipeHandlers} className="month-picker-container">
            {monthPickerVisible &&
                <>
                    <div 
                        className="month-picker-header"
                        style={{borderBottomColor: theme.backgroundColorFaded}}
                    >
                        {daysOfWeek.map((day, index) => (
                            <div 
                                key={index} 
                                className="month-picker-day"
                                style={{color: theme.foregroundColorFaded}}
                            >
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className="month-picker-days">
                        {days.map((day, index) => renderDayCell(day, index))}
                    </div>
                </>
            }
        </div>
    );
};

export default MonthPicker;
