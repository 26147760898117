import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './CalendarToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import CalendarButton from './CalendarButton';
import MonthPicker from './MonthPicker';
import MonthPickerButton from './MonthPickerButton';

// Images
import LeftIcon from '../../common/svg/LeftIcon';
import RightIcon from '../../common/svg/RightIcon';

const CalendarToolBar = () => {
    const { theme } = useTheme();
    const {
        calendarTitle,
        currentYear, setCurrentYear,
        currentMonth, setCurrentMonth,
        selectedCalendar
    } = useContext(Global);

    const handleLeftClick = () => {
        if (currentMonth === 0) { // January
            setCurrentMonth(11); // Set to December
            setCurrentYear(currentYear - 1); // Move to previous year
        } else {
            setCurrentMonth(currentMonth - 1); // Move to previous month
        }
    };

    const handleRightClick = () => {
        if (currentMonth === 11) { // December
            setCurrentMonth(0); // Set to January
            setCurrentYear(currentYear + 1); // Move to next year
        } else {
            setCurrentMonth(currentMonth + 1); // Move to next month
        }
    };

    return (
        <div className="calendar-toolbar-container">
            <div className="calendar-toolbar-background">

                {selectedCalendar === 'SCHEDULE' ? (
                    <div className="calendar-toolbar-left">
                        <div className="calendar-toolbar-schedule"
                            style={{ color: theme.foregroundColorFaded }}>
                            Schedule
                        </div>
                    </div>

                ) : (

                    <div className="calendar-toolbar-left">
                        <div className="calendar-toolbar-nav">
                            {!isMobile &&
                                <>
                                    <div className="calendar-toolbar-nav-button"
                                        onClick={handleLeftClick}>
                                        <LeftIcon
                                            color={theme.foregroundColor}
                                            width="22"
                                            height="22"
                                        />
                                    </div>
                                    <div className="calendar-toolbar-nav-button"
                                        onClick={handleRightClick}>
                                        <RightIcon
                                            color={theme.foregroundColor}
                                            width="22"
                                            height="22"
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="calendar-toolbar-month">
                            <MonthPickerButton />
                        </div>
                        <div className="calendar-toolbar-title"
                            style={{ color: theme.foregroundColor }}
                        >
                            {calendarTitle}
                        </div>
                    </div>
                )}

                <div className="calendar-toolbar-right" >
                    <CalendarButton />
                </div>
            </div>
            <MonthPicker />
        </div>
    );
};

export default CalendarToolBar;
