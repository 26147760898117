// Firebase
import { collection, deleteDoc, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';
import { ref, deleteObject } from 'firebase/storage';
import { storage } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class DocumentManager {

    /**
     * Method to add a new document.
     * 
     * @param {string} key - The new key of the document.
     * @param {string} data - App data
     * @returns {document} - New document record.
    */
    async addDocument(key, data) {

        await setDoc(doc(db, collections.documents, key), data);

        activity.log(key, 'writes', 1);

        return data;
    }

    /**
     * Fetches an document.
     * 
     * @param {string} key - Document key.
     * @returns {Promise<Object|null>} A promise that resolves to the document object if found, or null if not found.
    */
    async fetchDocument(key) {
        try {
            const documentCollection = collection(db, collections.documents);
            const q = query(documentCollection, where("key", "==", key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error;
        }
    }

    /**
     * Fetches field documents.
     * 
     * @param {string} objectKey - Key of the object.
     * @param {string} fieldKey - Key of the field.
     * @returns {Promise<Array>} A promise that resolves to an array of commenrts.
     */
    async fetchFieldDocuments(objectKey, fieldKey) {
        try {
            // Fetch all documents for the object and field
            const documentsQuery = query(
                collection(db, collections.documents),
                where("objectKey", "==", objectKey),
                where("fieldKey", "==", fieldKey)
            );
            const snapshot = await getDocs(documentsQuery);
            const documents = snapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));

            return documents;
        } catch (error) {
            console.error('Error fetching documents:', error);
            throw error;
        }
    }

    /**
     * Deletes an document from the Firestore database.
     * 
     * @param {string} key - Document key.
     */
    async deleteDocument(key) {
        try {

            // Delete the document
            await deleteDoc(doc(db, collections.documents, key));

        } catch (error) {
            console.error('Error deleting document:', error);
            return { success: false, message: "Error deleting document." };
        }
    }

    /**
     * Deletes an document from Firestore storage.
     * 
     * @param {string} url - Document url.
     */
    async deleteDocumentByUrl(url) {
        // Delete the document from storage using the url
        try {
            // Extract the file path from the URL
            const basePath = "https://firebasestorage.googleapis.com/v0/b/jotspot-main.appspot.com/o/";
            const pathStartIndex = basePath.length;
            const pathEndIndex = url.indexOf('?alt=media');
            const storagePath = url.substring(pathStartIndex, pathEndIndex);
            const fileRef = ref(storage, decodeURIComponent(storagePath));

            await deleteObject(fileRef);
            console.log(`File deleted successfully: ${url}`);
        } catch (error) {
            console.error(`Error deleting file: ${url}`, error);
        }
    }
}

export default DocumentManager;
