import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './MobileHeader.css';

// Components
import BackButton from './BackButton';
import MenuButton from './MenuButton';
import RunLogo from './MobileLogo';
import UserButton from '../common/components/userbutton/UserButton';

const MobileHeader = () => {
  const {
    backVisible,
    selectedApp,
    selectedObject,
    setAppTitle,
  } = useContext(Global);

  useEffect(() => {
    if (selectedApp) {
      setAppTitle(selectedApp.title);
    }
  }, [selectedApp, setAppTitle]);

  return (
    <>
      <div className="mobile-header-container">
        <div className="mobile-header-left">
          {backVisible ? (
            <BackButton />
          ) : (
            <MenuButton />
          )}
        </div>
        <div className="mobile-header-center">
          <RunLogo />
        </div>
        <div className="mobile-header-right">
          <UserButton />
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
