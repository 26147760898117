import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import getFunctions and httpsCallable
import { getDatabase } from 'firebase/database'; // Import getDatabase

const firebaseConfig = {
  apiKey: "AIzaSyAYK9YcwcP03Ni3mbj9FbEjTCv_zp1mW7Q",
  authDomain: "jotspot-main.firebaseapp.com",
  projectId: "jotspot-main",
  storageBucket: "jotspot-main.appspot.com",
  messagingSenderId: "479004547956",
  appId: "1:479004547956:web:ae8b4663d87e2539acaa21",
  measurementId: "G-T95VRTX0NM",
  databaseURL: "https://jotspot-main-default-rtdb.firebaseio.com/" // Realtime Database
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
const db = getFirestore(app);

// Initialize Firebase Storage and get a reference to the service
const storage = getStorage(app);

// Initialize Firebase Functions and get a reference to the service
const functions = getFunctions(app);

// Initialize Firebase Realtime Database and get a reference to the service
const realTimeDb = getDatabase(app); // Initialize Realtime Database

// Collection names centralized
const collections = {
  activity: "_activity",
  admin: "_admin",
  apps: "_apps",
  appusers: "_appusers",
  channels: "_channels",
  chatposts: "_chatposts",
  comments: "_comments",
  conversations: "_conversations",
  documents: "_documents",
  events: "_events",
  fields: "_fields",
  forumposts: "_forumposts",
  headlines: "_headlines",
  index: "_index",
  invites: "_invites",
  messages: "_messages",
  models: "_models",
  roles: "_roles",
  subscriptions: "_subscriptions",
  summaries: "_summaries",
  templates: "_templates",
  transactions: "_transactions",
  userelements: "_userelements",
  userindex: "_userindex",
  userrelationships: "_userrelationships",
  users: "_users",
};

export { app, auth, db, collections, storage, functions, httpsCallable, realTimeDb };
