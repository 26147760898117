import React, { useContext, useEffect, useState } from 'react';

// Config
import { validSummaryTextTypes } from '../../Config';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../../common/utilities/Constants';

// Styles
import './Object.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Components
import ButtonSummary from './elements/ButtonSummary';
import CreatedSummary from './elements/CreatedSummary';
import GallerySummary from './elements/GallerySummary';
import TextSummary from './elements/TextSummary';
import UserNameSummary from './elements/UserNameSummary';
import UserPhotoSummary from './elements/UserPhotoSummary';
import VideoGallerySummary from './elements/VideoGallerySummary';
import ViewCountSummary from './elements/ViewCountSummary';

// Styled Components
const InlineStyles = useStyle`
    .object-container:hover {
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * Object Component
 * 
 * This component renders an object in list view
 * 
 * @param {object} object - The object containing field values.
 * @param {object} height - Overall card height.
 * @param {function} onClick - The function to call onClick.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Object = ({ object, onClick }) => {
  const { theme } = useTheme();
  const {
    models,
    selectedObject,
    setFormMode,
    setChangesMade,
    setSelectedObject,
    userRole
  } = useContext(Global);

  const [model, setModel] = useState(null);
  const [height, setHeight] = useState("200px");

  /**
   * Initializes the model.
   */
  useEffect(() => {
    const model = models.find((model) => model.key === object.modelKey);
    setModel(model);
    setHeight(model && model.summaryHeight);
  }, [object]);

  /**
   * Handles click on the object.
   */
  const handleClick = async () => {

    // Set the selected object
    setSelectedObject(object);

    // If the mode isn't set to edit (user is editing things), set it to view
    setFormMode(userRole === "ADMIN" ? FormMode.EDIT : FormMode.VIEW);

    // Initialize changes made to false
    setChangesMade(false);

  };

  return (
    <>
      <InlineStyles
        hoverColor={theme.backgroundColor}
      />

      {/* CONTAINER */}
      <div className="object-container"
        key={object.key}
        onClick={onClick ? onClick : handleClick}
        style={{
          borderRightWidth: selectedObject && selectedObject.key === object.key ? "5px" : "0px",
          borderRightColor: selectedObject && selectedObject.key === object.key ? theme.highlightBackgroundColor : "transparent",
          height: height || "200px"
        }}>

        {/* ELEMENTS */}
        {model && model.elements && model.elements.map((modelElement, index) => {
          
          const {
            top = "0px",
            left = "0px",
            width = "100%",
            height = "30px",
            zIndex = "0",
            ...otherStyles
          } = modelElement.styles || {};

          {/* ITEM CONTAINER */}
          return (

            <div
              key={index}
              className="object-item"
              style={{
                position: 'absolute',
                top,
                left,
                width,
                height,
                zIndex,
                ...otherStyles
              }}>

              {/* TEXT */}
              {(modelElement.type === "field" && validSummaryTextTypes.includes(modelElement.field.type)) &&
                <TextSummary element={modelElement} object={object} />
              }

              {/* CREATED */}
              {(modelElement.type === "static" && modelElement.elementType === 'created') &&
                <CreatedSummary element={modelElement} object={object} />
              }

              {/* PHOTOS */}
              {(modelElement.type === "field" && modelElement.field.type === 'gallery') &&
                <GallerySummary element={modelElement} object={object} />
              }

              {/* VIDEOS */}
              {(modelElement.type === "field" && modelElement.field.type === 'videogallery') &&
                <VideoGallerySummary element={modelElement} object={object} />
              }

              {/* BUTTONS */}
              {modelElement.type === "button" &&
                <ButtonSummary element={modelElement} object={object} />
              }

              {/* USER PHOTO */}
              {(modelElement.type === "static" && modelElement.elementType === 'userphoto') &&
                <UserPhotoSummary element={modelElement} object={object} />
              }

              {/* USER NAME */}
              {(modelElement.type === "static" && modelElement.elementType === 'username') &&
                <UserNameSummary element={modelElement} object={object} />
              }

              {/* VIEW COUNT */}
              {(modelElement.type === "static" && modelElement.elementType === 'viewcount') &&
                <ViewCountSummary element={modelElement} object={object} />
              }
            </div>
          );

        })}
      </div>
    </>
  );
};

export default Object;
