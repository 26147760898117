import React from 'react';

// Styles
import './DownloadButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DownloadIcon from '../../../../common/svg/DownloadIcon';

const DownloadButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="downvote-button-container">
            <DownloadIcon
                color={theme.foregroundColor}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default DownloadButton;
