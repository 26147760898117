import React from 'react';

// Styles
import './VideoGallery.css';

const VideoGallery = ({ post }) => {

    return (
        <>
            {post.videoUrls.length === 1 &&
                <video
                    controls
                    src={post.videoUrls[0]}
                    className="video-gallery-video"
                    style={{ width: '100%' }}
                />
            }

            {post.videoUrls.length === 2 &&
                <>
                    <div className="video-gallery-left">
                        <video
                            controls
                            src={post.videoUrls[0]}
                            className="video-gallery-video"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="video-gallery-right">
                        <video
                            controls
                            src={post.videoUrls[1]}
                            className="video-gallery-video"
                            style={{ width: '100%' }}
                        />
                    </div>
                </>
            }

            {post.videoUrls.length >= 3 &&
                <>
                    <div className="video-gallery-left">
                        <video
                            controls
                            src={post.videoUrls[0]}
                            className="video-gallery-video"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="video-gallery-right">
                        <div className="video-gallery-right-top">
                            <video
                                controls
                                src={post.videoUrls[1]}
                                className="video-gallery-video"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="video-gallery-right-bottom">
                            <video
                                controls
                                src={post.videoUrls[2]}
                                className="video-gallery-video"
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default VideoGallery;
