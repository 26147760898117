import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Message.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Managers

const Message = ({ message }) => {
    const { theme } = useTheme();
    const {
        currentUser
    } = useContext(Global);

    return (
        <div
            className={message.userKey === currentUser.key ? 'message-container-right' : 'message-container-left'}
            key={message.key}>
            <div className="message-wrapper"
                style={{
                    backgroundColor: message.userKey === currentUser.key ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                }}>
                <div className="message-text"
                    style={{
                        color: message.userKey === currentUser.key ? theme.highlightForegroundColor : theme.foregroundColor
                    }}>
                    {message.text}
                </div>
            </div>
        </div>
    );
};

export default Message;
