import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './EventObjectSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../../common/svg/PlusIcon';

// Components
import EventDivider from './EventDivider';
import Search from '../../../common/search/Search';

const EventObjectSelector = ({
    onObjectSelect
}) => {
    const { theme } = useTheme();
    const {
        models
    } = useContext(Global);

    const [searchVisible, setSearchVisible] = useState(false);
    const [selectedModelKey, setSelectedModelKey] = useState('');

    const handleAdd = (modelKey) => {
        setSelectedModelKey(modelKey);
        setSearchVisible(true);
    };

    const handleSearch = (entry) => {
        setSearchVisible(false);
        onObjectSelect(entry);
    };

    return (
        <>
            {/* MODEL ADD BUTTONS */}
            {models.map((model, index) => (
                <>
                    <div className="event-object-selector-wrapper" onClick={() => handleAdd(model.key)}>
                        <div
                            className="event-object-selector-button">
                            <PlusIcon 
                                color={theme.foregroundColorFaded} 
                                width="12" 
                                height="12" />
                        </div>
                        <div className="event-object-button-add-label" style={{ color: theme.foregroundColorFaded }}>
                            Add {model.title}
                        </div>
                    </div>

                    <EventDivider />
                </>
            ))}

            {/* SEARCH MODAL */}
            <Search
                onSearch={handleSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
                modelKey={selectedModelKey}
            />

        </>
    );
};

export default EventObjectSelector;
