import React from 'react';

// Styles
import './UserThumb.css';

// Components
import UserDot from '../userdot/UserDot';

/**
 * UserThumb Component
 * 
 * This component renders a user thumbnail.
 * 
 * @param {object} user - The user object.
 * @param {integer} size - The size of the thumbnail.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserThumb = ({ user, size }) => {

    return (
        <>
            {/* CONTAINER */}
            <div className="user-thumb-container"
                style={{
                    height: `${size}px`,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: `${size}px`,
                }}>

                {user.photo ? (
                    <>
                        {/* CONTAINER */}
                        <img
                            src={user.photo}
                            className="user-thumb-photo"
                            style={{
                                height: `${size}px`,
                                width: `${size}px`,
                            }}
                            alt="User"
                        />
                    </>
                ) : (
                    <>
                        {/* DOT */}
                        <UserDot
                            letter={user.username ? user.username.toUpperCase()[0] : ''}
                            size={size}
                        />
                    </>
            )}
        </div>
        </>
    );
};

export default UserThumb;

