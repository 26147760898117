import React from 'react';

// Styles
import './DocumentsPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const DocumentsPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="documents-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div className="documents-preview-label"
                style={{ color: theme.foregroundColorFaded }}>
                {placeholder}
            </div>
            <div className="documents-preview-title"
                style={{ color: theme.foregroundColor }}>
                {placeholder}
            </div>
        </div>
    );
};

export default DocumentsPreview;
