import React, { useEffect, useState } from 'react';

// Styles
import './AutonumberCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * AutonumberCell Component
 * 
 * This component renders an input field that accepts text values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AutonumberCell = ({ object, field, handleBlur }) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  return (
    <div className="autonumber-cell-container"
      style={{
        color: theme.foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}
    >
      <div className="autonumber-cell-label">
        {value}
      </div>
    </div>
  );
};

export default AutonumberCell;
