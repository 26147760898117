import React from 'react';

// Styles
import './ContextMenuButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DotsIcon from '../../svg/DotsIcon';

/**
 * ContextMenuButton Component
 * 
 * This component renders a button for opening a context menu.
 * 
 * @param {string} title - The title of the button.
 * @param {object} icon - The icon component.
 * @param {integer} iconSize - The size of the icon.
 * @param {function} onToggle - The function to call when the button is clicked.
 * @param {string} dotsColor - The color of the dots icon.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuButton = ({ title, icon: IconComponent = DotsIcon, iconSize = 16, onToggle, dotsColor }) => {
  // Theme
  const { theme } = useTheme();

  // Default dots color
  if (dotsColor === undefined) {
    dotsColor = theme.foregroundColor;
  }

  return (
    <>
      {/* CONTAINER */}
      <div className="context-menu-button-container"
        onClick={onToggle}>

        {/* ICON WRAPPER */}
        <div
          className="context-menu-button-icon"
          title={title}>

          {/* ICON */}
          <IconComponent
            color={dotsColor}
            width={iconSize}
            height={iconSize}
          />
        </div>

      </div>
    </>
  );
};

export default ContextMenuButton;
