import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './Admin.css';

// Components
import TitleBar from '../common/titlebar/TitleBar';

// Managers
import AdminManager from '../common/managers/AdminManager';

const adminManager = new AdminManager();

const Admin = () => {
  const {
    studio
  } = useContext(Global);

  const [appPrompt, setAppPrompt] = useState('');
  const [summaryPrompt, setSummaryPrompt] = useState('');
  const [fieldsPrompt, setFieldsPrompt] = useState('');
  const [activeTab, setActiveTab] = useState('APP_PROMPT'); // State to track the active tab

  const appPromptRef = useRef(null);
  const summaryPromptRef = useRef(null);
  const fieldsPromptRef = useRef(null);

  useEffect(() => {
    // Fetch APP_PROMPT when the component mounts
    fetchPrompt('APP_PROMPT');
  }, []);

  const fetchPrompt = async (promptType) => {
    try {
      const promptSetting = await adminManager.fetchSetting(promptType);
      if (promptSetting && promptSetting.value) {
        switch (promptType) {
          case 'APP_PROMPT':
            setAppPrompt(promptSetting.value);
            break;
          case 'SUMMARY_PROMPT':
            setSummaryPrompt(promptSetting.value);
            break;
          case 'FIELDS_PROMPT':
            setFieldsPrompt(promptSetting.value);
            break;
          default:
            break;
        }
      } else {
        console.warn(`${promptType} setting is missing value`);
      }
    } catch (error) {
      console.error(`Failed to fetch ${promptType}:`, error);
    }
  };

  const handleSave = async () => {
    try {
      if (appPrompt.trim() !== "") {
        await adminManager.updateSetting('APP_PROMPT', appPrompt);
      }
      if (summaryPrompt.trim() !== "") {
        await adminManager.updateSetting('SUMMARY_PROMPT', summaryPrompt);
      }
      if (fieldsPrompt.trim() !== "") {
        await adminManager.updateSetting('FIELDS_PROMPT', fieldsPrompt);
      }
      alert('Update successful.');
    } catch (error) {
      console.error('Failed to update setting:', error);
    }
  };

  const handleAppInputChange = (event) => {
    setAppPrompt(event.target.value);
  };

  const handleSummaryInputChange = (event) => {
    setSummaryPrompt(event.target.value);
  };

  const handleFieldsInputChange = (event) => {
    setFieldsPrompt(event.target.value);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    fetchPrompt(tab);
  };

  return (
    <>
      <TitleBar />
      <div className="admin-container"
        style={{ backgroundColor: studio.backgroundColor }}>
        <div className="admin-tabs">
          <div
            className={`admin-tab-item ${activeTab === 'APP_PROMPT' ? 'active' : ''}`}
            onClick={() => handleTabClick('APP_PROMPT')}>
            App Prompt
          </div>
          <div
            className={`admin-tab-item ${activeTab === 'SUMMARY_PROMPT' ? 'active' : ''}`}
            onClick={() => handleTabClick('SUMMARY_PROMPT')}>
            Summary Prompt
          </div>
          <div
            className={`admin-tab-item ${activeTab === 'FIELDS_PROMPT' ? 'active' : ''}`}
            onClick={() => handleTabClick('FIELDS_PROMPT')}>
            Fields Prompt
          </div>
        </div>
        <div className="admin-content">
          {activeTab === 'APP_PROMPT' && (
            <textarea
              ref={appPromptRef}
              value={appPrompt}
              onChange={handleAppInputChange}
              className="admin-prompt"
              style={{ backgroundColor: studio.backgroundColorFaded }}
            />
          )}
          {activeTab === 'SUMMARY_PROMPT' && (
            <textarea
              ref={summaryPromptRef}
              value={summaryPrompt}
              onChange={handleSummaryInputChange}
              className="admin-prompt"
              style={{ backgroundColor: studio.backgroundColorFaded }}
            />
          )}
          {activeTab === 'FIELDS_PROMPT' && (
            <textarea
              ref={fieldsPromptRef}
              value={fieldsPrompt}
              onChange={handleFieldsInputChange}
              className="admin-prompt"
              style={{ backgroundColor: studio.backgroundColorFaded }}
            />
          )}
        </div>
        <button onClick={handleSave} className="admin-save-button">
          Save
        </button>
      </div>
    </>
  );
};

export default Admin;
