import React from 'react';

// Styles
import './EventRemoveButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const EventRemoveButton = ({
  onRemove
}) => {
  const { theme } = useTheme();

  return (

    <div
      onClick={onRemove}
      className="event-remove-button"
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.foregroundColor,
      }}>
      Remove
    </div>
  );
};

export default EventRemoveButton;
