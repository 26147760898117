import React from 'react';

const UserIcon = ({ color = "white", width = "24", height = "24" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path fill={color} d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" />
    <path fill={color} d="M13 12H11C8.61305 12 6.32387 12.9482 4.63604 14.636C2.94821 16.3239 2 18.6131 2 21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 18.6131 21.0518 16.3239 19.364 14.636C17.6761 12.9482 15.3869 12 13 12Z" />
  </svg>
);

export default UserIcon;
