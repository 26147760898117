import React, { useState } from 'react';

// Styles
import './AppUserInviteButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import PlusIcon from '../../common/svg/PlusIcon';

// Components
import AppUserInviteModal from './AppUserInviteModal';

const AppUserInviteButton = () => {
  const { theme } = useTheme();

  const [inviteOpen, setInviteOpen] = useState(false);

  const handleAdd = async () => {
    setInviteOpen(true);
  };

  return (
    <>
      <div className="app-user-invite-button-container"
        style={{ backgroundColor: theme.highlightBackgroundColor }}
        onClick={handleAdd}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>
      <AppUserInviteModal 
        open={inviteOpen}
        setOpen={setInviteOpen}
      />
    </>
  );
};

export default AppUserInviteButton;
