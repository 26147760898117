import React from 'react';

// Styles
import './ForumPostFormButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ForumPostFormButton = ({ onClick }) => {
  const { theme } = useTheme();

  return (
    <div className="forum-post-form-button-container"
      style={{
        backgroundColor: theme.backgroundColorFaded,
        color: theme.foregroundColor
      }}
      onClick={onClick}>
      Post
    </div>
  );
};

export default ForumPostFormButton;
