import React, { } from 'react';

// Styles
import './ElementGroup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ElementGroup Component
 * 
 * This component displays a group wrapper around elements in the list.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ElementGroup = ({ title, children }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      <div className="element-group-title"
        style={{ color: theme.foregroundColorFaded }}>
        {title}
      </div>
      <div className="element-group-items">
        {children}
      </div>
    </>
  );

};

export default ElementGroup;
