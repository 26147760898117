import React from 'react';

// Styles
import './ForumPostFormVideos.css';

// Components
import ForumPostFormVideo from './ForumPostFormVideo';

const ForumPostFormVideos = ({ urls }) => {

  return (
    <>
      {urls && urls.length > 0 &&
        <div className="forum-post-form-videos-container">
          {urls.map((url, index) => (
            <ForumPostFormVideo 
              index={index}
              url={url}
            />
          ))}
        </div>
      }
    </>
  );
};

export default ForumPostFormVideos;
