import React, { useEffect, useState } from 'react';

// Styles
import './TimeCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * TimeCell Component
 * 
 * This component renders an drop-down list for selecting a time.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TimeCell = ({
  object,
  field,
  handleBlur
}) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');

  const backgroundColor = "transparent";
  const foregroundColor = theme.foregroundColor;

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function sets the value to the input value.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    handleBlur(object.key, field.key, inputValue);
  };

  // Generate times in 5-minute increments
  const generateTimes = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const valueHour = hour.toString().padStart(2, '0');
        const valueMinute = minute.toString().padStart(2, '0');
        const timeValue = `${valueHour}:${valueMinute}`; // Value in 24-hour format

        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const amPm = hour < 12 ? 'AM' : 'PM';
        const timeDisplay = `${displayHour}:${valueMinute} ${amPm}`; // Display in 12-hour format

        times.push({ value: timeValue, display: timeDisplay });
      }
    }
    return times;
  };

  const times = generateTimes(); // Ensure times is initialized by calling generateTimes

  return (
    <div className="time-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}>
      <select
        className="time-cell-input"
        style={{ color: theme.foregroundColor }}
        value={value}
        onChange={handleChange}>
        <option value="" {...(value !== '' ? { disabled: true } : {})}></option>
        {times.map((time, index) => (
            <option key={index} value={time.value}>{time.display}</option>
        ))}
      </select>
    </div>
  );
};

export default TimeCell;
