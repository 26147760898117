import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Forum.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AddButton from '../../addbutton/AddButton';
import ForumPost from './forumpost/ForumPost';
import ForumPosts from './forumposts/ForumPosts';
import ForumPostForm from './forumpostform/ForumPostForm';
import ForumToolBar from './ForumToolBar';

/**
 * Forum Component
 * 
 * This component renders a forum channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Forum = () => {
  const { theme } = useTheme();
  const {
    selectedForumPost
  } = useContext(Global);

  // State Variables
  const [addButtonVisible, setAddButtonVisible] = useState(true);
  const [formVisible, setFormVisible] = useState(false);

  // Handle the click event for the add button
  const handleAddClick = async () => {
    setAddButtonVisible(false);
    setFormVisible(true);
  };

  // Handle the post event
  const handlePost = async () => {
    setAddButtonVisible(true);
    setFormVisible(false);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className={(isMobile || isTablet) ? "forum-container-mobile" : "forum-container"}>


        {(!isMobile || (isMobile && !selectedForumPost)) && (
          <>
            {/* LEFT */}
            <div className="forum-left">

              {/* TOOLBAR */}
              <ForumToolBar />

              {/* POST FORM */}
              {formVisible &&
                <div className="forum-post-form-container"
                  style={{
                    borderTopColor: theme.backgroundColorFaded,
                    borderBottomColor: theme.backgroundColorFaded
                  }}>
                  <ForumPostForm
                    onPost={handlePost}
                  />
                </div>
              }

              {/* POSTS */}
              <ForumPosts />

              {/* ADD BUTTON */}
              {addButtonVisible && (
                <div className="forum-add-button">
                  <AddButton onClick={handleAddClick} />
                </div>
              )}

            </div>

          </>
        )}

        {(isMobile || selectedForumPost) &&
          <>
            {/* RIGHT */}
            <div className={isMobile ? "forum-right-mobile" : "forum-right"}
              style={{
                borderLeftColor: theme.backgroundColorFaded
              }}>
              <ForumPost />
            </div>
          </>
        }

      </div>

    </>
  );
};

export default Forum;
