import React from 'react';

// Styles
import './Divider.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * Divider Component
 * 
 * This component renders a divider.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Divider = () => {
  
  // Theme
  const { theme } = useTheme();

  return (
    <>
      {/* CONTAINER */}
      <div className="divider-container"
        style={{
          borderBottomColor: theme.backgroundColorFaded
        }}></div>
    </>
  );
};

export default Divider;

