import React from 'react';

// Styles
import './SaveDesignButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * SaveDesignButton Component
 * 
 * This component renders a save button in the designer.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const SaveDesignButton = ({ onClick }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <div className="save-design-button-container"
    style={{
        backgroundColor: theme.highlightBackgroundColor,
        color: theme.highlightForegroundColor
    }}
    onClick={onClick}>
    Save Changes
</div>
  );

};

export default SaveDesignButton;
