import React from 'react';

const TextIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 32 32">
        <path d="M3 6H21M3 12H13M3 18H18" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>

);

export default TextIcon;
