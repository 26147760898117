import React from 'react';

// Styles
import './DayPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const DayPreview = ({ 
    placeholder = '', 
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="day-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
            <div 
                className="day-preview-label"
                style={{color: theme.foregroundColorFaded}}>
                {placeholder}
            </div>
            <select
                className="day-preview-input"
                style={{color: theme.foregroundColor}}
                readOnly
                tabIndex={-1}>
                <option value="" disabled>Wednesday</option>
            </select>
        </div>
    );
};

export default DayPreview;
