import React from 'react';

const FeatureIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24" fill="none">
        <rect x="3" y="3" width="7" height="7" rx="1" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <rect x="3" y="14" width="7" height="7" rx="1" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <rect x="14" y="3" width="7" height="7" rx="1" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M17.5 14.0002L17.5 21.0004" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M21.0001 17.5001L14 17.5001" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default FeatureIcon;
