import React, { useState } from 'react';

// Styles
import './ChannelPrivacy.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Switch from '../../../components/switch/Switch';

/**
 * ChannelPrivacy Component
 * 
 * This component allows the user to select the privacy of a channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelPrivacy = ({ onChange, defaultValue = false }) => {
    const { theme } = useTheme();

    // State Variables
    const [isPrivate, setPrivate] = useState(defaultValue);

    /**
     * Handles toggle of the private setting.
     */
    const handleTogglePrivate = () => {
        const newValue = !isPrivate;
        setPrivate(newValue);  // Update local state
        onChange(newValue);  // Pass new value back to parent
    };

    return (
        <>
            {/* TOP SECTION */}
            <div className="channel-privacy-section-top">

                {/* LABEL */}
                <div className="channel-privacy-label"
                    style={{ color: theme.foregroundColor }}>
                    Private (Invite Only)
                </div>

                {/* SWITCH */}
                <Switch
                    initialChecked={isPrivate}
                    onChange={handleTogglePrivate}
                />

            </div>

            {/* BOTTOM SECTION */}
            <div className="channel-privacy-section-bottom"
                style={{ color: theme.foregroundColorFaded }}>
                {isPrivate ?
                    "Only selected app members can participate." :
                    "All app members can participate."
                }
            </div>
        </>
    );
};

export default ChannelPrivacy;
