import React from 'react';

// Styles
import './AppUserPhoto.css';

const AppUserPhoto = ({ user }) => {

    return (
        <div className="app-user-photo-container">
            <div className="app-user-photo-wrapper">
                {user && user.photo &&
                    <img
                        src={user.photo}
                        className="app-user-photo"
                        alt="User"
                    />
                }
            </div>
        </div>
    );
};

export default AppUserPhoto;

