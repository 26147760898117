
class ResizeHelper {

    /**
     * Method to handle resizing of an element on the canvas.
     * 
     * @param {event} e - Drag event.
     * @param {object} element - Element that is being dragged
     * @param {string} direction - Direction of the resize handle:
     * - "top-left"
     * - "top-right"
     * - "bottom-left"
     * - "bottom-right"
     * @param {function} setElements - Function to update the elements array
     */
    handleResize(e, element, direction, setElements) {

        // Stop propagation
        e.preventDefault();
        e.stopPropagation();

        // Get the canvas container and its dimensions
        const container = document.querySelector('.canvas-container');
        const containerRect = container.getBoundingClientRect();

        const startX = e.clientX - containerRect.left;
        const startY = e.clientY - containerRect.top;

        // Initial dimensions and positions
        const startWidth = element.styles.width.includes('%')
            ? (parseFloat(element.styles.width) / 100) * containerRect.width
            : parseInt(element.styles.width || containerRect.width, 10);

        const startHeight = element.styles.height.includes('%')
            ? (parseFloat(element.styles.height) / 100) * containerRect.height
            : parseInt(element.styles.height || containerRect.height, 10);

        const startLeft = parseInt(element.styles.left || 0, 10);
        const startTop = parseInt(element.styles.top || 0, 10);

        let newWidth = startWidth;
        let newHeight = startHeight;
        let newLeft = startLeft;
        let newTop = startTop;

        // Helper function to update the element's styles
        const updateElement = () => {
            const updatedElement = {
                ...element,
                styles: {
                    ...element.styles,
                    width: `${newWidth}px`,
                    height: `${newHeight}px`,
                    left: `${newLeft}px`,
                    top: `${newTop}px`
                }
            };

            // Update the elements array
            setElements(prevElements =>
                prevElements.map(el => el.key === element.key ? updatedElement : el)
            );
        };

        const onMouseMove = (moveEvent) => {
            const dx = moveEvent.clientX - containerRect.left - startX;
            const dy = moveEvent.clientY - containerRect.top - startY;

            // Update width and left based on direction
            if (direction.includes('right')) newWidth = startWidth + dx;
            if (direction.includes('left')) {
                newWidth = startWidth - dx;
                newLeft = startLeft + dx;
            }

            // Update height and top based on direction
            if (direction.includes('bottom')) newHeight = startHeight + dy;
            if (direction.includes('top')) {
                newHeight = startHeight - dy;
                newTop = startTop + dy;
            }

            // Only update if both dimensions are positive
            if (newWidth > 0 && newHeight > 0) updateElement();
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        // Add mouse event listeners
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }
}

export default ResizeHelper;
