import React from 'react';

// Styles
import './ChatPostFormPhotos.css';

// Components
import ChatPostFormPhoto from './ChatPostFormPhoto';

const ChatPostFormPhotos = ({ urls }) => {

  return (
    <>
      {urls && urls.length > 0 &&
        <div className="chat-post-form-photos-container">
          {urls.map((url, index) => (
            <ChatPostFormPhoto 
              index={index}
              url={url}
            />
          ))}
        </div>
      }
    </>
  );
};

export default ChatPostFormPhotos;
