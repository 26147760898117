import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ChatUsers.css';

// Components
import ChatUser from './ChatUser';

const ChatUsers = () => {
  const {
    appUsers
  } = useContext(Global);

  return (
    <div className="chat-users-container">

      {appUsers.map((appUser) => (
        <ChatUser
          key={appUser.key}
          appUser={appUser}
        />
      ))}

    </div>
  );
};

export default ChatUsers;
