import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Activity
import { activity } from '../../../managers/ActivityManager';

// Styles
import './ForumPostForm.css';

// Images
import UserDot from '../../../components/userdot/UserDot';

// Components
import ForumPostFormButton from './ForumPostFormButton';
import ForumPostFormContent from './ForumPostFormContent';
import ForumPostFormDocumentButton from './ForumPostFormDocumentButton';
import ForumPostFormDocuments from './ForumPostFormDocuments';
import ForumPostFormTitle from './ForumPostFormTitle';
import ForumPostFormPhotoButton from './ForumPostFormPhotoButton';
import ForumPostFormPhotos from './ForumPostFormPhotos';
import ForumPostFormObjectButton from './ForumPostFormObjectButton';
import ForumPostFormObjects from './ForumPostFormObjects';
import ForumPostFormVideoButton from './ForumPostFormVideoButton';
import ForumPostFormVideos from './ForumPostFormVideos';

// Managers
import ObjectManager from '../../../managers/ObjectManager';
import ForumPostManager from '../../../managers/ForumPostManager';

const objectManager = new ObjectManager();
const forumPostManager = new ForumPostManager();

/**
 * ForumPostForm Component
 * 
 * This component renders form for creating channel posts.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ForumPostForm = ({ onPost }) => {
    const {
        currentUser,
        hideProgress,
        selectedApp,
        selectedChannel,
        showProgress
    } = useContext(Global);

    // State Variables
    const [documentFiles, setDocumentFiles] = useState([]);
    const [isTitleFocused, setIsTitleFocused] = useState(true);
    const [localPhotos, setLocalPhotos] = useState([]);
    const [localVideos, setLocalVideos] = useState([]);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [videoUrls, setVideoUrls] = useState([]);
    const [objects, setObjects] = useState([]);

    // Event Handlers
    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleTitleFocus = () => setIsTitleFocused(true);
    const handleContentChange = (e) => setContent(e.target.value);

    /**
     * Method to handle posting to the selected channel.
     * 
     * @param {string} e - Click event.
     */
    const handlePost = async () => {

        // Display a message to the user
        showProgress('Sending post...');

        const uploadedPhotoUrls = [];
        const uploadedVideoUrls = [];
        const uploadedDocumentUrls = [];
        let totalBytes = 0; // Initialize totalBytes to accumulate file sizes

        // Upload photos
        for (const file of localPhotos) {
            const key = generateKey();
            const storageRef = ref(storage, `images/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedPhotoUrls.push(url);

            // Accumulate file size
            totalBytes += file.size;
        }

        // Upload videos
        for (const file of localVideos) {
            const key = generateKey();
            const storageRef = ref(storage, `videos/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedVideoUrls.push(url);

            // Accumulate file size
            totalBytes += file.size;
        }

        // Upload documents
        for (const { file } of documentFiles) {
            const key = generateKey();
            const storageRef = ref(storage, `files/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedDocumentUrls.push({ url, name: file.name });

            // Accumulate file size
            totalBytes += file.size;
        }

        // Log the total bytes of uploaded files
        activity.log(selectedApp.key, 'uploads', totalBytes);

        hideProgress();

        forumPostManager.add(
            selectedApp.key,
            selectedChannel.key,
            currentUser.key,
            currentUser.username,
            title,
            content,
            uploadedPhotoUrls,
            uploadedVideoUrls,
            uploadedDocumentUrls,
            objects
        );

        // Call the onPost event
        onPost();

        setTitle("");
        setContent("");
        setPhotoUrls([]);
        setLocalPhotos([]);
        setVideoUrls([]);
        setLocalVideos([]);
        setDocumentFiles([]);
        setObjects([]);
        setIsTitleFocused(false);
    };

    const handleSearch = async (entry) => {
        const object = await objectManager.fetch(selectedApp.key, entry.modelKey, entry.objectKey);
        setObjects(prev => [...prev, object]);
    };

    return (
        <>
            {/* INPUT AREA */}
            <div className="forum-post-form-wrapper">
                <div className="forum-post-form-left">
                    <UserDot
                        letter={currentUser.username.toUpperCase()[0]}
                        size="36"
                    />
                </div>
                <div className="forum-post-form-center">

                    {/* TITLE */}
                    <ForumPostFormTitle
                        title={title}
                        onFocus={handleTitleFocus}
                        onChange={handleTitleChange}
                    />

                    {/* CONTENT */}
                    <ForumPostFormContent
                        content={content}
                        onChange={handleContentChange}
                    />
                </div>
                {isTitleFocused && (
                    <div className="forum-post-form-right">
                        <ForumPostFormButton onClick={handlePost} />
                    </div>
                )}
            </div>

            {/* ATTACHMENT BUTTONS */}
            <div className={`forum-post-form-attachments ${isTitleFocused ? 'visible' : 'hidden'}`}>

                {/* ATTACH PHOTOS */}
                <ForumPostFormPhotoButton
                    localPhotos={localPhotos}
                    setLocalPhotos={setLocalPhotos}
                    photoUrls={photoUrls}
                    setPhotoUrls={setPhotoUrls}
                />

                {/* ATTACH VIDEOS */}
                <ForumPostFormVideoButton
                    localVideos={localVideos}
                    setLocalVideos={setLocalVideos}
                    videoUrls={videoUrls}
                    setVideoUrls={setVideoUrls}
                />

                {/* ATTACH DOCUMENTS */}
                <ForumPostFormDocumentButton
                    documentFiles={documentFiles}
                    setDocumentFiles={setDocumentFiles}
                />

                {/* ATTACH OBJECTS */}
                <ForumPostFormObjectButton
                    onSearch={handleSearch}
                />

            </div>

            {/* PHOTOS */}
            <ForumPostFormPhotos
                urls={photoUrls}
            />

            {/* VIDEOS */}
            <ForumPostFormVideos
                urls={videoUrls}
            />

            {/* DOCUMENTS */}
            <ForumPostFormDocuments
                files={documentFiles}
            />

            {/* OBJECTS */}
            <ForumPostFormObjects
                objects={objects}
            />
        </>
    );
};

export default ForumPostForm;
