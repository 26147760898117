import React, { useEffect, useRef, useState } from 'react';

// Styles
import './Password.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import EyeIcon from '../../../../common/svg/EyeIcon';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const Password = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true
}) => {
    const { theme } = useTheme();

    const [value, setValue] = useState(''); // Default ADD mode value
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    // Focus the input when the container is clicked
    const handleContainerClick = (e) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleBlur = () => {
        onUpdate(field, value);
    };

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    // Toggle the visibility state
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    showFieldMenu={showFieldMenu}
                />

                {/* INPUT */}
                <div className="password-wrapper">
                    <input
                        type={isPasswordVisible ? 'text' : 'password'} // Toggle input type based on visibility state
                        ref={inputRef}
                        className="password-input"
                        style={{ color: theme.foregroundColor }}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <div
                        className="password-icon"
                        onClick={togglePasswordVisibility}>
                        <EyeIcon
                            color={theme.foregroundColor}
                            width="20"
                            height="20"
                        />
                    </div>
                </div>

            </FieldContainer>
        </>
    );
};

export default Password;
