import React from 'react';

// Styles
import './ChannelAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import NewIcon from '../../../svg/NewIcon';

/**
 * ChannelAddButton Component
 * 
 * This component renders a button for adding a channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelAddButton = ({ onClick }) => {
    const { theme } = useTheme();

    return (
        <>
            {/* CONTAINER */}
            <div className="channel-add-button-container"
                onClick={onClick}>

                {/* ICON */}
                <div className="channel-add-button-image">
                    <NewIcon
                        color={theme.foregroundColor}
                        width="18"
                        height="14"
                    />
                </div>

                {/* TEXT */}
                <div className="channel-add-button-text"
                    style={{color: theme.foregroundColor}}>
                    Add Channel
                </div>
                
            </div>
        </>
    );
};

export default ChannelAddButton;
