import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumPostFormTitle.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ForumPostFormTitle = ({ title, onFocus, onChange }) => {
  const { theme } = useTheme();
  const {
      selectedApp
  } = useContext(Global);

  // References
  const textareaRef = useRef(null);

  /** 
   * Sets the height of the textarea.
   */
  useEffect(() => {
      if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

          if (textareaRef.current.scrollHeight > 200) {
              textareaRef.current.style.height = '200px';
          }
      }
  }, [title]);

  return (
    <>
      <textarea
        name="post"
        className="forum-post-form-title"
        placeholder="Title..."
        style={{
          backgroundColor: theme.backgroundColor,
          borderColor: theme.backgroundColor,
          color: theme.foregroundColor,
          fontFamily: selectedApp.fontFamily,
          maxHeight: '200px' // Set max height
        }}
        onFocus={onFocus}
        onChange={onChange}
        value={title}
        rows={1}
        ref={textareaRef}
      />
    </>
  );
};

export default ForumPostFormTitle;
