import React from 'react';

const DownIcon = ({ color = "white", width = "16", height = "21" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 16 21"
  >
    <path d="M15.65 14.7587L8.64999 20.7587C8.59886 20.7925 8.54539 20.8226 8.48999 20.8487L8.36999 20.9287C8.1324 21.0233 7.86758 21.0233 7.62999 20.9287L7.50999 20.8487C7.45459 20.8226 7.40112 20.7925 7.34999 20.7587L0.349991 14.7587C0.148427 14.5863 0.0235997 14.3409 0.00297071 14.0765C-0.0176583 13.8121 0.067601 13.5503 0.239992 13.3487C0.412382 13.1472 0.657783 13.0223 0.92221 13.0017C1.18664 12.9811 1.44843 13.0663 1.64999 13.2387L6.99999 17.8287V1.99872C6.99999 1.7335 7.10535 1.47915 7.29288 1.29161C7.48042 1.10408 7.73478 0.998718 7.99999 0.998718C8.26521 0.998718 8.51956 1.10408 8.7071 1.29161C8.89463 1.47915 8.99999 1.7335 8.99999 1.99872V17.8287L14.35 13.2387C14.5516 13.0663 14.8133 12.9811 15.0778 13.0017C15.3422 13.0223 15.5876 13.1472 15.76 13.3487C15.9324 13.5503 16.0176 13.8121 15.997 14.0765C15.9764 14.3409 15.8516 14.5863 15.65 14.7587Z"
      fill={color} />
  </svg>
);

export default DownIcon;
