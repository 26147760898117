import React from 'react';

const PencilIcon = ({ color = "white", width = "24", height = "24" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path d="M17.6223 4.88789C18.2528 5.38768 18.6839 6.04497 18.8346 6.74006C19.0029 7.516 18.8169 8.30349 18.312 8.95448L9.70121 20.0591C9.26594 20.6208 8.61148 20.9484 7.90404 20.9579L4.58187 20.9999H4.57252C4.20997 20.9999 3.89521 20.7479 3.81314 20.3909L3.06103 17.1213C2.90105 16.4251 3.05895 15.7038 3.49526 15.142L4.6255 13.6836C4.8904 13.3413 5.38073 13.2804 5.71938 13.5503C6.05804 13.818 6.11726 14.3136 5.85132 14.6559L4.72107 16.1143C4.57771 16.2991 4.52577 16.5354 4.57875 16.7643L5.18854 19.4165L7.8843 19.3829C8.11596 19.3798 8.33099 19.2727 8.47435 19.0879L17.0852 7.98324C17.3054 7.69975 17.3812 7.39525 17.3127 7.07606C17.2389 6.73901 17.0083 6.40197 16.6613 6.12792L15.2174 4.98239C14.6585 4.54139 13.87 4.3146 13.3319 5.01074L12.1944 6.47652L13.9999 7.94019C14.3354 8.21109 14.3894 8.70773 14.1204 9.04583C13.9666 9.24112 13.7402 9.34192 13.5116 9.34192C13.3413 9.34192 13.1688 9.28522 13.0254 9.16867L11.2335 7.71655L8.28736 11.5164C8.02142 11.8587 7.53214 11.9207 7.19244 11.6498C6.85482 11.382 6.79561 10.8864 7.06051 10.5442L12.1051 4.03845C13.0836 2.77427 14.7977 2.65037 16.1783 3.74341L17.6223 4.88789ZM13.5963 19.425H20.2209C20.651 19.425 21 19.7778 21 20.2125C21 20.6472 20.651 21 20.2209 21H13.5963C13.1652 21 12.8172 20.6472 12.8172 20.2125C12.8172 19.7778 13.1652 19.425 13.5963 19.425Z"
      fill={color} />
  </svg>
);

export default PencilIcon;
