import React from 'react';

const ExpandLessIcon = ({ color = "white", width = "12", height = "7" }) => (
    <svg 
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 12 7"
    >
     <g id="Icons" stroke="none" strokeWidth="1" fill="none">
        <g id="Rounded" transform="translate(-248.000000, -3484.000000)">
            <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                <g id="-Round-/-Navigation-/-expand_less" transform="translate(142.000000, 98.000000)">
                    <g>
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M11.29,8.71 L6.7,13.3 C6.31,13.69 6.31,14.32 6.7,14.71 C7.09,15.1 7.72,15.1 8.11,14.71 L12,10.83 L15.88,14.71 C16.27,15.1 16.9,15.1 17.29,14.71 C17.68,14.32 17.68,13.69 17.29,13.3 L12.7,8.71 C12.32,8.32 11.68,8.32 11.29,8.71 Z" id="🔹-Icon-Color" 
                            fill={color}></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ExpandLessIcon;
