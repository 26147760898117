import React from 'react';

const DeleteIcon = ({ color = "white", width = "20", height = "21" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 20 21" fill="none">
        <path d="M17 0.998718H3C2.20435 0.998718 1.44129 1.31479 0.87868 1.8774C0.316071 2.44001 0 3.20307 0 3.99872V5.99872C0 6.26393 0.105357 6.51829 0.292893 6.70582C0.48043 6.89336 0.734784 6.99872 1 6.99872H2V17.9987C2 18.7944 2.31607 19.5574 2.87868 20.12C3.44129 20.6826 4.20435 20.9987 5 20.9987H15C15.7956 20.9987 16.5587 20.6826 17.1213 20.12C17.6839 19.5574 18 18.7944 18 17.9987V6.99872H19C19.2652 6.99872 19.5196 6.89336 19.7071 6.70582C19.8946 6.51829 20 6.26393 20 5.99872V3.99872C20 3.20307 19.6839 2.44001 19.1213 1.8774C18.5587 1.31479 17.7956 0.998718 17 0.998718ZM16 17.9987C16 18.2639 15.8946 18.5183 15.7071 18.7058C15.5196 18.8934 15.2652 18.9987 15 18.9987H5C4.73478 18.9987 4.48043 18.8934 4.29289 18.7058C4.10536 18.5183 4 18.2639 4 17.9987V6.99872H16V17.9987ZM18 4.99872H2V3.99872C2 3.7335 2.10536 3.47915 2.29289 3.29161C2.48043 3.10408 2.73478 2.99872 3 2.99872H17C17.2652 2.99872 17.5196 3.10408 17.7071 3.29161C17.8946 3.47915 18 3.7335 18 3.99872V4.99872ZM6 15.9987V9.99872C6 9.7335 6.10536 9.47915 6.29289 9.29161C6.48043 9.10408 6.73478 8.99872 7 8.99872C7.26522 8.99872 7.51957 9.10408 7.70711 9.29161C7.89464 9.47915 8 9.7335 8 9.99872V15.9987C8 16.2639 7.89464 16.5183 7.70711 16.7058C7.51957 16.8934 7.26522 16.9987 7 16.9987C6.73478 16.9987 6.48043 16.8934 6.29289 16.7058C6.10536 16.5183 6 16.2639 6 15.9987ZM12 15.9987V9.99872C12 9.7335 12.1054 9.47915 12.2929 9.29161C12.4804 9.10408 12.7348 8.99872 13 8.99872C13.2652 8.99872 13.5196 9.10408 13.7071 9.29161C13.8946 9.47915 14 9.7335 14 9.99872V15.9987C14 16.2639 13.8946 16.5183 13.7071 16.7058C13.5196 16.8934 13.2652 16.9987 13 16.9987C12.7348 16.9987 12.4804 16.8934 12.2929 16.7058C12.1054 16.5183 12 16.2639 12 15.9987Z"
            fill={color} />
    </svg>
);

export default DeleteIcon;
