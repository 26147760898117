import React, { useRef } from 'react';

// Styles
import './ChatPostFormDocumentButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import DocumentIcon from '../../../../../svg/DocumentIcon';

const ChatPostFormDocumentButton = ({ documentFiles, setDocumentFiles }) => {
  const { theme } = useTheme();

  // References
  const documentInputRef = useRef(null);

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    const newDocumentFiles = files.map(file => ({
        file,
        url: URL.createObjectURL(file)
    }));
    setDocumentFiles([...documentFiles, ...newDocumentFiles]);
    documentInputRef.current.value = "";
};

  return (
    <>
      {/* CONTAINER */}
      <div className="chat-post-form-document-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => documentInputRef.current.click()}>

        {/* ICON */}
        <DocumentIcon
          color={theme.foregroundColor}
          width="15"
          height="15"
        />

        {/* INPUT */}
        <input
          ref={documentInputRef}
          id="document-upload"
          type="file"
          accept=".pdf,.doc,.docx,.txt,.ppt,.pptx,.xls,.xlsx,.csv,.rtf,.html,.zip"
          onChange={handleDocumentUpload}
          style={{ display: 'none' }}
          multiple
        />

      </div>
    </>
  );
};

export default ChatPostFormDocumentButton;
