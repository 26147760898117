/**
 * Resizes a textarea to fit its content.
 * 
 * @param {string} ref - Reference to the textarea.
 */
export function adjustTextareaHeight(ref) {
  if (ref.current) {
    ref.current.style.height = 'auto'; // Reset height to auto to calculate the new height
    ref.current.style.height = `${ref.current.scrollHeight}px`; // Set new height based on scrollHeight
  }
};