import React from 'react';

const CreatedIcon = ({ color = "white", width = "48", height = "48" }) => (
  <svg 
  version="1.1"
  width={width}
  height={height}
  viewBox="0 0 48 48">
  <g>
    <g>
      <rect width="48" height="48" fill="none"/>
    </g>
    <g>
      <path fill={color} d="M2,24a22,22,0,0,0,41.7,9.7,2,2,0,0,0-.5-2.6,2,2,0,0,0-3,.7A17.8,17.8,0,0,1,24,42,18,18,0,1,1,37.4,12H32.1A2.1,2.1,0,0,0,30,13.7,2,2,0,0,0,32,16H42a2,2,0,0,0,2-2V4.1A2.1,2.1,0,0,0,42.3,2,2,2,0,0,0,40,4V8.9A22,22,0,0,0,2,24Z"/>
      <path fill={color} d="M34,32a1.7,1.7,0,0,1-1-.3L22,25.1V14a2,2,0,0,1,4,0v8.9l9,5.4a1.9,1.9,0,0,1,.7,2.7A1.9,1.9,0,0,1,34,32Z"/>
    </g>
  </g>
</svg>

);

export default CreatedIcon;
