import React from 'react';

const DotIcon = ({ color = "white", width = "16", height = "16" }) => (
  <svg version="1.1"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
    <circle fill={color} cx="8" cy="8" r="4"/>
</svg>

);

export default DotIcon;
