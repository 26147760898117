// Firebase
import { listAll, deleteObject } from 'firebase/storage';

class StorageManager {

    /**
     * Deletes all files and folders inside the specified path.
     *
     * @param {string} folderRef - Reference to the folder storage path.
     */
    async deleteFolderContents(folderRef) {
        try {
            const folderContents = await listAll(folderRef);

            const deletePromises = folderContents.items.map((itemRef) => {
                // Delete each file
                return deleteObject(itemRef);
            });

            const folderDeletePromises = folderContents.prefixes.map((subFolderRef) => {
                // Recursively delete each subfolder
                return this.deleteFolderContents(subFolderRef);
            });

            await Promise.all([...deletePromises, ...folderDeletePromises]);
        } catch (error) {
            console.error("Error deleting folder contents: ", error);
        }
    }

}

export default StorageManager;
