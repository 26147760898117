import React from 'react';

const SelectIcon = ({ color = "white", width = "16", height = "16" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 16 16">
        <path fill={color} d="M2 5.56L2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z" />
    </svg>
);

export default SelectIcon;
