import React from 'react';

const MenuIcon = ({ color = "white", width = "16", height = "16" }) => (

<svg
    version="1.1" 
    width={width} 
    height={height} 
    viewBox="0 0 16 16"
>
    <path d="M2 3h12a1 1 0 0 1 0 2H2a1 1 0 1 1 0-2zm0 4h12a1 1 0 0 1 0 2H2a1 1 0 1 1 0-2zm0 4h12a1 1 0 0 1 0 2H2a1 1 0 0 1 0-2z" id="a"
    fill={color}/>
</svg>
);

export default MenuIcon;