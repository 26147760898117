import React from 'react';

const ForwardIcon = ({ color = "white", width = "11", height = "20" }) => (
    <svg 
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 11 20"
    >
    <g id="Icons" stroke="none" fill="none">
        <g id="Rounded" transform="translate(-345.000000, -3434.000000)">
            <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                <g id="-Round-/-Navigation-/-arrow_forward_ios" transform="translate(238.000000, 54.000000)">
                    <g>
                        <polygon id="Path" opacity="0.87" points="24 24 0 24 0 0 24 0"></polygon>
                        <path d="M7.38,21.01 C7.87,21.5 8.66,21.5 9.15,21.01 L17.46,12.7 C17.85,12.31 17.85,11.68 17.46,11.29 L9.15,2.98 C8.66,2.49 7.87,2.49 7.38,2.98 C6.89,3.47 6.89,4.26 7.38,4.75 L14.62,12 L7.37,19.25 C6.89,19.73 6.89,20.53 7.38,21.01 Z" 
                            fill={color}></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ForwardIcon;
