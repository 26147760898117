import React from 'react';

// Styles
import './ChatPostFormPhoto.css';

const ChatPostFormPhoto = ({ index, url }) => {

  return (
    <img key={index} src={url} alt="attachment" className="chat-post-form-photo" />
  );
};

export default ChatPostFormPhoto;
