import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppItem.css';

const AppItem = ({ app }) => {
    const {
        resetVisibility,
        setMenuVisible,
        setPage,
        setRunHomeVisible,
        setSelectedApp
    } = useContext(Global);

    const handleAppClick = async (app) => {

        resetVisibility();

        setSelectedApp(app);
        setRunHomeVisible(true);
        setPage('RUN');

        if (isMobile) {
            setMenuVisible(true);
        }
    };

    /**
     * Function to load only the regular variant of a font.
     * 
     * @param {string} fontName - Name of the font.
     */
    const loadGoogleFont = (fontName) => {
      const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    };
  
    useEffect(() => {
      if (!app) return;
      loadGoogleFont(app.fontFamily || 'Red Hat Display');
    }, [app]);

    return (

        <div
            key={app.key}
            className="app-item-container"
            style={{
                backgroundColor: app.themes.dark.backgroundColor,
                borderColor: app.themes.dark.backgroundColorFaded
            }}
            onClick={() => handleAppClick(app)}
        >
            <div className="app-item-wrapper">
                <div className="app-item-top">
                    <div className="app-item-left">
                        {app.logoDark &&
                            <img src={app.logoDark} alt="App Logo" className="app-item-logo" />
                        }
                        <div className="app-item-title-version">
                            <div className="app-item-title"
                                style={{ 
                                    color: app.themes.dark.foregroundColor,
                                    fontFamily: app.fontFamily
                                }}>
                                {app.title}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-item-description"
                    style={{ 
                        color: app.themes.dark.foregroundColorFaded,
                        fontFamily: app.fontFamily
                    }}>
                    {app.description}
                </div>
                <div style={{flexGrow: 1}}></div>
            </div>
        </div>

    );
};

export default AppItem;
