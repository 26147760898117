import React, { useRef, useState, useEffect } from 'react';
import Peer from 'simple-peer';
import { ref, push, onChildAdded, remove } from 'firebase/database'; // Firebase Realtime Database methods
import { realTimeDb } from './firebaseConfig'; // Import your Firebase Realtime Database config

const VideoChat = () => {
  const [stream, setStream] = useState(null);
  const [isOfferProcessed, setIsOfferProcessed] = useState(false);
  const [isAnswerProcessed, setIsAnswerProcessed] = useState(false);
  const [pendingOffer, setPendingOffer] = useState(null); // Store the offer for manual answering
  const [logs, setLogs] = useState([]); // State to store logs for display
  const [isCaller, setIsCaller] = useState(false);
  const [remoteStream, setRemoteStream] = useState(null);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const candidateQueue = useRef([]);

  const peerRef = useRef(null); // Use a ref to store the peer object

  // Clears all previous signals when the component mounts
  useEffect(() => {
    clearSignals();
  }, []);

  useEffect(() => {
    // Define video constraints for 640x480 resolution
    const constraints = {
      video: {
        width: { ideal: 640 },  // Exact width of 640px
        height: { ideal: 480 },  // Exact height of 480px
      },
      audio: true  // Keep audio enabled
    };

    // Access media devices (camera & mic) with specific constraints
    navigator.mediaDevices.getUserMedia(constraints)
      .then(mediaStream => {
        setStream(mediaStream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = mediaStream;
        }
        addLog("Local stream set at 640x480 resolution");
      })
      .catch(err => {
        addLog("Error accessing media devices: " + err.message);
      });

    // Start listening for signals
    listenForSignalsFromFirebase();
  }, []);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
      addLog("Remote stream set after component update");
    }
  }, [remoteVideoRef, remoteStream]); // Trigger when ref or stream changes

  // Method to clear all previous signals from Firebase
  const clearSignals = async () => {
    addLog("Clearing all previous signals");
    const signalsRef = ref(realTimeDb, 'signals');
    await remove(signalsRef);
  };

  // Method to add a log message
  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  // Method to create a new peer
  const createPeer = (initiator) => {
    return new Peer({
      initiator: initiator,
      stream,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },  // Google STUN server
          {
            urls: "turn:turn.anyfirewall.com:443?transport=tcp",
            username: "webrtc",
            credential: "webrtc"
          }
        ]
      }
    });
    /*
    return new Peer({
      initiator: initiator,
      stream,  // Use the local stream
      config: {
        iceServers: [
          {
            urls: ["stun:ws-turn4.xirsys.com"]
          },
          {
            username: "8eCE3s05SMLlFPz9k4ZsQ-3yY-rfxA4pmQmWDSY9mswyDg4vmLSyVhAIpUgshbFrAAAAAGb4q3prZXZpbm5lc3NsYW5k",
            credential: "154e9668-7e01-11ef-97ef-0242ac140004",
            urls: [
              "turn:ws-turn4.xirsys.com:80?transport=udp",
              "turn:ws-turn4.xirsys.com:3478?transport=udp",
              "turn:ws-turn4.xirsys.com:80?transport=tcp",
              "turn:ws-turn4.xirsys.com:3478?transport=tcp",
              "turns:ws-turn4.xirsys.com:443?transport=tcp",
              "turns:ws-turn4.xirsys.com:5349?transport=tcp"
            ]
          }
        ]
      }
    });
    */
  };

  // Method to listen for signals from Firebase
  const listenForSignalsFromFirebase = () => {
    const signalsRef = ref(realTimeDb, 'signals');
    onChildAdded(signalsRef, (snapshot) => {
      const signalData = snapshot.val();
      if (!signalData) {
        return;
      }

      //addLog(`Received signal of type: ${signalData.type}`);

      // Handle offer signal (callee - desktop)
      if (signalData.type === 'offer' && !isOfferProcessed && !isCaller) {
        addLog("Received offer signal on receiver");
        setPendingOffer(signalData);  // Store the offer for manual answering
      }

      // Handle answer signal (caller - phone)
      else if (signalData.type === 'answer' && !isAnswerProcessed && isCaller) {
        addLog("Received answer signal on caller");

        // Make sure peer exists and is initialized
        if (peerRef.current) {
          peerRef.current.signal(signalData);  // Process the answer signal immediately with the same peer
          setIsAnswerProcessed(true);

          // Process any queued ICE candidates after the answer is handled
          candidateQueue.current.forEach(candidate => {
            addLog("Processing queued ICE candidate after answer.");
            peerRef.current.signal(candidate);  // Process the queued ICE candidates
          });
          candidateQueue.current.length = 0;  // Clear the queue after processing
        } else {
          addLog("Peer is not initialized yet, cannot process answer.");
        }
      }

      // Handle ICE candidates (both caller and callee)
      else if (signalData.candidate) {
        addLog("Received ICE candidate signal");
        if (peerRef.current) {
          peerRef.current.signal(signalData);  // Process ICE candidate if peer is ready
        } else {
          addLog("Peer is not initialized yet, queuing ICE candidate.");
          candidateQueue.current.push(signalData);  // Queue ICE candidates until peer is ready
        }
      }
    });
  };

  // Method to start the peer connection as the caller
  const startPeerConnection = () => {
    setIsCaller(true);  // Mark this device as the caller
    addLog("Starting peer connection as caller");

    if (!stream) {
      addLog("Stream not set yet, cannot start peer connection.");
      return;
    }

    // Create a new peer
    const newPeer = createPeer(true); // Caller initiates the connection

    // Handle the signal (offer/answer/ICE candidates)
    newPeer.on('signal', data => {
      addLog("Peer signal: " + data.type);
      sendSignalToFirebase(data); // Send the offer signal to Firebase
    });

    // Handle incoming remote media stream
    newPeer.on('stream', stream => {
      setRemoteStream(stream);  // Store the stream in state
      addLog("Received remote stream");
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = stream;
        addLog("Remote video stream set successfully on remoteVideoRef");
      } else {
        addLog("Failed to attach remote stream to remoteVideoRef. remoteVideoRef is null or undefined.");
      }
    });

    // Handle ICE state changes
    newPeer.on('iceStateChange', () => {
      const currentState = newPeer._pc.iceConnectionState;
      addLog("ICE connection state changed to: " + currentState);

      if (currentState === 'connected') {
        addLog("ICE connection established, peer-to-peer connection is ready.");

        // Process queued ICE candidates after peer is connected
        candidateQueue.current.forEach(candidate => {
          addLog("Processing queued ICE candidate.");
          newPeer.signal(candidate);  // Process the queued ICE candidates
        });
        candidateQueue.current.length = 0;  // Clear the queue
      } else if (currentState === 'disconnected') {
        addLog("ICE connection disconnected. Trying to reconnect...");
        // Optional: attempt to reconnect or handle the disconnection
      } else if (currentState === 'failed') {
        addLog("ICE connection failed. TURN server might be required.");
      }
    });

    peerRef.current = newPeer;  // Store the peer in ref
  };

  // Method to answer the call on the receiver side (manually triggered)
  const answerCall = () => {
    addLog("Answering the call");

    if (!pendingOffer) {
      addLog("No offer to answer.");
      return;
    }

    if (!stream) {
      addLog("Stream not set yet, cannot answer the call.");
      return;
    }

    const newPeer = createPeer(false); // Receiver does not initiate the connection

    newPeer.on('signal', data => {
      addLog("Peer signal: " + data.type);
      sendSignalToFirebase(data); // Send the answer signal to Firebase
      if (data.type === 'answer') {
        addLog("Answer signal sent to Firebase: " + JSON.stringify(data));
      }
    });

    newPeer.on('stream', remoteStream => {
      addLog("Received remote stream");

      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = remoteStream;
        addLog("Remote video stream set successfully on remoteVideoRef");
      } else {
        addLog("Failed to attach remote stream to remoteVideoRef. remoteVideoRef is null or undefined.");
      }
    });

    newPeer.on('iceStateChange', () => {
      const currentState = newPeer._pc.iceConnectionState;
      addLog("ICE connection state changed to: " + currentState);

      if (currentState === 'connected') {
        addLog("ICE connection established, peer-to-peer connection is ready.");
      } else if (currentState === 'failed') {
        addLog("ICE connection failed. Possible network/NAT issue.");
      } else if (currentState === 'disconnected') {
        addLog("ICE connection disconnected.");
      }
    });

    newPeer.on('error', err => {
      addLog("Peer connection error: " + err.message);
    });

    // Process the offer received earlier
    addLog("Processing the stored offer");
    newPeer.signal(pendingOffer);  // Apply the offer to the peer
    setPendingOffer(null);  // Clear the pending offer after answering

    // Process queued ICE candidates after peer initialization
    candidateQueue.current.forEach(candidate => {
      addLog("Processing queued ICE candidate.");
      newPeer.signal(candidate);
    });
    candidateQueue.current.length = 0; // Clear the queue
  };

  // Send signal data to Firebase
  const sendSignalToFirebase = (data) => {
    const signalsRef = ref(realTimeDb, 'signals');

    // Attach who is sending the signal (caller or callee)
    const signalWithSender = {
      ...data,
      from: isCaller ? 'caller' : 'callee'  // Identify whether the caller or callee is sending the signal
    };

    push(signalsRef, signalWithSender);  // Push the signaling data (offer/answer/ICE candidates) to Firebase
  };

  return (
    <div>
      <div className="video-container">
        <video ref={localVideoRef} autoPlay muted style={{ width: "50%" }} /> {/* Local video stream */}
        <video ref={remoteVideoRef} autoPlay playsInline muted style={{ width: "50%" }} /> {/* Remote video stream */}
      </div>

      {/* Desktop: Start Call */}
      <button onClick={startPeerConnection}>Start Call</button>

      {/* Phone: Answer Call */}
      {!isCaller && pendingOffer && (
        <button onClick={answerCall}>Answer Call</button>
      )}

      {/* Display logs in the UI */}
      <div style={{ color: 'white', marginTop: '20px', padding: '10px', border: '1px solid black', height: '200px', overflowY: 'scroll' }}>
        <h3>Logs:</h3>
        <ul>
          {logs.map((log, index) => (
            <li key={index}>{log}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VideoChat;

