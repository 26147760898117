import React, { useEffect, useState } from 'react';

// Styles
import './StatesCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * StatesCell Component
 * 
 * This component renders an drop-down list for selecting a state.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const StatesCell = ({
  object,
  field,
  handleBlur
}) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');

  const backgroundColor = "transparent";
  const foregroundColor = theme.foregroundColor;

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function sets the value to the input value.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    handleBlur(object.key, field.key, inputValue);
  };

  // Hardcoded list of all US states as name/value pairs
  const usStates = [
    { name: "AL", value: "Alabama" },
    { name: "AK", value: "Alaska" },
    { name: "AZ", value: "Arizona" },
    { name: "AR", value: "Arkansas" },
    { name: "CA", value: "California" },
    { name: "CO", value: "Colorado" },
    { name: "CT", value: "Connecticut" },
    { name: "DE", value: "Delaware" },
    { name: "FL", value: "Florida" },
    { name: "GA", value: "Georgia" },
    { name: "HI", value: "Hawaii" },
    { name: "ID", value: "Idaho" },
    { name: "IL", value: "Illinois" },
    { name: "IN", value: "Indiana" },
    { name: "IA", value: "Iowa" },
    { name: "KS", value: "Kansas" },
    { name: "KY", value: "Kentucky" },
    { name: "LA", value: "Louisiana" },
    { name: "ME", value: "Maine" },
    { name: "MD", value: "Maryland" },
    { name: "MA", value: "Massachusetts" },
    { name: "MI", value: "Michigan" },
    { name: "MN", value: "Minnesota" },
    { name: "MS", value: "Mississippi" },
    { name: "MO", value: "Missouri" },
    { name: "MT", value: "Montana" },
    { name: "NE", value: "Nebraska" },
    { name: "NV", value: "Nevada" },
    { name: "NH", value: "New Hampshire" },
    { name: "NJ", value: "New Jersey" },
    { name: "NM", value: "New Mexico" },
    { name: "NY", value: "New York" },
    { name: "NC", value: "North Carolina" },
    { name: "ND", value: "North Dakota" },
    { name: "OH", value: "Ohio" },
    { name: "OK", value: "Oklahoma" },
    { name: "OR", value: "Oregon" },
    { name: "PA", value: "Pennsylvania" },
    { name: "RI", value: "Rhode Island" },
    { name: "SC", value: "South Carolina" },
    { name: "SD", value: "South Dakota" },
    { name: "TN", value: "Tennessee" },
    { name: "TX", value: "Texas" },
    { name: "UT", value: "Utah" },
    { name: "VT", value: "Vermont" },
    { name: "VA", value: "Virginia" },
    { name: "WA", value: "Washington" },
    { name: "WV", value: "West Virginia" },
    { name: "WI", value: "Wisconsin" },
    { name: "WY", value: "Wyoming" }
  ];

  return (
    <div className="states-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}>
      <select
        className="states-cell-input"
        style={{ color: theme.foregroundColor }}
        value={value}
        onChange={handleChange}>
        <option value="" {...(value !== '' ? { disabled: true } : {})}></option>
        {usStates.map(state => (
          <option key={state.name} value={state.name}>{state.value}</option>
        ))}
      </select>
    </div>
  );
};

export default StatesCell;
