import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode } from '../../common/utilities/Constants';

// Styles
import './MenuItems.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Images
import CalendarIcon from '../../common/svg/CalendarIcon';
import HomeIcon from '../../common/svg/HomeIcon';
import CubeIcon from '../../common/svg/CubeIcon';
import MessagesIcon from '../../common/svg/MessagesIcon';
import SearchIcon from '../../common/svg/SearchIcon';
import SettingsIcon from '../../common/svg/SettingsIcon';
import UsersIcon from '../../common/svg/UsersIcon';

// Components
import AddMenuItemButton from './AddMenuItemButton';
import Channels from './channels/Channels';
import MenuItemMenu from './MenuItemMenu';
import MenuSettings from './MenuSettings';
import NewModelModal from './NewModelModal';
import Search from '../../common/search/Search';

// Managers
import ObjectManager from '../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

const InlineStyles = useStyle`
    .menu-item:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const MenuItems = () => {
    const { theme } = useTheme();
    const {
        models,
        calendarVisible,
        messagesVisible,
        newModelModalOpen,
        resetVisibility,
        runHomeVisible,
        selectedApp,
        selectedModel,
        setFormMode,
        setAppUsersVisible,
        setCalendarVisible,
        setMessagesVisible,
        setNewModelModalOpen,
        setRunHomeVisible,
        setSelectedModel,
        setSelectedObject,
        setSettingsVisible,
        setObjectsView,
        settingsVisible,
        userRole,
    } = useContext(Global);

    const [isMenuSettingsOpen, setMenuSettingsOpen] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);

    const handleItemClick = (visibilitySetter) => {
        resetVisibility();
        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }
        visibilitySetter(true);
    };

    const handleSearchClick = () => {
        setSearchVisible(true);
    };

    const onSearch = async (entry) => {

        // Reset the visibility
        resetVisibility();

        // Make sure the form is selected instead of table
        setObjectsView("LIST");

        // Find the model in the models array and set it to active
        const model = models.find(model => model.key === entry.modelKey);
        setSelectedModel(model);

        // Fetch the selected object and set it to active
        const object = await objectManager.fetch(selectedApp.key, entry.modelKey, entry.objectKey);
        setSelectedObject(object);

        // Make sure we're in edit mode if admin
        setFormMode(userRole === "ADMIN" ? FormMode.EDIT : FormMode.VIEW);

        // Close the search dialog
        setSearchVisible(false);
    };

    const handleAddCollectionClick = () => {
        setNewModelModalOpen(true);
    };

    const MenuItem = ({ menuItemKey, isActive, onClick, children, Icon = null }) => (
        <div
            className="menu-item"
            style={{
                backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent'
            }}>
            <div className="menu-item-left"
                onClick={onClick}>
                {Icon &&
                    <Icon
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="18"
                        height="14"
                    />
                }
                <div className="menu-item-text"
                    style={{
                        color: isActive ? theme.highlightForegroundColor : theme.foregroundColor
                    }}>
                    {children}
                </div>
            </div>

            {userRole === "ADMIN" &&
                menuItemKey &&
                <div
                    className="model-item-edit-image">
                    <MenuItemMenu model={models.find(model => model.key === menuItemKey)} />
                </div>
            }
        </div>
    );

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="menuitems-container"
                style={{
                    backgroundColor: theme.backgroundColor
                }}>

                {/* HOME */}
                <div className="menuitems-list">

                    {/* MOBILE-ONLY */}
                    {isMobile &&
                        <>
                            <MenuItem
                                key="RUNHOME"
                                isActive={runHomeVisible}
                                onClick={() => handleItemClick(setRunHomeVisible)}
                                Icon={HomeIcon}>
                                Home
                            </MenuItem>

                            {/* MESSAGES */}
                            <MenuItem
                                key="MESSAGES"
                                isActive={messagesVisible}
                                onClick={() => handleItemClick(setMessagesVisible)}
                                Icon={MessagesIcon}
                            >
                                Messages
                            </MenuItem>

                            {/* CALENDAR */}
                            <MenuItem
                                key="CALENDAR"
                                isActive={calendarVisible}
                                onClick={() => handleItemClick(setCalendarVisible)}
                                Icon={CalendarIcon}
                            >
                                Calendar
                            </MenuItem>

                            {/* USERS */}
                            <MenuItem
                                key="USERS"
                                isActive={false}
                                onClick={() => handleItemClick(setAppUsersVisible)}
                                Icon={UsersIcon}>
                                People
                            </MenuItem>
                        </>
                    }

                    {/* CHANNELS */}
                    <Channels />

                    {/* COLLECTIONS */}
                    <div className="menu-items-group-title"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        Collections
                    </div>
                    <div className="menu-items-group">
                        <MenuItem
                            key="SEARCH"
                            isActive={false}
                            onClick={() => handleSearchClick()}
                            Icon={SearchIcon}>
                            Search
                        </MenuItem>
                        {models.map(model => (
                            <MenuItem
                                key={model.key}
                                menuItemKey={model.key}
                                isActive={selectedModel && selectedModel.key === model.key}
                                onClick={() => handleItemClick(() => setSelectedModel(model))}
                                Icon={CubeIcon}>
                                {model.title}
                            </MenuItem>
                        ))}

                        {/* ADD COLLECTION */}
                        {userRole === "ADMIN" &&
                            <>
                                <AddMenuItemButton
                                    onClick={handleAddCollectionClick}
                                    title="Add Collection"
                                />
                                <NewModelModal
                                    newModelModalOpen={newModelModalOpen}
                                    setNewModelModalOpen={setNewModelModalOpen}
                                />
                            </>
                        }
                    </div>

                    {/* SETTINGS */}
                    {isMobile && (
                        <MenuItem
                            key="SETTINGS"
                            isActive={settingsVisible}
                            onClick={() => handleItemClick(setSettingsVisible)}
                            Icon={SettingsIcon}
                        >
                            Settings
                        </MenuItem>
                    )}

                    {selectedModel &&
                        <MenuSettings
                            modelKey={selectedModel.key}
                            isMenuSettingsOpen={isMenuSettingsOpen}
                            setMenuSettingsOpen={setMenuSettingsOpen}
                        />
                    }

                </div>
            </div>

            <Search
                onSearch={onSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
            />
        </>
    );
};

export default MenuItems;
