import React, { useEffect, useState } from 'react';

// Styles
import './CheckboxCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CheckOffIcon from '../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../common/svg/CheckOnIcon';

/**
 * CheckboxCell Component
 * 
 * This component renders an input field that accepts boolean values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CheckboxCell = ({ object, field, handleBlur }) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  const handleToggle = () => {
    const newCheckedState = !value;
    setValue(newCheckedState);
    handleBlur(object.key, field.key, newCheckedState ? true : false);
  };

  return (
    <div className="checkbox-cell-container"
      style={{
        backgroundColor: "transparent",
        color: theme.foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}
      onClick={handleToggle}>
      <div className="checkbox-cell-checkbox-wrapper">
        {value ? (
          <CheckOnIcon
            color={theme.highlightBackgroundColor}
            checkColor={theme.foregroundColor}
            width="22"
            height="22"
          />
        ) : (
          <CheckOffIcon
            color={theme.foregroundColor}
            width="22"
            height="22"
          />
        )}
      </div>
    </div >
  );
};

export default CheckboxCell;
