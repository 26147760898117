import React from 'react';

const PriorityIcon = ({ color = "white", width = "4", height = "18" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 4 18">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-926.000000, -3657.000000)">
                <g transform="translate(100.000000, 3600.000000)">
                    <g transform="translate(816.000000, 54.000000)">
                        <g>
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M12,21 C10.8954305,21 10,20.1045695 10,19 C10,17.8954305 10.8954305,17 12,17 C13.1045695,17 14,17.8954305 14,19 C14,20.1045695 13.1045695,21 12,21 Z M12,3 C13.1,3 14,3.9 14,5 L14,13 C14,14.1 13.1,15 12,15 C10.9,15 10,14.1 10,13 L10,5 C10,3.9 10.9,3 12,3 Z"
                                fill={color}></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default PriorityIcon;
