import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './TimespanSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import SelectIcon from '../../svg/SelectIcon';

const TimespanSelector = () => {
    const { theme } = useTheme();
    const {
        setTimespan,
        timespan
    } = useContext(Global);

    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    const toggleDropdown = (event) => {
        setIsOpen(!isOpen);
    };

    // Handle clicking outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current &&
                !containerRef.current.contains(event.target) &&
                event.target.tagName !== 'LI') {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Function to return the label based on the timespan value
    const getLabel = () => {
        switch (timespan) {
            case 'CURRENT': return 'Current Billing Period';
            case 'LAST60': return 'Last 60 Days';
            case 'LAST24': return 'Last 24 Hours';
            case 'LAST7': return 'Last 7 Days';
            case 'LAST30': return 'Last 30 Days';
            default: return '';
        }
    };

    const handleTimespanChange = (event, billing) => {
        event.stopPropagation();
        setTimespan(billing);
        setIsOpen(false);
    };

    return (
        <div className="timespan-selector-outer-container">
            <div ref={containerRef} className="timespan-selector-container" onClick={toggleDropdown}>
                <div className="timespan-selector-triangle">
                    <SelectIcon
                        color={theme.foregroundColor}
                        width="16"
                        height="16"
                    />
                </div>
                <span style={{ color: theme.foregroundColor }}>
                    {getLabel()}
                </span>
            </div>
            {isOpen && (
                <ul 
                    className="timespan-selector-dropdown-menu" 
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded
                    }}
                    onClick={(e) => e.stopPropagation()}>
                    <li onClick={(e) => {
                        e.stopPropagation();
                        handleTimespanChange(e, 'CURRENT');
                    }} 
                    className="timespan-selector-dropdown-menu-item"
                    style={{color: theme.foregroundColor}}>
                        Current Billing Period
                    </li>
                    <li onClick={(e) => {
                        e.stopPropagation();
                        handleTimespanChange(e, 'LAST60');
                    }} 
                    className="timespan-selector-dropdown-menu-item"
                    style={{color: theme.foregroundColor}}>
                        Last 60 Days
                    </li>
                    <li onClick={(e) => {
                        e.stopPropagation();
                        handleTimespanChange(e, 'LAST24');
                    }} 
                    className="timespan-selector-dropdown-menu-item"
                    style={{color: theme.foregroundColor}}>
                        Last 24 Hours
                    </li>
                    <li onClick={(e) => {
                        e.stopPropagation();
                        handleTimespanChange(e, 'LAST7');
                    }} 
                    className="timespan-selector-dropdown-menu-item"
                    style={{color: theme.foregroundColor}}>
                        Last 7 Days
                    </li>
                    <li onClick={(e) => {
                        e.stopPropagation();
                        handleTimespanChange(e, 'LAST30');
                    }} 
                    className="timespan-selector-dropdown-menu-item"
                    style={{color: theme.foregroundColor}}>
                        Last 30 Days
                    </li>
                </ul>

            )}
        </div>
    );
};

export default TimespanSelector;
