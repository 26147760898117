import React from 'react';

// Utilities
import { getEventTimespan } from '../../../utilities/Events';
import { formatTimestamp } from '../../../utilities/Formatters';

// Styles
import './Event.css';

// Images
import CalendarIcon from '../../../svg/CalendarIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

const Event = ({ event }) => {
    const { theme } = useTheme();

    const handleEventClick = () => {

    };

    return (
        <>
            {/* CONTAINER */}
            <div key={event.url} className="event-container"
                style={{
                    backgroundColor: theme.backgroundColorFaded
                }}>

                {/* TITLE SECTION */}
                <div className="event-title-wrapper">

                    {/* ICON */}
                    <CalendarIcon
                        color={theme.highlightBackgroundColor}
                        width="16"
                        height="16"
                    />

                    {/* TITLE */}
                    <div className="event-title"
                        style={{
                            color: theme.foregroundColor
                        }}
                        onClick={() => handleEventClick()}>
                        {event.title}
                    </div>

                </div>

                {/* BOTTOM SECTION */}
                <div className="event-day-and-time">

                    {/* DAY */}
                    <div
                        className="event-day"
                        style={{ color: theme.foregroundColor }}>
                        {formatTimestamp(event.startDate.toDate())}
                    </div>

                    {/* TIME */}
                    <div
                        className="event-time"
                        style={{ color: theme.foregroundColorFaded }}>
                        {getEventTimespan(event)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Event;
