import React, { useRef } from 'react';

// Styles
import './ForumPostFormVideoButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import VideoIcon from '../../../svg/VideoIcon';

const ForumPostFormVideoButton = ({ localVideos, setLocalVideos, videoUrls, setVideoUrls }) => {
  const { theme } = useTheme();

  // References
  const videoInputRef = useRef(null);

  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    const newLocalVideos = files.map(file => URL.createObjectURL(file));
    setLocalVideos([...localVideos, ...files]);
    setVideoUrls([...videoUrls, ...newLocalVideos]);
    videoInputRef.current.value = "";
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="forum-post-form-video-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => videoInputRef.current.click()}>

        {/* ICON */}
        <VideoIcon
          color={theme.foregroundColor}
          width="18"
          height="18"
        />

        {/* INPUT */}
        <input
          ref={videoInputRef}
          id="video-upload"
          type="file"
          accept="video/*"
          onChange={handleVideoUpload}
          style={{ display: 'none' }}
          multiple
        />
      </div>
    </>
  );
};

export default ForumPostFormVideoButton;
