import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './CanvasElement.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ButtonSummaryPreview from '../previews/ButtonSummaryPreview';
import CreatedSummaryPreview from '../previews/CreatedSummaryPreview';
import GallerySummaryPreview from '../previews/GallerySummaryPreview';
import ResizeHandles from '../resizehandles/ResizeHandles';
import TextSummaryPreview from '../previews/TextSummaryPreview';
import UserNameSummaryPreview from '../previews/UserNameSummaryPreview';
import UserPhotoSummaryPreview from '../previews/UserPhotoSummaryPreview';
import VideoGallerySummaryPreview from '../previews/VideoGallerySummaryPreview';
import ViewCountSummaryPreview from '../previews/ViewCountSummaryPreview';

// Helpers
import DragHelper from '../DragHelper';
import KeyboardHelper from '../KeyboardHelper';

const dragHelper = new DragHelper();
const keyboardHelper = new KeyboardHelper();

/**
 * CanvasElement Component
 * 
 * This component renders an element on the canvas.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CanvasElement = ({ element, elements }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedElement,
    setElements,
    setSelectedElement
  } = useContext(Global);

  /**
   * Method to handle drag start as the user begins moving an element on the canvas
   * 
   * @param {event} e - Drag event.
   */
  const handleDragStart = (e) => {
    setSelectedElement(element);
    e.dataTransfer.setData("text/plain", `${e.clientX}, ${e.clientY}`);
    dragHelper.handleDragStart(e, element, "moving");
  };

  /**
   * Method to set the selected element when the element is clicked.
   * 
   * @param {event} e - Click event.
   */
  const handleClick = (e) => {
    e.stopPropagation();
    setSelectedElement(element);
  };

  /**
   * Effect for managing keyboard actions against the selected element.
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      keyboardHelper.handleKeyPress(event, elements, setElements, selectedElement, setSelectedElement);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [elements, selectedElement, setElements, setSelectedElement]);

  return (
    <div
      className="canvas-element-container"
      draggable="true"
      onDragStart={handleDragStart}
      onClick={handleClick}
      style={{
        position: 'absolute',
        overflow: 'show',
        borderColor: selectedElement && selectedElement.key === element.key ? theme.backgroundColorFaded : theme.backgroundColor,
        top: element.styles.top,
        left: element.styles.left,
        width: element.styles.width,
        height: element.styles.height,
        zIndex: 0
      }}>
      <>
        {/* FIELDS */}
        {element.type === 'field' && element.field &&
          <>
            {['autonumber', 'country', 'currency', 'day', 'lookup', 'number', 'phone', 'state', 'text', 'time', 'year'].includes(element.field.type) ? <TextSummaryPreview element={element} /> : null}
            {element.field.type === 'gallery' ? <GallerySummaryPreview element={element} /> : null}
            {element.field.type === 'videogallery' ? <VideoGallerySummaryPreview element={element} /> : null}
          </>
        }

        {/* ELEMENTS */}
        {element.type === 'static' && 
          <>
            {/* DATE CREATED */}
            {element.elementType === 'created' &&
              <CreatedSummaryPreview element={element} />
            }

            {/* VIEW COUNT */}
            {element.elementType === 'viewcount' &&
              <ViewCountSummaryPreview element={element} />
            }

            {/* USER PHOTO */}
            {element.elementType === 'userphoto' &&
              <UserPhotoSummaryPreview element={element} />
            }

            {/* USER NAME */}
            {element.elementType === 'username' &&
              <UserNameSummaryPreview element={element} />
            }
          </>
        }

        {element.type === 'button' &&
          <ButtonSummaryPreview element={element} />
        }

        {selectedElement && selectedElement.key === element.key && (
          <ResizeHandles element={element} />
        )}
      </>
    </div>
  );
};

export default CanvasElement;
