import React from 'react';

// Utilities
import { truncateFileName } from '../../../../../utilities/Strings';

// Styles
import './ChatPostFormDocument.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import DocumentIcon from '../../../../../svg/DocumentIcon';

const ChatPostFormDocument = ({ file, url }) => {
  const { theme } = useTheme();
  
  console.log(file);

  const handleDocumentClick = (url) => {
      window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="chat-post-form-document-container"
      style={{
        backgroundColor: theme.backgroundColorFaded
      }}>
      <DocumentIcon
        color={theme.highlightBackgroundColor}
        width="14"
        height="14"
      />
      <div
        className="chat-post-form-document-filename"
        style={{
          color: theme.foregroundColor
        }}
        onClick={() => handleDocumentClick(url)}>
        {truncateFileName(file.name, 100)}
      </div>
    </div>
  );
};

export default ChatPostFormDocument;
