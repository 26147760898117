import React from 'react';

// Styles
import './UserFieldsVisibility.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserFieldsVisibility = ({ visible }) => {
  const { theme } = useTheme();

  return (
    <div className="user-fields-visibility-container"
      style={{
        color: theme.foregroundColorFaded
      }}>
      ({visible ? "Visible to other users" : "Not visible to other users"})
    </div>
  );
};

export default UserFieldsVisibility;
