import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './FormDeleteConfirmation.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../common/components/modal/Modal';

const FormDeleteConfirmation = ({ isOpen, setIsOpen, onConfirm, onCancel }) => {
    const { theme } = useTheme();
    const {
        selectedModel,
        selectedObject
    } = useContext(Global);

    return (

        <>
            {isOpen &&

                <Modal title="Delete" isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <div className="form-delete-confirmation-text"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        Are you sure you want to delete {selectedObject[selectedModel.titleFieldKey]}?
                    </div>
                    <div className="modal-buttons">
                        <button onClick={onCancel} className="modal-button"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            No
                        </button>
                        <button onClick={onConfirm} className="modal-button"
                            style={{ color: theme.foregroundColor }}>
                            Yes
                        </button>
                    </div>
                </Modal>

            }
        </>
    );
};

export default FormDeleteConfirmation;
