import React, { useContext } from 'react';

// Styles
import './ObjectsToolBar.css';

// Global
import { Global } from '../../Global';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ViewMenu from './ViewMenu';

const ObjectsToolBar = () => {
    const { theme } = useTheme();
    const { selectedModel } = useContext(Global);

    return (
        <div className="objects-toolbar-container">
            <div className="objects-toolbar-buttons">
                <div className="objects-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}
                >
                    {selectedModel &&
                        <>
                            {selectedModel.title}
                        </>
                    }
                </div>
                <div className="objects-toolbar-right" >
                    {selectedModel &&
                        <ViewMenu />
                    }
                </div>
            </div>
        </div>
    );
};

export default ObjectsToolBar;
