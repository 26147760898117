import React, { useState, useRef, useEffect } from 'react';

// Styles
import './Select.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
    
    .select-option:hover {
        background-color: ${(props) => props.hoverBackgroundColor};
        color: ${(props) => props.hoverForegroundColor};
    }

    .select-option.selected {
        color: ${(props) => props.selectedTextColor};
    }

`;

/**
 * Select Component
 * 
 * This component replaces the HTML <select>.
 * 
 * @param {array} options - Array of options to display.
 * @param {string} value - Selected value.
 * @param {function} onChange - The function to call when the selection changes.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Select = ({
    options,
    value = '',
    onChange
}) => {
    // Theme
    const { theme } = useTheme();

    // State Variables
    const [isOpen, setIsOpen] = useState(false);

    // References
    const dropdownRef = useRef(null);

    /**
     * Toggles the dropdown open and closed.
     */
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    /**
     * Handles the selection of an option.
     */
    const handleSelect = (option) => {
        onChange(option.value);
        setIsOpen(false);
    };

    /**
     * Handles clicking outside the dropdown.
     */
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    // Effect to add the event listener for clicking outside the dropdown
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Find the label for the currently selected value
    const selectedLabel = options.find(option => option.value === value)?.label || 'Select an option';

    return (
        <>
            <InlineStyles
                hoverBackgroundColor={theme.highlightBackgroundColor}
                hoverForegroundColor={theme.highlightForegroundColor}
                selectedTextColor={theme.foregroundColorFaded}
            />

            {/* CONTAINER */}
            <div className="select-container" ref={dropdownRef}>

                {/* TRIGGER */}
                <div className="select-trigger"
                    style={{
                        color: theme.foregroundColor
                    }}
                    onClick={toggleDropdown}>
                    {selectedLabel}
                </div>

                {/* OPTIONS */}
                {isOpen && (
                    <div className={`select-options`}
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                            color: theme.foregroundColor
                        }}>
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={`select-option ${option.value === value ? 'selected' : ''}`}
                                onClick={() => handleSelect(option)}>
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default Select;
