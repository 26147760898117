import React from 'react';

// Styles
import './ChatUser.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const ChatUser = ({ appUser }) => {
    const { theme } = useTheme();

    return (

        <div className="chat-user-container">
            <div className="chat-user-name"
                style={{
                    color: theme.foregroundColor
                }}>
                {appUser.username}
            </div>
        </div>
        
    );
};

export default ChatUser;
