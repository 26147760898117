import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../common/utilities/Keys';

// Styles
import './NewModelModal.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../common/components/modal/Modal';

// Managers
import ModelManager from '../../common/managers/ModelManager';
import PromptManager from '../../common/managers/PromptManager';

const modelManager = new ModelManager();
const promptManager = new PromptManager();

const NewModelModal = ({ newModelModalOpen, setNewModelModalOpen }) => {
    const { theme } = useTheme();
    const {
        currentUser,
        hideProgress,
        models,
        resetVisibility,
        selectedApp,
        setFormMode,
        setSelectedModel,
        setStatusMessage,
        showProgress
    } = useContext(Global);

    const [title, setTitle] = useState('');

    const titleInputRef = useRef(null);

    // Focus the title field on model load
    useEffect(() => {
        if (newModelModalOpen) {
            titleInputRef.current.focus();
        }
    }, [newModelModalOpen]);

    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a menu item title.');
            return;
        }

        showProgress("Adding menu item...");

        setNewModelModalOpen(false);

        const userKey = currentUser.key;
        const userFirstName = currentUser.firstName;
        const userLastName = currentUser.lastName;
        const appKey = selectedApp.key;

        const newModelKey = generateKey();  // Generate key for the new model

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each model to find the highest sort number
        models.forEach(model => {
            const sortNumber = model.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const modelData = {
            key: newModelKey,
            appKey: appKey,
            description: "",
            userKey: userKey,
            userFirstName: userFirstName,
            userLastName: userLastName,
            title: title,
            summaryHeight: "44px",
            noview: [],
            noadd: [],
            noedit: [],
            nodelete: [],
            sort: highestSortNumber + 1,
            dateCreated: now,
            dateModified: now,
        };

        await modelManager.add(appKey, newModelKey, modelData);

        // Generate default fields
        await submitModelPrompt(selectedApp, modelData, []);

        setTitle('');

        setStatusMessage("Model created successfully.");

        hideProgress();
    };

    const submitModelPrompt = async (app, model, models) => {
      try {
  
        const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, models);
  
        console.log(prompt);
  
        const response = await promptManager.send(prompt);
  
        await promptManager.processModelResponse(
            response, 
            app, 
            model, 
            showProgress,
            setSelectedModel,
            setFormMode,
            resetVisibility,
            []);
  
      } catch (error) {
        console.error('Failed to fetch API response:', error);
      }
    };

    return (
        <Modal title="Add a Collection"
            isOpen={newModelModalOpen}
            onClose={() => setNewModelModalOpen(false)}
            width="300px">
            <div className="new-model-modal-wrapper">
                <div className="new-model-modal-container">
                    <div className="new-model-modal-right">
                        <div className="modal-label"
                            style={{color: theme.foregroundColor}}>
                            Collection Title
                        </div>
                        <input
                            type="text"
                            name="title"
                            className="modal-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                                fontFamily: selectedApp.fontFamily
                            }}
                            value={title}
                            maxLength="18"
                            onChange={e => setTitle(e.target.value)}
                            ref={titleInputRef}
                        />
                    </div>
                </div>
                <div className="new-model-modal-buttons">
                    <button
                        type="button"
                        onClick={handleAdd}
                        className="new-model-modal-button"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily
                        }}>
                        Continue...
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default NewModelModal;
