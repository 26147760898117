import React from 'react';

const UpvoteOnIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path fill={color} d="M4,14h2h2v3v4c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1v-5v-2h1h3c0.385,0,0.734-0.221,0.901-0.566 c0.166-0.347,0.12-0.758-0.12-1.059l-8-10C12.4,1.9,11.6,1.9,11.219,2.375l-8,10c-0.24,0.301-0.286,0.712-0.12,1.059 C3.266,13.779,3.615,14,4,14z" />
    </svg>
);

export default UpvoteOnIcon;
