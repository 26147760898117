import React from 'react';

// Styles
import './ForumPostFormPhotos.css';

// Components
import ForumPostFormPhoto from './ForumPostFormPhoto';

const ForumPostFormPhotos = ({ urls }) => {

  return (
    <>
      {urls && urls.length > 0 &&
        <div className="forum-post-form-photos-container">
          {urls.map((url, index) => (
            <ForumPostFormPhoto 
              index={index}
              url={url}
            />
          ))}
        </div>
      }
    </>
  );
};

export default ForumPostFormPhotos;
