import React from 'react';

// Styles
import './AppBarAdd.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../../common/svg/PlusIcon';

/**
 * AppBarAdd Component
 * 
 * This component renders an add icon for adding an app from the app bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppBarAdd = ({ onClick }) => {
    const { theme } = useTheme();

    return (
        <>
            {/* CONTAINER */}
            <div className="app-bar-add-container">

                {/* CIRCLE */}
                <div className="app-bar-add-circle"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}
                    onClick={onClick}>

                    {/* ADD ICON */}
                    <PlusIcon
                        color={theme.foregroundColorFaded}
                        width="20"
                        height="20"
                    />

                </div>
            </div>
        </>
    );
};

export default AppBarAdd;
