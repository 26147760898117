import React from 'react';

// Styles
import './CurrencyPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CurrencyPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="currency-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div
                className="currency-preview-label"
                style={{ color: theme.foregroundColorFaded }}
            >
                {placeholder}
            </div>
            <input
                type="text"
                value="1023.50"
                className="currency-preview-input"
                style={{ color: theme.foregroundColor }}
                readOnly
                tabIndex={-1} // This makes the input unfocusable via keyboard
            />
        </div>
    );
};

export default CurrencyPreview;
