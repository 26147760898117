import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './FavoriteButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import FavoriteIcon from '../../../../common/svg/FavoriteIcon';
import FavoriteOnIcon from '../../../../common/svg/FavoriteOnIcon';

// Managers
import ElementManager from '../../../../common/managers/ElementManager';

const elementManager = new ElementManager();

const FavoriteButton = ({ element, object }) => {
    const { theme } = useTheme();
    const {
        currentUser,
        selectedApp,
        selectedModel,
        userElements
    } = useContext(Global);

    // State Variables
    const [selected, setSelected] = useState(false);

    /** 
     * Checks if the user has selected the element.
     */
    useEffect(() => {

        const userElement = userElements.find(elem => 
            elem.key === element.key && 
            elem.userKey === currentUser.key && 
            elem.objectKey === object.key);

        if (userElement && userElement.value) {
            setSelected(true);
        }   

    }, [userElements]);

    /**
     * Handles the click event for the favorite button.
     * @param {event} event 
     */
    const handleClick = async (event) => {
        event.stopPropagation();

        const newValue = !selected;

        setSelected(newValue);

        const data = {
            key: element.key,
            appKey: selectedApp.key,
            modelKey: selectedModel.key,
            userKey: currentUser.key,
            objectKey: object.key,
            elementKey: element.key,
            value: newValue
        };

        await elementManager.update(selectedApp.key, element.key, data);
    };

    return (
        <div className="favorite-button-container"
            onClick={handleClick}>
            {selected ? (
                <FavoriteOnIcon
                    color={theme.highlightBackgroundColor}
                    width="100%"
                    height="100%"
                />
            ) : (
                <FavoriteIcon
                    color={theme.foregroundColor}
                    width="100%"
                    height="100%"
                />
            )}
        </div>
    );
};

export default FavoriteButton;

