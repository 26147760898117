import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { formatDate } from '../../../common/utilities/Formatters';

// Styles
import './EventDate.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Day Picker
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const DayPickerStyles = useStyle`
    .rdp {
        --rdp-accent-color: ${(props) => props.highlightColor} !important;
        --rdp-background-color: transparent !important;
    }
    .rdp-caption {
        padding: 12px !important;
    }
    .rdp-caption-button {
        background-color: transparent !important;
        cursor: pointer;
    }
    .rdp-caption-select {
        background-color: transparent !important;
        cursor: pointer;
        font-size: 12pt;
        font-weight: bold;
    }
    .rdp-caption-title {
        font-size: 12pt;
        font-weight: bold;
    }
`;

// Custom caption component with year selector
const CustomCaption = ({ currentMonth, onMonthChange, onYearChange }) => {
    const { theme } = useTheme();
    const { 
      selectedApp 
    } = useContext(Global);
    
    if (!currentMonth) {
        currentMonth = new Date(); // Default to the current date if undefined
    }

    const currentYear = currentMonth.getFullYear();
    const years = [];
    for (let i = currentYear - 120; i <= currentYear + 10; i++) {
        years.push(i);
    }

    const handleYearChange = (e) => {
        const newYear = parseInt(e.target.value, 10);
        onYearChange(newYear);
    };

    const handleMonthChange = (delta) => {
        onMonthChange(delta);
    };

    return (
        <div
            className="rdp-caption">
            <span className="rdp-caption-title">{currentMonth.toLocaleString('default', { month: 'long' })}</span>
            <div
                className="rdp-caption-button"
                style={{
                    color: theme.foregroundColor
                }}
                onClick={() => handleMonthChange(-1)}>{'<'}</div>
            <div
                className="rdp-caption-button"
                style={{
                    color: theme.foregroundColor
                }}
                onClick={() => handleMonthChange(1)}>{'>'}</div>
            <select
                className="rdp-caption-select"
                style={{
                    color: theme.foregroundColor,
                    fontFamily: selectedApp.fontFamily
                }}
                value={currentYear}
                onChange={handleYearChange}>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

const EventDate = ({
    placeholder = '',
    defaultValue,
    onUpdate
}) => {
    const { theme } = useTheme();
    const { 
      selectedApp 
    } = useContext(Global);

    // State Variables
    const [inputValue, setInputValue] = useState(defaultValue ? formatDate(defaultValue) : '');
    const [popupVisible, setPopupVisible] = useState(false);

    // References
    const popupRef = useRef(null);
    const inputRef = useRef(null);
    
    const [selectedDate, setSelectedDate] = useState(
        defaultValue ? defaultValue : null
    );
    const [currentMonth, setCurrentMonth] = useState(
        defaultValue ? defaultValue : new Date()
    );

    // Update state when defaultValue changes
    useEffect(() => {
        if (defaultValue) {
            setInputValue(formatDate(defaultValue));
            setSelectedDate(defaultValue);
            setCurrentMonth(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupVisible(false);
            }
        }

        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    const handleSelect = (date) => {
        if (date) {
            setSelectedDate(date);
            const formattedDate = formatDate(date);
            setInputValue(formattedDate);
            onUpdate(date);
            setCurrentMonth(date); // Set the selected date's month as the current month
        } else {
            setSelectedDate(null);
            setInputValue('');
            onUpdate(null);
        }
        setPopupVisible(false);
    };

    const handleMonthChange = (delta) => {
        const newDate = new Date(currentMonth);
        newDate.setMonth(newDate.getMonth() + delta);
        setCurrentMonth(newDate);
    };

    const handleYearChange = (newYear) => {
        const newDate = new Date(currentMonth);
        newDate.setFullYear(newYear);
        setCurrentMonth(newDate);
    };

    const calculatePopupPosition = () => {
        if (inputRef.current) {
            const inputRect = inputRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const popupHeight = 340;

            if (inputRect.top + popupHeight > viewportHeight) {
                return {
                    top: inputRef.current.offsetTop - popupHeight,
                    left: inputRef.current.offsetLeft + 10
                };
            }
            return {
                top: inputRef.current.offsetTop + inputRef.current.offsetHeight,
                left: inputRef.current.offsetLeft + 10
            };
        }
        return { top: 0, left: 0 };
    };

    const popupPosition = calculatePopupPosition();

    return (
        <>
            <div
                className="event-date-container">
                <input
                    type="text"
                    className="event-date-input"
                    placeholder={placeholder}
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily
                    }}
                    value={inputValue}
                    onClick={togglePopup}
                    readOnly
                    ref={inputRef}
                />
            </div>
            {popupVisible && (
                <div
                    className="event-date-popup"
                    ref={popupRef}
                    style={{
                        top: popupPosition.top,
                        left: popupPosition.left,
                        backgroundColor: theme.backgroundColor,
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily
                    }}>
                    <DayPickerStyles highlightColor={theme.highlightBackgroundColor} />
                    <DayPicker
                        mode="single"
                        selected={selectedDate}
                        onSelect={handleSelect}
                        month={currentMonth}
                        captionLayout="dropdown"
                        components={{
                            Caption: (props) => (
                                <CustomCaption
                                    currentMonth={currentMonth}
                                    onMonthChange={handleMonthChange}
                                    onYearChange={handleYearChange}
                                />
                            )
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default EventDate;
