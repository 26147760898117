import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { FormMode } from '../common/utilities/Constants';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Mobile.css';

// Theme
import { useStyle, useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AppUserProfile from '../common/appusers/AppUserProfile';
import AppUsers from '../common/appusers/AppUsers';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channels/chat/Chat';
import FieldSelector from '../common/form/fieldselector/FieldSelector';
import Form from '../common/form/Form';
import Forum from '../common/channels/forum/Forum';
import Menu from '../common/menu/Menu';
import Messages from '../common/messages/Messages';
import Objects from '../common/objects/Objects';
import RunHome from '../run/forum/RunHome';
import RunHeader from './MobileHeader';
import Settings from '../run/settings/Settings';
import TitleBar from '../common/titlebar/TitleBar';

// Styled Components
const InlineStyles = useStyle`
    
    body {
      margin: 0;
      font-family: ${(props) => props.fontFamily} !important;
      display: flex;
      flex-direction: column;
    }

    ::selection {
        background: ${(props) => props.selectionBackground};
        color: ${(props) => props.selectionForeground};
    }

    input::selection, textarea::selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

    input::-moz-selection, textarea::-moz-selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

`;

const Mobile = () => {
  const { theme } = useTheme();
  const {
    accountAboutVisible,
    accountBillingVisible,
    accountVisible,
    formMode,
    appUsersVisible,
    calendarVisible,
    chatVisible,
    currentUser,
    fieldSelectorVisible,
    forumVisible,
    selectedForumPost,
    menuVisible,
    messagesVisible,
    objectsView,
    runHomeVisible,
    selectedApp,
    selectedAppUser,
    selectedModel,
    selectedObject,
    setMenuVisible,
    settingsVisible,
    fieldVisible
  } = useContext(Global);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const renderContent = () => {
    if (fieldSelectorVisible) return <FieldSelector size="LARGE" />;
    if ((selectedObject && !fieldVisible) || (
      selectedModel &&
      !objectsView === "TABLE" && // ADD BUTTON CLICKED
      selectedObject === null &&
      formMode === FormMode.ADD
    )
    ) return <Form />;
    if (selectedAppUser) return <AppUserProfile />;
    if (chatVisible) return <Chat />;
    if (forumVisible) return <Forum />;
    if (selectedForumPost) return <Forum />;
    if (calendarVisible) return <Calendar />;
    if (settingsVisible) return <Settings />;
    if (runHomeVisible) return <RunHome />;
    if (appUsersVisible) return <AppUsers />;
    if (messagesVisible) return <Messages />;
    if (accountVisible) {
      if (isMobile) {
        if (accountAboutVisible) {
          return <AccountAbout />;
        } else if (accountBillingVisible) {
          return <AccountBilling />;
        } else {
          return <AccountMenu />;
        }
      } else {
        return <AccountMenu />;
      }
    }
    return <Objects />;
  };

  /**
   * Function to load only the regular variant of a font.
   * 
   * @param {string} fontName - Name of the font.
   */
  const loadGoogleFont = (fontName) => {
    const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    if (!selectedApp) return;
    loadGoogleFont(selectedApp.fontFamily || 'Red Hat Display');
  }, [selectedApp]);

  return (
    <>
      <InlineStyles
        fontFamily={selectedApp && selectedApp.fontFamily || 'Bokor'}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="mobile-container"
        style={{ backgroundColor: theme.backgroundColor }}>
        {selectedApp &&
          selectedApp.userKey === currentUser.key &&
          !isMobile &&
          !isTablet && (
            <div className="mobile-title-bar">
              <TitleBar />
            </div>
          )}
        {menuVisible && (
          <div className="mobile-menu-mask" onClick={closeMenu}></div>
        )}
        <div className={isMobile ? "mobile-center" : "mobile-center-mobile"} style={{ backgroundColor: theme.backgroundColor }}>
          <div className={`mobile-menu ${menuVisible ? 'visible' : ''}`}>
            <Menu />
          </div>
          <div className="mobile-content">
            <RunHeader />
            <div className="mobile-summaries">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mobile;
