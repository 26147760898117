import React, { useState, useEffect } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './EventFieldChecklist.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Modal from '../../../common/components/modal/Modal';

// Images
import CheckOffIcon from '../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../common/svg/CheckOnIcon';

/**
 * EventFieldChecklist
 * 
 * Component to allow the user to select which fields to include in an event.
 * 
 */
const EventFieldChecklist = ({
    modalOpen,
    setModalOpen,
    model,
    fields,
    onSave
}) => {
    const { theme } = useTheme();

    // State Variables
    const [selectedFields, setSelectedFields] = useState([]);
    const [sortedFields, setSortedFields] = useState([]);

    useEffect(() => {

        /**
         * Array of valid field types that can be included in the embedded object.
         */
        const validFieldTypes = [
            'comments',
            'country',
            'currency',
            'date',
            'day',
            'documents',
            'gallery',
            'number',
            'location',
            'list',
            'lookup',
            'month',
            'phone',
            'state',
            'text',
            'time',
            'videogallery',
            'userlist',
            'year',
            // checklists
        ];
    
        // Filter fields to include only those with a valid type
        const filteredFields = fields.filter(field => validFieldTypes.includes(field.type));
    
        // Sort the filtered fields
        const sorted = filteredFields.sort((a, b) => a.sort - b.sort);
    
        // Set the sorted fields to state
        setSortedFields(sorted);
    
        if (modalOpen) {
            setSelectedFields(filteredFields.map(field => field.key));
        }

    }, [modalOpen, fields]);

    /**
     * Method to toggle the selection of a field.
     * @param {string} fieldKey - The key of the field to toggle.
     */
    const handleToggle = (fieldKey) => {
        // Don't allow the title field to be deselected
        if (fieldKey === model.titleFieldKey) return;

        setSelectedFields(prevSelectedFields => {
            if (prevSelectedFields.includes(fieldKey)) {
                return prevSelectedFields.filter(key => key !== fieldKey);
            } else {
                return [...prevSelectedFields, fieldKey];
            }
        });
    }

    /**
     * Method to save the selected fields.
     */
    const handleSave = () => {
        // Filter the fields to return only the selected ones
        const selectedFieldObjects = fields.filter(field => selectedFields.includes(field.key));

        // Pass back the selected field objects to the onSave callback
        onSave(selectedFieldObjects);

        // Close the modal after saving
        setModalOpen(false);
    }

    return (

        <Modal title="Include" width={isMobile ? "90%" : "350px"} isOpen={modalOpen} onClose={() => setModalOpen(false)}>

            {/* INCLUDE FIELDS */}
            <div className="event-field-checklist-items"
                style={{
                    color: theme.foregroundColor
                }}>
                {sortedFields.map((field) => (
                    <div key={field.key} className="event-field-checklist-item-wrapper">
                        <div
                            className="event-field-checklist-item-checkbox"
                            style={{
                                cursor: field.key === model.titleFieldKey ? 'default' : 'pointer'
                            }}
                            onClick={() => handleToggle(field.key)}>
                            {(selectedFields.includes(field.key)) ||
                                (field.key === model.titleFieldKey) ? (
                                <CheckOnIcon
                                    color={field.key === model.titleFieldKey ? theme.backgroundColorFaded : theme.highlightBackgroundColor}
                                    checkColor={field.key === model.titleFieldKey ? theme.foregroundColorFaded : theme.highlightForegroundColor}
                                    width="24"
                                    height="24"
                                />
                            ) : (
                                <CheckOffIcon
                                    color={theme.foregroundColor}
                                    width="24"
                                    height="24"
                                />
                            )}
                        </div>
                        <div
                            className="event-field-checklist-item-label"
                            style={{
                                color: theme.foregroundColor,
                                cursor: field.key === model.titleFieldKey ? 'default' : 'pointer'
                            }}
                            onClick={() => handleToggle(field.key)}>
                            {field.title}
                        </div>
                    </div>
                ))}
            </div>

            {/* SAVE BUTTON */}
            <div className="modal-buttons">
                <button onClick={handleSave} className="modal-button"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    Continue...
                </button>
            </div>
        </Modal>

    );
};

export default EventFieldChecklist;
