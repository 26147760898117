import React from 'react';

// Styles
import './AppTitleSlash.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppTitleSlash = () => {
    const { theme } = useTheme();

    return (
        <div className="app-title-slash-container"
            style={{ color: theme.foregroundColor }}
        >/</div>
    );
};

export default AppTitleSlash;
