import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AccountAboutSaveButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * AccountAboutSaveButton Component
 * 
 * This component renders a button to save changes to the user account.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountAboutSaveButton = ({ onClick, enabled }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  return (

    <div className="account-about-save-button-container"
      onClick={onClick}
      style={{
        color: enabled
          ? (selectedApp ? theme.highlightForegroundColor : studio.highlightForegroundColor)
          : (selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded),
        backgroundColor: enabled
          ? (selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor)
          : (selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded)
      }}>
      Save Changes
    </div>

  );
};

export default AccountAboutSaveButton;
