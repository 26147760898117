import React from 'react';

// Styles
import './AutonumberPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const AutonumberPreview = ({ 
    placeholder = '', 
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="autonumber-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}
        >
            <div 
                className="autonumber-preview-label"
                style={{color: theme.foregroundColorFaded}}
            >
                {placeholder}
            </div>
            <div
                className="autonumber-preview-input"
                style={{color: theme.foregroundColor}}
            >
                22
            </div>
        </div>
    );
};

export default AutonumberPreview;
