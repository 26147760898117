import React from 'react';

// Utilities
import { getEventTimespan } from '../../common/utilities/Events';

// Styles
import './CalendarEvent.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`

.calendar-event-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%; /* Height of the fade */
    background: linear-gradient(to bottom, transparent, ${(props) => props.gradientColor});
  }
    
`;

const CalendarEvent = ({ event }) => {
  const { theme } = useTheme();

  return (
    <>
      <InlineStyles
        gradientColor={theme.highlightBackgroundColor}
      />
      <div 
        className="calendar-event-title"
        style={{ color: theme.highlightForegroundColor }}>
        {event.title}
      </div>
      <div 
        className="calendar-event-time"  
        style={{ color: theme.highlightForegroundColor }}>
        {getEventTimespan(event)}
      </div>
      <div className="calendar-event-details" style={{ color: theme.highlightForegroundColor }}>
        {event.attachments.map((item, index) => (
          <div key={index} 
          className="calendar-event-detail">
              {item.objectTitle}
            </div>
        ))}
        {Object.entries(event.formData)
          .filter(([key, value]) => {
            // Find the field in event.fields to check its type
            const field = event.fields.find(f => f.key === key);
            return field && field.type !== 'checklist';
          })
          .map(([key, value], index) => (
            <div key={index} className="calendar-event-detail">
              {Array.isArray(value) 
                ? value.map(item => <div key={item.key}>{item.note || ''}</div>) 
                : value}
            </div>
          ))}
      </div>
      <div className="calendar-event-fade"></div>
    </>
  );
};

export default CalendarEvent;
