import React, { useContext, useEffect, useState, useRef } from 'react';

// Global context and styles
import { Global } from '../../Global';

// Styles
import './CalendarSchedule.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import CalendarScheduleEvent from './CalendarScheduleEvent';

const CalendarSchedule = () => {
    const { theme } = useTheme();
    const {
        events,
        setSelectedEvent,
        setEventVisible,
    } = useContext(Global);

    // State to hold sorted and grouped events
    const [groupedEvents, setGroupedEvents] = useState({});
    const containerRef = useRef(null);

    useEffect(() => {
        if (events) {
            // Sort events by startDate
            const sorted = [...events].sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis());

            // Group events by day
            const grouped = sorted.reduce((groups, event) => {
                const eventDate = event.startDate.toDate();
                const dateKey = eventDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

                if (!groups[dateKey]) {
                    groups[dateKey] = [];
                }
                groups[dateKey].push(event);
                return groups;
            }, {});

            setGroupedEvents(grouped);
        }
    }, [events]);

    useEffect(() => {
        if (containerRef.current && Object.keys(groupedEvents).length > 0) {
            // Find the closest date to today
            const today = new Date();
            const sortedDates = Object.keys(groupedEvents).sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA - dateB;
            });

            let targetDateIndex = sortedDates.findIndex(date => new Date(date) >= today);

            if (targetDateIndex === -1) {
                // If no future date found, use the last date
                targetDateIndex = sortedDates.length - 1;
            }

            // Scroll to the target date
            const targetElement = containerRef.current.children[targetDateIndex];
            if (targetElement) {
                const targetPosition = targetElement.offsetTop - containerRef.current.clientHeight / 2 + targetElement.clientHeight / 2;
                containerRef.current.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [groupedEvents]);

    const handleEventClick = (eventKey) => {
        const event = Object.values(groupedEvents).flat().find(e => e.key === eventKey);
        setSelectedEvent(event);
        setEventVisible(true);
    }

    return (
        <div className="calendar-schedule-container" ref={containerRef}>
            {Object.keys(groupedEvents).map((date, index) => (
                <div key={index} className="calendar-schedule-day">
                    <div className="calendar-schedule-date"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        {date}
                    </div>
                    {groupedEvents[date].map((event, idx) => (
                        <div
                            key={event.key} // Ensure the key is unique for each event
                            className="calendar-schedule-event"
                            onClick={() => handleEventClick(event.key)}
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                borderColor: theme.backgroundColor
                            }}>
                            <CalendarScheduleEvent event={event} index={idx} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default CalendarSchedule;
