import React from 'react';

// Styles
import './RelationshipsPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const RelationshipsPreview = ({ 
    placeholder = '', 
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="relationships-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
            <div className="relationships-preview-label"
                style={{color: theme.foregroundColorFaded}}
            >
                {placeholder}
            </div>
            
        </div>
    );
};

export default RelationshipsPreview;
