import React, { useRef } from 'react';

// Styles
import './UserNameSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserNameSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();
    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    const user = {
        key: object.userKey,
        firstName: object.userFirstName,
        lastName: object.userLastName,
    };

    return (
        <div
            className="user-photo-summary-container"
            ref={containerRef}
            style={{ ...processed, height }}>
            {user.firstName} {user.lastName}
        </div>
    );
};

export default UserNameSummary;
