import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ResizeHandle.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Helpers
import ResizeHelper from '../ResizeHelper';

const resizeHelper = new ResizeHelper();

/**
 * ResizeHandle Component
 * 
 * This component renders a resize handle for an element on the canvas.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ResizeHandle = ({ element, direction }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    setElements
  } = useContext(Global);

  const handleResize = (e) => {

    resizeHelper.handleResize(e, element, direction, setElements);

  };

  return (
    <div
      className={`resize-handle-container ${direction}`}
      style={{
        backgroundColor: theme.highlightBackgroundColor,
        borderColor: theme.highlightForegroundColor
      }}
      onMouseDown={(e) => handleResize(e, element, direction)}
    />
  );

};

export default ResizeHandle;
