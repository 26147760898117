import React from 'react';

// Styles
import './CreatedSummaryPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const CreatedSummaryPreview = ({ element }) => {
  const { getProcessedStyles } = useTheme();

  const { styles = {} } = element;

  // Filter out top, left, width, height, and zIndex
  const { top, left, width, height, zIndex, ...filteredStyles } = styles;

  // Ensure width and height are 100%
  const combinedStyles = {
      ...filteredStyles,
      width: '100%',
      height: '100%',
  };

  return (
      <div className="created-summary-preview-container" style={getProcessedStyles(combinedStyles)}>
          3 days ago
      </div>
  );
};

export default CreatedSummaryPreview;
