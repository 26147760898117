import React from 'react';

// Styles
import './VideoPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Videos
import teacher from '../../../../common/images/teacher.png';

const VideoPreview = ({
    placeholder = '',
    selected = false,
    size
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="video-preview-container">
            <div className="video-preview-wrapper"
                style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
                <img
                    src={teacher}
                    alt="Teacher"
                    className="video-preview-video"
                />
            </div>
        </div>
    );
};

export default VideoPreview;
