import React from 'react';

// Styles
import './UserFieldsLabel.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserFieldsLabel = ({ label }) => {
  const { theme } = useTheme();

  return (
    <div className="user-fields-label-container"
      style={{
        color: theme.foregroundColor
      }}>
      {label}:
    </div>
  );
};

export default UserFieldsLabel;
