import React from 'react';

// Styles
import './CommentsPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CommentsPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="comments-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div
                className="comments-preview-label"
                style={{ color: theme.foregroundColorFaded }}
            >
                {placeholder}
            </div>
            <div>
                <div className="comments-preview-content">
                    <div className="comments-preview-comments-container">
                        <div className="user-dot-container"
                            style={{
                                '--dot-size': 30 + 'px',
                                '--dot-font-size': 15 + 'px',
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor,
                                minWidth: 'var(--dot-size)',
                                height: 'var(--dot-size)'
                            }}>
                            EJ
                        </div>
                        <div className="comments-preview-right">
                            <div className="comments-preview-header">
                                <div className="comments-preview-name"
                                    style={{ color: theme.foregroundColor }}>
                                    Ernie Johnson
                                </div>
                                <div
                                    className="comments-preview-timestamp"
                                    style={{ color: theme.foregroundColorFaded }}>
                                    •  1 day ago
                                </div>
                            </div>
                            <div
                                className="comments-preview-text"
                                style={{ color: theme.foregroundColor }}>
                                GOAT
                            </div>
                            <div className="comments-preview-actions">
                                <div className="comments-preview-show-reply-button"
                                    style={{ color: theme.foregroundColorFaded }}>
                                    Reply
                                </div>
                            </div>
                            <div className="comments-preview-show-replies-button"
                                style={{
                                    backgroundColor: theme.highlightBackgroundColor,
                                    color: theme.highlightForegroundColor
                                }}>
                                Hide Replies
                            </div>
                            <div className="comments-preview-replies">
                                <div className="comments-preview-comments-container">
                                    <div className="user-dot-container"
                                        style={{
                                            '--dot-size': 30 + 'px',
                                            '--dot-font-size': 15 + 'px',
                                            backgroundColor: theme.highlightBackgroundColor,
                                            color: theme.highlightForegroundColor,
                                            minWidth: 'var(--dot-size)',
                                            height: 'var(--dot-size)'
                                        }}>
                                        CB
                                    </div>
                                    <div className="comments-preview-right">
                                        <div className="comments-preview-header">
                                            <div className="comments-preview-name"
                                                style={{ color: theme.foregroundColor }}>
                                                Charles Barkley
                                            </div>
                                            <div className="comments-preview-timestamp"
                                                style={{ color: theme.foregroundColorFaded }}>
                                                •  Just now
                                            </div>
                                        </div>
                                        <div className="comments-preview-text"
                                            style={{ color: theme.foregroundColor }}>
                                            I disagree. Lebron was even better than Jordan.
                                        </div>
                                        <div className="comments-preview-actions">
                                            <div className="comments-preview-show-reply-button"
                                                style={{ color: theme.foregroundColorFaded }}>
                                                Reply
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comments-preview-comments-container">
                                    <div className="user-dot-container" style={{
                                        '--dot-size': 30 + 'px',
                                        '--dot-font-size': 15 + 'px',
                                        backgroundColor: theme.highlightBackgroundColor,
                                        color: theme.highlightForegroundColor,
                                        minWidth: 'var(--dot-size)',
                                        height: 'var(--dot-size)'
                                    }}>
                                        SO
                                    </div>
                                    <div className="comments-preview-right">
                                        <div className="comments-preview-header">
                                            <div className="comments-preview-name" style={{ color: theme.foregroundColor }}>
                                                Shrek</div>
                                            <div className="comments-preview-timestamp" style={{ color: theme.foregroundColorFaded }}>
                                                •  Just now

                                            </div>
                                        </div><div className="comments-preview-text" style={{ color: theme.foregroundColor }}>
                                            Kobe FTW!!
                                        </div>
                                        <div className="comments-preview-actions">
                                            <div className="comments-preview-show-reply-button" style={{ color: theme.foregroundColorFaded }}>
                                                Reply
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

















                </div>
            </div>
        </div>
    );
};

export default CommentsPreview;
