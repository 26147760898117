import React from 'react';

const BookmarkOnIcon = ({ color = "white", width = "16", height = "16" }) => (
    <svg version="1.1"
        width={width}
        height={height}
        viewBox="0 0 16 16">
        <path fill={color} d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
    </svg>
);

export default BookmarkOnIcon;
