import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Theme
import { useTheme } from '../../ThemeContext';

// Styles
import './Settings.css';

// Components
import AppSettingsFont from '../../common/appsettings/AppSettingsFont';
import AppSettingsTitle from '../../common/appsettings/AppSettingsTitle';
import AppSettingsDescription from '../../common/appsettings/AppSettingsDescription';
import AppLogoEdit from '../../common/applogo/AppLogoEdit';
import Switch from '../../common/components/switch/Switch';
import ThemeEditor from '../../common/appsettings/AppSettingsThemeEditor';

// Managers
import AppManager from '../../common/managers/AppManager';

const appManager = new AppManager();

/**
 * Settings Component
 * 
 * This component is used on mobile to set app setting and administer them if
 * the current user is an administrator.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Settings = () => {
  const { currentTheme, theme, setCurrentTheme } = useTheme();
  const {
    hideProgress,
    selectedApp,
    setPage,
    setSelectedApp,
    setSettingsVisible,
    setStatusMessage,
    showProgress,
    userRole
  } = useContext(Global);

  // State Variables
  const [switchState, setSwitchState] = useState(currentTheme === "DARK");
  const [tempThemes, setTempThemes] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [font, setFont] = useState(null);

  /**
   * Initializes the settings when the selected app changes.
   */
  useEffect(() => {
    if (!selectedApp) return;

    // Set the switch state
    setSwitchState(currentTheme === "DARK");

    // Initialize about fields
    setTitle(selectedApp.title);
    setDescription(selectedApp.description);

    // Initialize temp themes
    if (!tempThemes) {
      setTempThemes(selectedApp.themes);
    }
  }, [selectedApp, currentTheme, tempThemes]);

  /**
   * Saves the theme any time tempThemes changes.
   */
  useEffect(() => {

    async function saveThemes() {

      // Current timestamp
      const now = Timestamp.now();

      const data = {
        themes: tempThemes,
        dateModified: now,
      };

      // Call updateApp to update the database
      const result = await appManager.updateApp(selectedApp.key, data);

      if (result.success) {
        // Update state
        setSelectedApp((prevApp) => ({
          ...prevApp,
          themes: tempThemes,
          fontFamily: font,
        }));
      }
    }

    if (tempThemes) {
      saveThemes();
    }

  }, [tempThemes, font, selectedApp.key, setSelectedApp]);

  /**
   * Handles theme switch changes from dark mode to light mode.
   */
  const handleSwitchChange = (newValue) => {
    setSwitchState(newValue);
    setCurrentTheme(newValue ? "DARK" : "LIGHT");
  };

  /**
   * Handles theme changes.
   */
  const handleThemeChange = (themeType, updatedTheme) => {
    const newThemes = {
      ...tempThemes,
      [themeType]: updatedTheme
    };
    setTempThemes(newThemes);
  };

  /**
   * Handles the title blur event by saving data and updating state.
   */
  const handleTitleBlur = async () => {
    const now = Timestamp.now();

    const data = {
      title: title,
      dateModified: now,
    };

    const result = await appManager.updateApp(selectedApp.key, data);

    if (result.success) {
      setSelectedApp((prevApp) => ({
        ...prevApp,
        title: title,
      }));
    }
  };

  /**
   * Handles the description blur event by saving data and updating state.
   */
  const handleDescriptionBlur = async () => {
    const now = Timestamp.now();

    const data = {
      description: description,
      dateModified: now,
    };

    const result = await appManager.updateApp(selectedApp.key, data);

    if (result.success) {
      setSelectedApp((prevApp) => ({
        ...prevApp,
        description: description,
      }));
    }
  };

  /**
   * Handles font changes by saving data and updating state.
   */
  const handleFontChange = async (fontFamily) => {
    const now = Timestamp.now();

    const data = {
      fontFamily: fontFamily,
      dateModified: now,
    };

    const result = await appManager.updateApp(selectedApp.key, data);

    setFont(fontFamily);
  };

  /**
   * Handles complete delete of the app.
   */
  const handleDelete = async () => {
    showProgress('Deleting app...');

    if (!selectedApp) {
      alert('No app selected!');
      hideProgress();
      return;
    }

    const message = selectedApp.title + " and all of its data will be permanently deleted. Are you sure you would like to continue?";

    if (!window.confirm(message)) {
      hideProgress();
      return;
    }

    try {
      const appKey = selectedApp.key;
      const appManager = new AppManager();
      await appManager.deleteApp(appKey);

      setSettingsVisible(false);
      setSelectedApp(null);
      setPage('HOME');
      setStatusMessage('App deleted successfully.');
    } catch (error) {
      console.error('Error deleting object and related data: ', error);
      setStatusMessage('Error deleting object and related data');
    }

    hideProgress();
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="settings-container">


        {/* DARK/LIGHT MODE */}
        <div className="settings-setting-wrapper">
          <div className="settings-setting-label"
            style={{ color: theme.foregroundColor }}
          >
            Use Dark Mode
          </div>
          <Switch initialChecked={switchState} onChange={handleSwitchChange} />
        </div>

        {/* ADMIN FUNCTIONALITY */}
        {userRole === "ADMIN" &&
          <>
            <div className="settings-setting-wrapper">

              {/* ABOUT */}
              <div className="settings-setting-about-wrapper">
                <div className="settings-setting-label"
                  style={{ color: theme.foregroundColor }}>
                  About
                </div>

                {/* TITLE */}
                <div className="settings-setting-about-fields">
                  <AppSettingsTitle
                    title={title}
                    setTitle={setTitle}
                    onBlur={handleTitleBlur}
                  />

                  {/* DESCRIPTION */}
                  <AppSettingsDescription
                    description={description}
                    setDescription={setDescription}
                    onBlur={handleDescriptionBlur}
                  />
                </div>
              </div>
            </div>
            <div className="settings-setting-wrapper">

              {/* THEMES AND LOGOS */}
              <div className="settings-setting-themes-wrapper">
                <div className="settings-setting-label"
                  style={{ color: theme.foregroundColor }}>
                  Themes
                </div>

                {/* DARK MODE */}
                <div className="settings-setting-theme-wrapper">
                  {tempThemes &&
                    <>
                      <AppLogoEdit
                        title="Dark Mode Icon"
                        backgroundColor={tempThemes.dark.backgroundColor}
                        borderColor={tempThemes.dark.backgroundColorFaded}
                        mode="DARK"
                      />
                      <ThemeEditor
                        title="Dark Mode Colors"
                        adjustTheme={tempThemes.dark}
                        onThemeChange={(updatedTheme) => handleThemeChange("dark", updatedTheme)}
                      />
                    </>
                  }
                </div>

                {/* LIGHT MODE */}
                <div className="settings-setting-theme-wrapper">
                  {tempThemes &&
                    <>
                      <AppLogoEdit
                        title="Light Mode Icon"
                        backgroundColor={tempThemes.light.backgroundColor}
                        borderColor={tempThemes.light.backgroundColorFaded}
                        mode="DARK"
                      />
                      <ThemeEditor
                        title="Light Mode Colors"
                        adjustTheme={tempThemes.light}
                        onThemeChange={(updatedTheme) => handleThemeChange("light", updatedTheme)}
                      />
                    </>
                  }
                </div>

              </div>
            </div>

            {/* FONT */}
            <div className="settings-setting-label"
              style={{ color: theme.foregroundColor }}>
              Font
            </div>
            <div className="settings-setting-wrapper">
              <AppSettingsFont fontFamily={selectedApp.fontFamily} onChange={handleFontChange} />
            </div>

            {/* DELETE BUTTON */}
            <button
              type="button"
              onClick={handleDelete}
              className="settings-delete"
              style={{ color: theme.highlightBackgroundColor }}
            >
              Delete App
            </button>
          </>
        }
      </div>
    </>
  );
};

export default Settings;
