import React from 'react';

// Styles
import './LocationPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const LocationPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="location-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}
        >
            <div
                className="location-preview-label"
                style={{ color: theme.foregroundColorFaded }}
            >
                {placeholder}
            </div>
            <div className="location-preview-wrapper">
                <div className="location-preview-latitude"
                    style={{ color: theme.foregroundColor }}
                >
                    29.9792
                </div>
                <div className="location-preview-longitude"
                    style={{ color: theme.foregroundColor }}
                >
                    31.1342
                </div>
            </div>
        </div>
    );
};

export default LocationPreview;
