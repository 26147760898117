import React, { useEffect, useState } from 'react';

// Styles
import './Autonumber.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const Autonumber = ({
    object,
    field,
    showFieldMenu = true
}) => {
    const { theme } = useTheme();

    // State Variables
    const [value, setValue] = useState(''); // Default ADD mode value

    useEffect(() => {
        setValue(object?.[field.key] || '--');
    }, [object, field.key]);

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    showFieldMenu={showFieldMenu}
                />

                <div type="text"
                    className="autonumber-input"
                    style={{ color: theme.foregroundColor }}>
                    {value}
                </div>

            </FieldContainer>
        </>
    );
};

export default Autonumber;
