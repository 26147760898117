import React from 'react';

const HomeIcon = ({ color = "white", width = "43.5", height = "41" }) => (
  <svg
  version="1.1"
  width={width}
  height={height}
  viewBox="0 0 43.5 41">
  <path fill={color} d="M386.694,1171.71l-18.771-16a2.981,2.981,0,0,0-3.862,0l-18.771,16a3.007,3.007,0,0,0,1.795,5.29v15.99a3.006,3.006,0,0,0,2.985,3.01h31.844a3.006,3.006,0,0,0,2.985-3.01V1177A3.007,3.007,0,0,0,386.694,1171.71ZM365,1193h-1.99v-9h5.97v9H365Zm16.917-19v19h-9.951v-9a2.991,2.991,0,0,0-2.986-3h-5.97a2.991,2.991,0,0,0-2.986,3v9H350.07v-19h-2.849l18.771-16,18.771,16h-2.849Z" 
    transform="translate(-344.25 -1155)"/>
</svg>

);

export default HomeIcon;
