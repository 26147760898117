import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './LoginButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * LoginButton Component
 * 
 * This component renders a button to attempt a user login.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginButton = ({ onClick, enabled }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  return (

    <div className="login-button-container"
      onClick={onClick}
      style={{
        color: enabled
          ? (selectedApp ? theme.highlightForegroundColor : studio.highlightForegroundColor)
          : (selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded),
        backgroundColor: enabled
          ? (selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor)
          : (selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded)
      }}>
      Log In
    </div>

  );
};

export default LoginButton;
