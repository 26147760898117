import React, { useEffect, useRef, useState } from 'react';

// Styles
import './VerticalHandle.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * VerticalHandle Component
 * 
 * This component allows the user to resize the canvas vertically.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const VerticalHandle = ({ containerHeight, setContainerHeight }) => {

  // Theme
  const { theme } = useTheme();

  // State Variables
  const [isResizing, setIsResizing] = useState(false);

  // References
  const initialMouseYRef = useRef(0);
  const initialHeightRef = useRef(0);

  // Constants
  const MIN_HEIGHT = 30; // Minimum height of the canvas

  /**
   * Method to handle the mouse down event when the user begins resizing the canvas.
   * 
   * @param {event} event - Mouse down event.
  */
  const handleMouseDown = (event) => {
    setIsResizing(true);
    initialMouseYRef.current = event.clientY;
    initialHeightRef.current = parseInt(containerHeight, 10); // Store the initial container height
  };

  /**
   * Effect to set and remove mouse listeners during and after movement.
  */
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isResizing) return;
      const deltaY = event.clientY - initialMouseYRef.current;
      const newHeight = Math.max(MIN_HEIGHT, initialHeightRef.current + deltaY);
      setContainerHeight(`${newHeight}px`);
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    // Cleanup listeners when done
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, containerHeight]);

  return (
    <>
      {/* CONTAINER */}
      <div
        className="vertical-handle-container"
        onMouseDown={handleMouseDown}
        style={{ cursor: 'row-resize' }}>

        {/* TOP LINE */}
        <div
          className="vertical-handle-line"
          style={{ backgroundColor: theme.foregroundColorFaded }}></div>

        {/* BOTTOM LINE */}
        <div
          className="vertical-handle-line"
          style={{ backgroundColor: theme.foregroundColorFaded }}></div>

      </div>
    </>
  );
};

export default VerticalHandle;
