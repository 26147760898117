/**
 * Converts a decimal number to a percentage string.
 * @param {number} decimal - The decimal number to convert (e.g. 1.0 = 100%).
 * @returns {string} - A string representation of the percentage (e.g. '100%').
 */
export function convertDecimalToPercentage(decimal) {
    // Ensure the input is a number, default to 0 if not valid.
    const number = isNaN(decimal) ? 0 : decimal;

    // Multiply by 100 and format as a percentage string with no decimal places.
    return `${(number * 100).toFixed(0)}%`;
}