import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppUserSummary.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppUserSummary = ({ appUser }) => {
    const { theme } = useTheme();
    const {
        roles
    } = useContext(Global);

    const getRoleName = (roleKey) => {
        if (roleKey === "ADMIN") return "Administrator";
        const role = roles.find(role => role.key === roleKey);
        return role.title;
    };

    return (

        <div className="app-users-user">
            <div className="app-users-name"
                style={{
                    color: theme.foregroundColor
                }}>
                {appUser.username}
            </div>
            <div className="app-users-role"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                {getRoleName(appUser.roleKey)}
            </div>
        </div>
        
    );
};

export default AppUserSummary;
