import React from 'react';

const UpIcon = ({ color = "white", width = "16", height = "21" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 16 21"
  >
    <path d="M15.76 8.64871C15.6746 8.74852 15.5705 8.83054 15.4534 8.89009C15.3363 8.94963 15.2087 8.98554 15.0778 8.99575C14.9468 9.00597 14.8152 8.99029 14.6903 8.94962C14.5654 8.90895 14.4498 8.84408 14.35 8.75871L8.99999 4.16871V19.9987C8.99999 20.2639 8.89463 20.5183 8.7071 20.7058C8.51956 20.8934 8.26521 20.9987 7.99999 20.9987C7.73478 20.9987 7.48042 20.8934 7.29288 20.7058C7.10535 20.5183 6.99999 20.2639 6.99999 19.9987V4.16871L1.64999 8.75871C1.55019 8.84407 1.43455 8.90893 1.30967 8.9496C1.1848 8.99027 1.05314 9.00595 0.92221 8.99573C0.791279 8.98552 0.663642 8.94961 0.546587 8.89007C0.429532 8.83053 0.32535 8.74851 0.239991 8.64871C0.0676005 8.44715 -0.0176583 8.18535 0.00297073 7.92093C0.0235997 7.6565 0.148427 7.4111 0.349991 7.23871L7.34999 1.23871C7.40112 1.20495 7.45459 1.17487 7.50999 1.14871L7.62999 1.06871C7.86758 0.974086 8.1324 0.974086 8.36999 1.06871L8.48999 1.14871C8.54539 1.17487 8.59886 1.20495 8.64999 1.23871L15.65 7.23871C15.7498 7.32407 15.8318 7.42825 15.8914 7.5453C15.9509 7.66236 15.9868 7.78999 15.997 7.92093C16.0072 8.05186 15.9916 8.18352 15.9509 8.30839C15.9102 8.43327 15.8454 8.54891 15.76 8.64871Z" 
      fill={color} />
  </svg>
);

export default UpIcon;
