import React from 'react';

const CheckOnIcon = ({ color = "white", checkColor = "transparent", width = "18", height = "18" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 18 18">
        <g stroke="none" fill="none">
            <g transform="translate(-103.000000, -4323.000000)">
                <g transform="translate(100.000000, 4266.000000)">
                    <g transform="translate(0.000000, 54.000000)">
                        <g>
                            <rect fill={checkColor} x="5" y="5" width="14" height="14" rx="0" ry="0"></rect>
                            <path d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M10.71,16.29 C10.32,16.68 9.69,16.68 9.3,16.29 L5.71,12.7 C5.32,12.31 5.32,11.68 5.71,11.29 C6.1,10.9 6.73,10.9 7.12,11.29 L10,14.17 L16.88,7.29 C17.27,6.9 17.9,6.9 18.29,7.29 C18.68,7.68 18.68,8.31 18.29,8.7 L10.71,16.29 Z"
                                fill={color} />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

);

export default CheckOnIcon;
