import React from 'react';

const BackIcon = ({ color = "white", width = "11", height = "20" }) => (
  <svg 
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 11 20"
  >
    <g stroke="none" fill="none">
        <g transform="translate(-548.000000, -3434.000000)">
            <g transform="translate(100.000000, 3378.000000)">
                <g transform="translate(442.000000, 54.000000)">
                    <g>
                        <polygon opacity="0.87" points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M16.62,2.99 C16.13,2.5 15.34,2.5 14.85,2.99 L6.54,11.3 C6.15,11.69 6.15,12.32 6.54,12.71 L14.85,21.02 C15.34,21.51 16.13,21.51 16.62,21.02 C17.11,20.53 17.11,19.74 16.62,19.25 L9.38,12 L16.63,4.75 C17.11,4.27 17.11,3.47 16.62,2.99 Z" 
                        fill={color} />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default BackIcon;
