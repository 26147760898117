import React from 'react';

const PasswordIcon = ({ color = "white", width = "256", height = "256" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" />
        <line x1="40" y1="56" x2="40" y2="200" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="104" y1="96" x2="104" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="73.57" y1="118.11" x2="104" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="85.19" y1="153.89" x2="104" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="122.81" y1="153.89" x2="104" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="134.43" y1="118.11" x2="104" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="200" y1="96" x2="200" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="169.57" y1="118.11" x2="200" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="181.19" y1="153.89" x2="200" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="218.81" y1="153.89" x2="200" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
        <line x1="230.43" y1="118.11" x2="200" y2="128" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
    </svg>
);

export default PasswordIcon;
