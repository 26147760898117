import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ChannelDelete.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Managers
import { activity } from '../../../managers/ActivityManager';

// Components
import Modal from '../../../components/modal/Modal';

// Managers
import ChannelManager from '../../../managers/ChannelManager';

const channelManager = new ChannelManager();

/**
 * ChannelDelete Component
 * 
 * This component confirms a delete prior to deleting the channel.
 * 
 * @param {object} channel - The channel object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelDelete = ({ channel, isOpen, setOpen }) => {
    const { theme } = useTheme();
    const {
        hideProgress,
        resetVisibility,
        selectedApp,
        setRunHomeVisible,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the channel.
     */
    const deleteChannel = async () => {

        showProgress("Deleting channel...");

        try {

            await channelManager.delete(selectedApp.key, channel.key);

            resetVisibility();
            setRunHomeVisible(true);

            // Log the activity
            activity.log(selectedApp.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting channel: ", error);
        }

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete Channel"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={400}>

                {/* MESSAGE */}
                <div className="channel-delete-message"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    This will permanently delete all messages in this channel. Continue?
                </div>

                {/* BUTTONS */}
                <div className="channel-delete-buttons">
                    <div onClick={deleteChannel} className="channel-delete-button"
                        style={{ color: theme.foregroundColor }}>
                        Delete
                    </div>
                </div>

            </Modal>
        </>
    );
};

export default ChannelDelete;
