import React from 'react';

// Styles
import './UserNameSummaryPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserNameSummaryPreview = ({ element }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    // Ensure width and height are 100%
    const combinedStyles = {
        ...filteredStyles,
        width: '100%',
        height: '100%',
    };

    const user = {
        firstName: 'Kaden',
        lastName: 'Nessland',
    };

    return (
        <div className="user-name-summary-preview-container" style={getProcessedStyles(combinedStyles)}>
            {user.firstName} {user.lastName}
        </div>
    );
};

export default UserNameSummaryPreview;
