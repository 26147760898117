import React from 'react';

import { isMobile } from 'react-device-detect';

// Styles
import './Menu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppBar from '../components/appbar/AppBar';
import MenuItems from './MenuItems';
import MenuHeader from './MenuHeader';

const Menu = () => {
    const { theme } = useTheme();

    return (
        <div className="menu-container"
            style={{
                borderRightColor: theme.backgroundColorFaded // Desktop
            }}>
            
            {/* HEADER 
            {isMobile &&
                <MenuHeader />
            }
            */}

            {/* APP BAR */}
            {isMobile &&
                <AppBar />
            }

            {/* MENU ITEMS */}
            <MenuItems />
        </div>
    );
};

export default Menu;
