import React, { useEffect, useState } from 'react';

// Styles
import './FieldPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import DocumentsPreview from './previews/DocumentsPreview';
import AutonumberPreview from './previews/AutonumberPreview';
import BarcodePreview from './previews/BarcodePreview';
import CheckboxPreview from './previews/CheckboxPreview';
import ChecklistPreview from './previews/ChecklistPreview';
import CommentsPreview from './previews/CommentsPreview';
import CountriesPreview from './previews/CountriesPreview';
import CurrencyPreview from './previews/CurrencyPreview';
import DatePreview from './previews/DatePreview';
import DayPreview from './previews/DayPreview';
import ImagePreview from './previews/ImagePreview';
import GalleryPreview from './previews/GalleryPreview';
import LocationPreview from './previews/LocationPreview';
import LookupPreview from './previews/LookupPreview';
import MonthPreview from './previews/MonthPreview';
import NumberPreview from './previews/NumberPreview';
import ObjectChecklistPreview from './previews/ObjectChecklistPreview';
import PasswordPreview from './previews/PasswordPreview';
import PhonePreview from './previews/PhonePreview';
import RelationshipsPreview from './previews/RelationshipsPreview';
import StatesPreview from './previews/StatesPreview';
import TextPreview from './previews/TextPreview';
import TimePreview from './previews/TimePreview';
import UserListPreview from './previews/UserListPreview';
import VideoPreview from './previews/VideoPreview';
import VideoGalleryPreview from './previews/VideoGalleryPreview';
import YearPreview from './previews/YearPreview';

const FieldPreview = ({
    type,
    title,
    description,
    size = "SMALL"
}) => {
    const { theme } = useTheme();

    const [scale, setScale] = useState("0.35");

    useEffect(() => {
        setScale(size === "SMALL" ? "0.35" : "0.80");
    }, [size]);

    // Map type keys to respective components and their heights
    const componentMap = {
        autonumber: { component: AutonumberPreview, heightSmall: "110px", heightLarge: "135px" },
        barcode: { component: BarcodePreview, heightSmall: "110px", heightLarge: "135px" },
        checkbox: { component: CheckboxPreview, heightSmall: "100px", heightLarge: "115px" },
        checklist: { component: ChecklistPreview, heightSmall: "165px", heightLarge: "225px" },
        comments: { component: CommentsPreview, heightSmall: "250px", heightLarge: "370px" },
        countries: { component: CountriesPreview, heightSmall: "110px", heightLarge: "135px" },
        currency: { component: CurrencyPreview, heightSmall: "110px", heightLarge: "135px" },
        date: { component: DatePreview, heightSmall: "110px", heightLarge: "135px" },
        day: { component: DayPreview, heightSmall: "110px", heightLarge: "135px" },
        documents: { component: DocumentsPreview, heightSmall: "120px", heightLarge: "160px" },
        image: { component: ImagePreview, heightSmall: "170px", heightLarge: "240px" },
        gallery: { component: GalleryPreview, heightSmall: "170px", heightLarge: "240px" },
        location: { component: LocationPreview, heightSmall: "110px", heightLarge: "135px" },
        lookup: { component: LookupPreview, heightSmall: "110px", heightLarge: "135px" },
        month: { component: MonthPreview, heightSmall: "110px", heightLarge: "135px" },
        number: { component: NumberPreview, heightSmall: "110px", heightLarge: "135px" },
        objectchecklist: { component: ObjectChecklistPreview, heightSmall: "100px", heightLarge: "100px" },
        password: { component: PasswordPreview, heightSmall: "110px", heightLarge: "135px" },
        phone: { component: PhonePreview, heightSmall: "110px", heightLarge: "135px" },
        relationships: { component: RelationshipsPreview, heightSmall: "150px", heightLarge: "200px" },
        states: { component: StatesPreview, heightSmall: "110px", heightLarge: "135px" },
        text: { component: TextPreview, heightSmall: "110px", heightLarge: "135px" },
        time: { component: TimePreview, heightSmall: "110px", heightLarge: "135px" },
        userlist: { component: UserListPreview, heightSmall: "175px", heightLarge: "245px" },
        video: { component: VideoPreview, heightSmall: "170px", heightLarge: "240px" },
        videogallery: { component: VideoGalleryPreview, heightSmall: "170px", heightLarge: "240px" },
        year: { component: YearPreview, heightSmall: "110px", heightLarge: "135px" }
    };

    // Select the component and height based on the type provided
    const typePrefix = type.includes('-') ? type.split('-')[0] : type;
    const { component: Component, heightSmall } = componentMap[typePrefix] || {};  // Use prefix to fetch the correct component and height

    // Render nothing if no component matches the type
    if (!Component) return null;

    return (
        <div
            className="field-preview-container"
            style={{
                backgroundColor: theme.backgroundColor,
                height: size === "SMALL" ? heightSmall : "auto"
            }}>
            <div
                className="field-preview-title"
                style={{ color: theme.foregroundColor }}>
                {title}
            </div>
            {description && description.length > 0 &&
                <div
                    className="field-preview-description"
                    style={{ 
                        color: theme.foregroundColorFaded 
                    }}>
                    {description}
                </div>
            }
            <div
                className="field-preview-wrapper"
                style={{
                    transform: size === "SMALL" ? `scale(${scale})` : "none"
                }}>
                <Component placeholder={title} />
            </div>
        </div>
    );
};

export default FieldPreview;
