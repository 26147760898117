import React from 'react';

const LocationIcon = ({ color = "white", width = "32", height = "32" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 32 32" fill="none">
        <path fill={color} d="M16,18a5,5,0,1,1,5-5A5.0057,5.0057,0,0,1,16,18Zm0-8a3,3,0,1,0,3,3A3.0033,3.0033,0,0,0,16,10Z" />
        <path fill={color} d="M16,30,7.5645,20.0513c-.0479-.0571-.3482-.4515-.3482-.4515A10.8888,10.8888,0,0,1,5,13a11,11,0,0,1,22,0,10.8844,10.8844,0,0,1-2.2148,6.5973l-.0015.0025s-.3.3944-.3447.4474ZM8.8125,18.395c.001.0007.2334.3082.2866.3744L16,26.9079l6.91-8.15c.0439-.0552.2783-.3649.2788-.3657A8.901,8.901,0,0,0,25,13,9,9,0,1,0,7,13a8.9054,8.9054,0,0,0,1.8125,5.395Z" />
        <rect width="32" height="32" transform="translate(0 32) rotate(-90)" />
    </svg>
);

export default LocationIcon;
