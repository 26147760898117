import React from 'react';

const IntegerInput = ({ value, onChange, ...props }) => {

    return (
        <input
            type="text"
            value={value}
            onChange={(e) => {
                const val = e.target.value;
                if (/^\d*$/.test(val)) { // Only allow digits
                    onChange(e);
                }
            }}
            {...props}
        />
    );
};

export default IntegerInput;