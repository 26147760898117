import React from 'react';

const DotsIcon = ({ color = "white", width = "17", height = "4" }) => (
  <svg 
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 17 4"
  >
  <path stroke={color} strokeLinecap="round" strokeWidth="3" d="M2.49 2h.01m6 0h.01m5.99 0h.01"/>
</svg>
);

export default DotsIcon;
