import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Conversations.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ConversationsToolBar from './ConversationsToolBar';
import ConversationAddButton from './ConversationAddButton';
import ConversationSummary from './ConversationSummary';
import Divider from '../../common/components/divider/Divider';

// Managers
import ConversationManager from '../../common/managers/ConversationManager';

const conversationManager = new ConversationManager();

const Conversations = () => {
    const { theme } = useTheme();
    const {
        conversations,
        currentUser,
        setSelectedConversation,
        selectedApp,
    } = useContext(Global);

    const handleClick = async (conversation) => {
        setSelectedConversation(conversation);
        
        // Update the conversation with the latest message
        await conversationManager.update(selectedApp.key, conversation.key, { lastMessageRead: true });
    };

    // Filter conversations where the current user is a participant
    const filteredConversations = conversations.filter(conversation => 
        conversation.participants.some(participant => participant.userKey === currentUser.key)
    );

    // Sort conversations by the date the last message was created, in descending order
    const sortedConversations = filteredConversations.sort((a, b) => {
        const dateA = new Date(a.lastMessage?.dateCreated);
        const dateB = new Date(b.lastMessage?.dateCreated);
        return dateB - dateA; // Sort descending: most recent first
    });

    return (
        <>
            <div className="conversations-container"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                }}>
                <ConversationsToolBar />
                <div className="conversations-list">
                    {sortedConversations.map((conversation, index) => (
                        <div className="conversations-conversation-wrapper"
                            key={conversation.key}
                            onClick={() => handleClick(conversation)}>
                            <ConversationSummary
                                conversation={conversation}
                            />
                            <Divider />
                        </div>
                    ))}
                </div>
                <div className="conversations-add-button">
                    <ConversationAddButton />
                </div>
            </div>
        </>
    );
};

export default Conversations;
