import React, { useState } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ChecklistAddForm.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Utilities
import { generateKey } from '../../../../common/utilities/Keys';

// Components
import Modal from '../../../../common/components/modal/Modal';

const ChecklistAddForm = ({
    modalOpen,
    setModalOpen,
    onAdd
}) => {
    const { theme } = useTheme();

    // State Variables
    const [itemTitle, setItemTitle] = useState('');
    const [itemInstructions, setItemInstructions] = useState('');

    /**
     * Method to add a checklist item to a checklist.
     */
    const handleAdd = () => {
        const key = generateKey();
        const newItem = {
            key,
            title: itemTitle,
            instructions: itemInstructions
        };

        onAdd(newItem);
    }

    return (

        <Modal title="Checklist Item" width={isMobile ? "90%" : "350px"} isOpen={modalOpen} onClose={() => setModalOpen(false)}>

            {/* TITLE FIELD */}
            <div className="checklist-add-form-label"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                Title
            </div>
            <input type="text"
                className="checklist-add-form-title-input"
                value={itemTitle}
                onChange={(e) => setItemTitle(e.target.value)}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor
                }}
            />

            {/* INSTRUCTIONS FIELD */}
            <div className="checklist-add-form-label"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                Instructions
            </div>
            <textarea
                className="checklist-add-form-instructions-input"
                value={itemInstructions}
                onChange={(e) => setItemInstructions(e.target.value)}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor
                }}
                maxLength="250"
            ></textarea>

            {/* ADD BUTTON */}
            <div className="modal-buttons">
                <button onClick={handleAdd} className="modal-button"
                    style={{ color: theme.foregroundColor }}>
                    Add
                </button>
            </div>
        </Modal>

    );
};

export default ChecklistAddForm;
