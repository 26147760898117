import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './Canvas.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CanvasElement from '../canvaselement/CanvasElement';
import SaveDesignButton from '../savedesignbutton/SaveDesignButton';
import VerticalHandle from './VerticalHandle';

// Helpers
import DragHelper from '../DragHelper';

/**
 * Canvas Component
 * 
 * This component allows the user to design object summary layouts.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Canvas = ({ onSave }) => {

  // Theme
  const { theme, fieldDefaults } = useTheme();

  // Global
  const {
    elements,
    selectedModel,
    setElements,
    setSelectedElement
  } = useContext(Global);

  // State Variables
  const [containerHeight, setContainerHeight] = useState('50px');

  // Instantiate the DragHelper
  const dragHelper = new DragHelper();

  // Effect to initially set the container height from the model.summaryHeight
  useEffect(() => {
    setContainerHeight(selectedModel.summaryHeight);
  }, [selectedModel]);

  /**
   * Method to handle a drop action on the canvas. This will either add a new
   * element to the canvas or move an existing one.
   * 
   * @param {event} e - Drop event.
   */
  const handleDrop = (e) => {
    dragHelper.handleDrop(e, elements, setElements, setSelectedElement, fieldDefaults);
  }

  /**
   * Method to clear the selected element when the container is clicked.
   */
  const handleContainerClick = () => {
    setSelectedElement(null);
  }

  /**
   * Method to stop the click event from propagating when the canvas is clicked.
   */
  const handleCanvasClick = async (e) => {
    e.stopPropagation();
    setSelectedElement(null);
  };

  /**
   * Method to handle the drag over event on the canvas.
   */
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * Method to save the design to the model record
   */
  const handleSave = async (e) => {
    onSave(elements, containerHeight);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="canvas-container"
        onClick={handleContainerClick}>

        <div className="canvas-top">

          {/* CANVAS */}
          <div className="canvas-wrapper"
            style={{
              backgroundColor: theme.backgroundColor,
              border: `1px dotted ${theme.backgroundColorFaded}`,
              height: containerHeight
            }}
            onClick={handleCanvasClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>

            {elements && elements.map((element, index) => {

              return (

                <CanvasElement
                  key={index}
                  element={element}
                  elements={elements}
                />

              );
            })}

          </div>

          {/* VERTICAL RESIZE HANDLE */}
          <VerticalHandle
            containerHeight={containerHeight}
            setContainerHeight={setContainerHeight}
          />

        </div>
        <div className="canvas-bottom">

          {/* SAVE BUTTON */}
          <SaveDesignButton onClick={handleSave} />

        </div>

      </div >
    </>
  );

};

export default Canvas;
