import React from 'react';

// Styles
import './DatePreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const DatePreview = ({
    placeholder = '',
    selected = false,
    value = ""
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="date-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}
        >
            <div className="date-preview-label"
                style={{ color: theme.foregroundColorFaded }}>
                {placeholder}
            </div>
            <input
                type="text"
                className="date-preview-input"
                style={{ color: theme.foregroundColor }}
                readOnly
                value={value}
                tabIndex={-1} // This makes the input unfocusable via keyboard
            />
        </div>
    );
};

export default DatePreview;
