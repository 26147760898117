import React, { useEffect, useRef, useState } from 'react';

// Styles
import './FeatureModal.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import FormField from '../../common/form/FormField';

const FeatureModal = ({ object, field, isOpen, onClose, onUpdate }) => {
  const { theme } = useTheme();
  const [isAnimating, setIsAnimating] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // Trigger the animation
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [isOpen]);

  if (!isOpen && !isAnimating) return null;

  return (
    <div
      className="feature-modal-overlay"
      onMouseDown={onClose}
      style={{ opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms' }}
    >
      <div
        className="feature-modal-content"
        style={{
          backgroundColor: theme.backgroundColor,
          opacity: isAnimating ? 1 : 0,
          transition: 'opacity 300ms, transform 300ms',
        }}
        ref={modalRef}
        onMouseDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <div className="feature-modal-body">
          <FormField
            object={object}
            key={field.key}
            field={field}
            onUpdate={onUpdate}
            showFieldMenu={false}
          />
          <div
            className="feature-modal-save-button"
            style={{ color: theme.foregroundColor }}
            onClick={onClose}>
            Save Changes
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureModal;
