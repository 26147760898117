export const getEventTimespan = (event) => {
  
  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes === 0 ? '' : `:${minutes < 10 ? '0' + minutes : minutes}`;
    return `${hours}${minutesStr}${ampm}`;
  };

  const startTime = formatTime(event.startDate.toDate());
  const endTime = formatTime(event.endDate.toDate());

  return `${startTime} - ${endTime}`;
};