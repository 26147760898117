import React from 'react';

// Styles
import './UserPhotoSummaryPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import UserThumb from '../../../common/components/userthumb/UserThumb';

const UserPhotoSummaryPreview = ({ element }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    // Ensure width and height are 100%
    const combinedStyles = {
        ...filteredStyles,
        width: '100%',
        height: '100%',
    };

    const user = {
        firstName: 'Kaden',
        lastName: 'Nessland',
    };

    return (
        <div className="user-photo-summary-preview-container" style={getProcessedStyles(combinedStyles)}>
            <UserThumb 
                user={user} 
                size={parseInt(width)}
            />
        </div>
    );
};

export default UserPhotoSummaryPreview;
