import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './EventSaveButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const EventSaveButton = ({
  onSave
}) => {
  const { theme } = useTheme();
  const { selectedEvent } = useContext(Global);

  return (

    <div
      onClick={onSave}
      className="event-save-button"
      style={{
        backgroundColor: theme.highlightBackgroundColor,
        color: theme.highlightForegroundColor,
      }}>
        {selectedEvent ? "Save" : "Add" }
      
    </div>
  );
};

export default EventSaveButton;
