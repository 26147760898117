import React from 'react';

// Styles
import './FormStatusMessage.css';

// Theme
import { useTheme } from '../../ThemeContext';

const FormStatusMessage = ({ message }) => {
    const { theme } = useTheme();

    return (

        <div className="form-success-message-container"
            style={{
                borderColor: theme.backgroundColorFaded,
                color: theme.foregroundColorFaded
            }}>
            {message}
        </div>
    );
};

export default FormStatusMessage;
